import { 
  GROUP_EVENTS_RESET_REDUCER, 
  GROUP_EVENTS_LOAD, 
  GROUP_EVENTS_TICKET_TYPES_LOAD,
  GROUP_EVENTS_ATTENDANCE_LOAD, 
  GROUP_EVENTS_EVENT_RESET 
} from '../types'

const initialState = {
    groupId: undefined,
    eventsLastUpdated: 0,
    events: {},
    ticketTypes: [],
    attendance: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_EVENTS_RESET_REDUCER:
      return initialState
    case GROUP_EVENTS_LOAD:
      return { 
          ...state, 
          groupId: action.payload.groupId,
          events: action.payload.events, 
          eventsLastUpdated: action.payload.timestamp
      }
    case GROUP_EVENTS_TICKET_TYPES_LOAD:
      return { 
          ...state, 
          ticketTypes: action.payload
      }
    case GROUP_EVENTS_ATTENDANCE_LOAD:
      return { 
          ...state, 
          attendance: action.payload
      }  
    case GROUP_EVENTS_EVENT_RESET:
      return { 
          ...state, 
          ticketTypes: [],
          attendance: []
      }
	  default:
	    return state
  }
}
