import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import IconEvent from '@material-ui/icons/Event'
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const options = {
  Public: 'anyone can purchase tickets',
  Members: 'only members can purchase tickets, but may purchase for guests',
  Private: 'only members can purchase and only members can attend'
}

class Step1 extends React.Component {
    state = {
        typeEl: null,
        name: this.props.event.name,
        tagline: this.props.event.tagline,
        summary: this.props.event.summary,
        dateTimeStart: this.props.event.dateTimeStart,
        dateTimeEnd: this.props.event.dateTimeEnd,
        type: this.props.event.type,
        listedOnSecureTix: this.props.event.listedOnSecureTix,
        message: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
 
    handleCheck = (e) => {
      let checked = !this.state.listedOnSecureTix
      this.setState({ [e.target.name]: checked })
    }

    handleNext = () => {
      let {name, tagline, summary, dateTimeStart, dateTimeEnd, type, listedOnSecureTix} = this.state
      if (name === '') {
        return this.setState({ message: 'Sorry, the event name is required.'})
      }
      this.props.updateInfo(name, tagline, summary, dateTimeStart, dateTimeEnd, type, listedOnSecureTix)
    }

    render() {
        return (
          <div>
            <form noValidate autoComplete='off'>
              <TextField fullWidth
                label='Name'
                name='name'
                value={this.state.name}
                onChange={this.handleChange}
                margin='normal'
              />
              <TextField fullWidth
                label='Tagline'
                name='tagline'
                value={this.state.tagline}
                onChange={this.handleChange}
                margin='normal'
              />
              <TextField fullWidth
                multiline
                rowsMax='7'
                label='Summary'
                name='summary'
                value={this.state.summary}
                onChange={this.handleChange}
                margin='normal'
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    fullWidth
                    margin="normal"
                    label="Start Date & Time"
                    minutesStep={5}
                    invalidDateMessage= 'Invalid Date Format - Click Calendar Icon'
                    minDate={new Date()}
                    value={this.state.dateTimeStart}
                    onChange={date => {this.setState({dateTimeStart: date})}}
                    format="ddd Do MMM YYYY - hh:mm a"
                    InputProps={{
                      endAdornment: (<InputAdornment position="end"><IconButton style={{padding:0}}><IconEvent /></IconButton></InputAdornment>)
                    }}
                  />
                  <DateTimePicker
                    fullWidth
                    margin="normal"
                    label="End Date & Time"
                    minutesStep={5}
                    invalidDateMessage= 'Invalid Date Format - Click Calendar Icon'
                    minDateMessage='End Date must be after Start Date'
                    minDate={this.state.dateTimeStart}
                    value={this.state.dateTimeEnd}
                    onChange={date => {this.setState({dateTimeEnd: date})}}
                    format="ddd Do MMM YYYY - hh:mm a"
                    InputProps={{
                      endAdornment: (<InputAdornment position="end"><IconButton style={{padding:0}}><IconEvent /></IconButton></InputAdornment>)
                    }}
                  />
              </MuiPickersUtilsProvider>

              <List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
                <ListItem button onClick={(event) => { this.setState({typeEl: event.currentTarget}) }} style={{padding:10}}>
                  <ListItemText primary={'Type of Event: ' + this.state.type} secondary={options[this.state.type]} />
                </ListItem>
              </List>                            
              
              <Menu 
                dense='true' 
                anchorEl={this.state.typeEl}
                open={Boolean(this.state.typeEl)}
                onClose={() => {this.setState({typeEl: null}) }}
              >
                <MenuItem value='Public' selected={this.state.type === 'Public' } onClick={() => {this.setState({type: 'Public', typeEl: null})}}>
                  <ListItemText style={{whiteSpace: 'normal'}} primary='PUBLIC' secondary={options['Public']} />
                </MenuItem>
                <MenuItem value='Members' selected={this.state.type === 'Members' } onClick={() => {this.setState({type: 'Members', typeEl: null})}}>
                  <ListItemText style={{whiteSpace: 'normal'}} primary='MEMBERS' secondary={options['Members']} />
                </MenuItem>
                <MenuItem value='Private' selected={this.state.type === 'Private' } onClick={() => {this.setState({type: 'Private', typeEl: null})}}>
                  <ListItemText style={{whiteSpace: 'normal'}} primary='PRIVATE' secondary={options['Private']} />
                </MenuItem>
              </Menu>
            </form>
            <hr/>
            <FormControlLabel
              control={
                <Checkbox checked={this.state.listedOnSecureTix} onChange={this.handleCheck} name='listedOnSecureTix' />
              }
              label='List event on SecureTix.net'
            />
            <div align='center' style={{ height: 25, padding: 10 }}>
                <Text variant='caption' style={{ color: 'red' }}>
                    {this.state.message}
                </Text>
            </div>
            <div>
              <div align='right' style={{ padding: 20 }}>
                <Button variant='contained' color='primary' onClick={this.handleNext}>
                  next
                </Button>
              </div>
            </div>
          </div>
        )
    }
}
export default Step1
