import { MEMBER_NOTIFICATIONS_LOAD } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Notifications Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorNotifications
export function startMonitoringNotifications() {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {

        // delay this snapshot if userInfo not loaded as yet
        if(getstate().auth.userInfo == null){
            await customDelay(1500)
        }

        // userInfo loaded so go ahead with snapshot
        const mId = (getstate().auth.userInfo) ? getstate().auth.userInfo.id : null
        const noteRef = firebase.firestore().collection('notifications')
        monitorNotifications = noteRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
            //let timestamp = Math.floor(Date.now() / 1000)
            let notifications = []
            querySnapshot.forEach((doc) => {
                let note = doc.data()
                note.docId = doc.id
                notifications.push(note)
            })
            let payload = {
                notifications: notifications,
                timestamp: Math.floor(Date.now() / 1000)
            }
            dispatch({type: MEMBER_NOTIFICATIONS_LOAD, payload: payload})
        })
    })
}

export function stopMonitoringNotifications(){
    return () => {
        monitorNotifications && monitorNotifications()
    }
}
// [END]: Notifications Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


export function saveNotificationToggle(id, save) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let db = firebase.firestore().collection('notifications').doc(id)
        db.update({ saved: save })
        resolve()
    })
}

export function openNotificationToggle(id, opened) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let db = firebase.firestore().collection('notifications').doc(id)
        db.update({ viewed: opened })
        resolve()
    })
}

export function deleteNotification(id) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        //let db = firebase.firestore().collection('notifications').doc(id)
        //db.update({ viewed: opened })
        console.log('delete notification')
        resolve()
    })
}