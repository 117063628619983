import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Typography from '@material-ui/core/Typography'
import IconApps from '@material-ui/icons/Apps'
import IconForward from '@material-ui/icons/Forward'
import IconReorder from '@material-ui/icons/Reorder'

function Crumb(props) {
    return (
        (props.crumb) ? (
            <Typography variant='caption' gutterBottom align='center'>
            <IconButton onClick={ props.home } size='small'> 
                <IconForward style={{ color: '#cccccc', transform: 'rotate(180deg)' }}/>
            </IconButton>
            <Button disabled size='small' style={{ color: '#b3b3b3', fontSize: '.95em', marginLeft: '-1em' }}>
                <KeyboardArrowRight style={{ margin: 0, fontSize: '1.5em' }} />
                { props.crumb }
            </Button>
            </Typography>
        ) : (
            <Typography variant='caption' gutterBottom align='center'>
            <Button disabled size='small' style={{ color: '#b3b3b3', fontSize: '.95em', marginLeft: '-.25em' }}>
                { props.rootText }
            </Button>
            </Typography>
        )
    )
}

function InfoComponent(props) {
  	let color = (props.showInfo) ? props.color : '#b3b3b3'
    return (
        <IconButton
            onClick= { props.handleShowInfoToggle }
            size='small'
            style={{ color: color, marginRight: '-.25em' }}
        >
            <IconInfo />
        </IconButton>
    )
}

function LayoutComponent(props) {
  	let list = (props.layoutStyle === 'list') ? props.color : '#b3b3b3'
  	let tile = (props.layoutStyle === 'tile') ? props.color : '#b3b3b3'
  	return (
        <React.Fragment>
            <IconButton
            onClick={() => {props.layoutStyleToggle('list')}}
            size='small'
            style={{ color: list, marginRight: '-.25em' }}
            >
            <IconReorder />
            </IconButton>
            <IconButton
            onClick={() => {props.layoutStyleToggle('tile')}}
            size='small'
            style={{ color: tile, marginRight: '-.25em' }}
            >
            <IconApps />
            </IconButton>
        </React.Fragment>
  	)
}

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

class Breadcrumb extends React.Component {
    state = {
        color: this.props.theme.palette.primary.sub,
        rootLink: '',
        rootText: '',
        crumb: '',
        infoIcon: false,
        showInfo: (this.props.infoOpen) ? this.props.infoOpen : false,
        layoutStyle: null
    }
    
    componentDidMount = () => {
        this.handleSetBreadcrumb(this.props)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.handleSetBreadcrumb(this.props)
        }
    }

    handleSetBreadcrumb = (props) => {
        let crumb = ''
        if (props.routes.views) {
            props.routes.views.map(function (links) {
                if (props.location.pathname.startsWith(links.path)) {
                    return crumb = links.name
                } else {
                    return false
                }

            })
        }
        this.setState({
            rootLink: props.routes.path,
            rootText: (props.routes.alias) ? props.routes.alias : props.routes.name,
            crumb: crumb,
            infoIcon: props.infoIcon,
            layoutStyle: props.layoutStyle
        })
    }

    handleGoHome = () => {
        this.props.history.push({ pathname: this.state.rootLink })
    }

    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }

    render() {
        return (
            <Container maxWidth='lg' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} style={{ marginLeft: 10, marginRight: 10, borderBottom: '1px solid #b3b3b3' }}>   
                <Grid container direction='row' justify='space-between' alignItems='flex-start'>
                    <Grid item>
                    <Crumb
                        rootText={ this.state.rootText }
                        crumb={ this.state.crumb }
                        home={ this.handleGoHome }
                    />
                    </Grid>
                    <Grid item style={{ marginTop: 0 }}>
                    <Typography variant='caption' gutterBottom align='center'>
                        {(this.state.infoIcon) ? (
                        <InfoComponent
                            color = { this.state.color}
                            showInfo = { this.state.showInfo }
                            handleShowInfoToggle = { this.handleShowInfoToggle }
                        />
                        ) : (null) }
                        {(this.state.layoutStyle) ? (
                        <LayoutComponent
                            color = { this.state.color }
                            layoutStyle = { this.state.layoutStyle }
                            layoutStyleToggle = { this.props.layoutStyleToggle }
                        />
                        ) : (null) }
                    </Typography>
                    </Grid>
                </Grid>
                </Grid>

                <Grid item xs={12} sm={10} l={10}>
                    <Collapse in={ this.state.showInfo }>
                        <Paper style={{width: '90%', margin: '0 auto'}}>
                            {this.props.infoComponent}
                            <div align='right'>
                            <Button onClick= {this.handleShowInfoToggle }>Hide Info</Button>
                            </div>
                        </Paper>
                    </Collapse>
                </Grid>
            </Grid>
            </Container>
        )   
    }
}
export default withRouter(withTheme(Breadcrumb))
