import React from 'react'
import { withRouter } from 'react-router-dom'
import Info from './Info'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import IconFilter from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class PrivateSubnav extends React.Component {
    state = { showInfo: false }

    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }

    render() {
        const {showInfo} = this.state
        let message = 'Group Dashboard'
        return (
            <Container maxWidth='lg' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} >
                    <AppBar position='static' color='default'>
                        <Toolbar variant="dense">
                            <Button>
                                <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                    <b>DASHBOARD</b>
                                </Text>
                            </Button>
                        </Toolbar>
                    </AppBar> 

                    <div style={{ display: 'flex', borderBottom: '2px solid #f1f1f1' }}>
                    <Text variant='caption' align='center'>
                    <Button disabled size='small' style={{ color: '#b3b3b3', fontSize: '1em', marginLeft: '-.25em', marginTop: '1em' }}>
                        {message}
                    </Button>
                    </Text>
                        <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                        <IconButton onClick= { this.handleShowInfoToggle } >
                            <IconInfo />
                        </IconButton>
                        </div>
                    </div>

                    <Dialog open={showInfo} onClose={this.handleShowInfoToggle}>
                        <DialogContent>
                            <Info />
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleShowInfoToggle} color='primary' autoFocus>
                            Close
                        </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </Grid>
            </Container>
        )
    }
}
export default withRouter(PrivateSubnav)
