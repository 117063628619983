import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { emailLogIn } from '../../redux/auth/actions'
import { chkEmail }  from '../../includes/functions'
import ServiceAndPolicies from '../components/ServiceAndPolicies'

import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class Register extends React.Component {
  state = {
    email: null,
    sent: false,
    message: null,
    btnText: 'submit',
    btnDisabled: false,
    checked: false
  }

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value, message: null})
  }
  
  chkCheckbox = () => {
    this.setState({ checked: !this.state.checked })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(this.state.email)) {
      return this.setState({ message: 'Please enter a valid email address.' })
    }
    if (!this.state.checked) {
      return this.setState({ message: 'Sorry, please agree with the TOS (Terms of Service)' })
    }
    this.setState({ btnText: 'Processing Request', btnDisabled: true })
    this.props.emailLogIn(this.state.email, this.props.match.params.type, this.props.location.search)
    .then(() => {
      this.setState({ sent: true })
    })
    .catch((err) => {
      this.setState({ btnText: 'Submit', btnDisabled: false, message: err }) 
    })
  }

  render() {
    const {sent, message, btnText, btnDisabled, checked } = this.state
    return (
      <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={5}>
            { sent ? (
                <Paper style={{ padding: 20 }} >
                  <Text variant='h5' gutterBottom>
                  Email Confirmation.
                  </Text>
                  <Text style={{ marginTop: 10, color: 'red', height: 50 }} variant='caption' component='p' align='center'>
                    A Login Link was sent to your email address - {this.state.email}.<br />
                    Welcome to LoyalTT
                  </Text>
                </Paper>
            ):(
                <Paper style={{ padding: 20 }} >
                  <Text variant='h5' gutterBottom>
                    {this.props.match.params.type.toUpperCase()}
                  </Text>
                  <Text variant='caption' component='p'>
                  {this.props.match.params.type.charAt(0).toUpperCase()+this.props.match.params.type.substring(1)} through your email account.
                  </Text>
                  <Text style={{ marginTop: 10, color: 'red', height: 50 }} variant='caption' component='p' align='center'>
                    { message }
                  </Text>

                  <form onSubmit={ this.handleSubmit }>
                    <TextField
                      type='text'
                      id='email'
                      label='Email'
                      placeholder='Enter your email address'
                      margin='normal'
                      fullWidth={true}
                      onChange={ this.handleChange }
                    />
                    <Checkbox
                      checked={checked}
                      onChange={this.chkCheckbox}
                      color='primary'
                    />
                    <ServiceAndPolicies tab={3} /><br/>
                    <div style={{ height: 20 }} />
                    <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                      { btnText }
                    </Button>
                    <div style={{ height: 20 }} />
                    { (this.props.match.params.type === 'register')
                        ? (<Button size='small' color='primary' component={ Link } to={'/email/login' + this.props.location.search}>Login through Email</Button>)
                        : (<Button size='small' color='primary' component={ Link } to={'/email/register' + this.props.location.search}>Register a New Account</Button>)
                    }                    
                    <Button size='small' color='primary' component={ Link } to={'/login' + this.props.location.search}>
                      Login with Email & Password
                    </Button>
                  </form>
                </Paper>
            )}
          </Grid>
        </Grid>  
      </Container>
    )
  }
}
const mapDispatchToProps = {
  emailLogIn
}
export default connect(null, mapDispatchToProps)(Register)
