import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringEvents, stopMonitoringEvents, createEvent } from '../../../redux/groupEvents/actions'
import { selectorLoadEvents } from '../../../redux/groupEvents/selectors'

import SubNav from './components/Subnav'
import New from './New'
import List from './List'

class GroupEventsContainer extends React.Component {
    state = {
      page: 1,
      count: 10,
      sort: 'Active',
      events: {},
      eventId: undefined,
      loading: true
    }
    componentDidMount = () => {
      this.props.startMonitoringEvents()
        .then(() => {
          this.setSort()
        })
        .catch((error) => {
          console.log('sorry there was an error - ', error)
          this.setSort()
        })
    }
    componentDidUpdate = (prevProps) => {
      if (this.props !== prevProps) {
        this.setSort()
      }
    }
    componentWillUnmount = () => {
      this.props.stopMonitoringEvents()
    }

    setSort = () => {
      let page = this.props.match.params.page || 1
      let count = this.props.match.params.count || 10
      let sort = this.props.match.params.sort || 'Active'
      let eventId = this.props.match.params.eventId || undefined

      let events = {}
      switch(sort) {
        case 'Active':
            events = this.props.activeEvents
          break
        case 'Pending':
            events = this.props.pendingEvents
          break
        case 'Past':
            events = this.props.pastEvents
          break
        default:
            events = {}
      }
      this.setState({ page: page, count: count, sort: sort, events: events, eventId: eventId, loading: false })
    }

    render() {
        let showOptions = (this.props.location.pathname === '/group/events/new-event') ? 0 : 1
        const {page, count, sort, events, eventId, loading} = this.state
        const {createEvent} = this.props
        const pendingCount = Object.keys(this.props.pendingEvents).length

        return (
            <React.Fragment>
                <SubNav sort={sort} showOptions={showOptions} pendingCount={pendingCount} />
                <Switch>
                  <Route path='/group/events/new-event' render={props => <New {...props} 
                      createEvent={createEvent}          
                    />} 
                  />
                  <Route path='/group/events' render={props => <List {...props}
                      page={page}
                      count={count}
                      sort={sort}
                      events={events} 
                      eventId={eventId}
                      loading={loading}
                    />} 
                  />
                </Switch>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    activeEvents: selectorLoadEvents(state.groupEvents.events, 'Active'),
    pendingEvents: selectorLoadEvents(state.groupEvents.events, 'Pending'),
    pastEvents: selectorLoadEvents(state.groupEvents.events, 'Past')
})
const mapDispatchToProps = {
  startMonitoringEvents, 
  stopMonitoringEvents, 
  createEvent
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupEventsContainer)
