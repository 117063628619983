import React from 'react'
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode'
import { createCanvas } from 'canvas'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

class IdCard extends React.Component {
    state = {
	    open: this.props.openID,
	    qrSrc: null,
	    bcSrc: null
  	}

    componentDidMount = () => {
        this.updateImages()
    }
       
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props !== prevProps) {
            this.updateImages()
        }
    }

    handleClose = () => {
        this.setState({qrSrc: null, bcSrc: null})
        this.props.handleOpenIDClose()
    }

    updateImages = () => {
        let encoded = this.props.selectedOption.id
        let _this = this
        QRCode.toDataURL(encoded, function (err, url) {
            _this.setState({qrSrc: url})
        })
        const canvas = createCanvas()
        JsBarcode(canvas, encoded, {
            format: 'CODE128',
            width:1,
            height:40,
            fontSize: 15,
            displayValue: false 
        })
        this.setState({bcSrc: canvas.toDataURL() })
    }

    render() {
        return (
            <div>
                <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={this.props.openID}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                >
                <DialogTitle > 
                    LoyalTT ID
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center'}}>
                    <img alt={'LoyalTT id: '+ this.props.selectedOption.id} src={this.state.qrSrc} style={{ width:'100%', maxWidth: 250, border: '1px solid #cccccc'}} /><br />
                    <b>{this.props.selectedOption.name}</b><br />
                    {this.props.selectedOption.id}<br />
                    <img alt={'LoyalTT id: '+ this.props.selectedOption.id} src={this.state.bcSrc} style={{ width:'100%', maxWidth: 270 }} />			  
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color='primary'>
                    Close
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default IdCard
