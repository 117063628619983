import React from 'react'
import { connect } from 'react-redux'
import { getMemberInfo } from '../../../redux/groupMembers/actions'
import Swal from 'sweetalert2'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/CropOriginal'
import CloseIcon from '@material-ui/icons/Close'

class MemberProfile extends React.Component {
    state = {
        tabValue: 0,
        memberDetails: {
            headerImage: '/images/default-groupHeader.jpg',
            summary: 'zzzz',
            login: 'billy'
        }
    }

    deleteMember = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.props.deleteUnregisteredMember(id)
                .then()
                .catch(() => {
                    Swal.fire(
                        'Error!',
                        'Sorry, there was an error, please try again.',
                        'error'
                      )
                })
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.groupId !== prevProps.groupId) {
            if (this.props.groupId) {
                this.props.getMemberInfo(this.props.memberId)
                .then((data) => {
                    let groupDetails = {
                        headerImage: data.headerImages[data.headerImageSelected].photo
                    }
                   this.setState({ groupDetails: groupDetails})
                    console.log(data)
                })
                .catch((error) => {
                    console.log('show error', error)
                })
            } else {
                console.log('close')
                this.setState({ groupDetails: {}})
            }
        }
    }

    handleChange = (event, tabValue) => {
        this.setState({ tabValue })
    }

    render() {
        //const {headerImage} = this.state.groupDetails
        const headerImage = ''
        return (
            <React.Fragment>
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.handleCloseMember}
            >
                <AppBar style={{ position: 'relative' }} >
                        <Toolbar>
                            <ImageIcon style={{ marginRight: 5 }} />
                            <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                                Group Details
                            </Text>
                            <IconButton color='inherit' onClick={this.props.handleCloseMember}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                <DialogContent style={{ padding:0, margin: 0}}>
                    <img src={headerImage} style={{ width: '100%'}} alt=''/>
                    <AppBar position="static" color="default">
                        <Tabs
                        variant="fullWidth" 
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        >
                        <Tab label="About" />
                        <Tab label="Posts" />
                        <Tab label="Connect" />
                        </Tabs>
                    </AppBar>
                    <div style={{padding: 10}}>
                        {(this.state.tabValue === 0) ? (
                            <About groupDetails={this.state.groupDetails}/>
                        ) : ( null )}
                        {(this.state.tabValue === 1) ? (
                            <Posts />
                        ) : ( null )}
                        {(this.state.tabValue === 2) ? (
                            <Connect />
                        ) : ( null )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleCloseMember} color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
    getMemberInfo
}
export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(MemberProfile))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const About = (props) => {
    return (
        <div>
            <p><b>Description of group. Member Status in Group. Option to join</b></p>
            <p>GroupId:</p>
        </div>
    )
}

const Posts = () => {
    return (
        <div>
            <p><b>list of all current posts</b></p>           
        </div>
    )
}

const Connect = () => {
    return (
        <div>
            <p><b>Options for Member to connect to group</b></p>
            <ul>
                <li>ask a question (support ticket)</li>
                <li>give suggestions</li>
                <li>make a complaint</li>
                <li>submit a review</li>
            </ul>            
        </div>
    )
}
