import React from 'react'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
var HtmlToReactParser = require('html-to-react').Parser

function TeamMemberEmail() {
    let subject, html
    let link = 'xxx'
    subject = 'GROUP invited you to join their team on LoyalTT'
    html  = '<div style="background-color:#F5F5F5; padding:5px">'
    html += '   <div align="center" style="margin-top:40px; font: 30px Arial, sans-serif; color:black"><b>LoyalTT</b></div>'
    html += '   <div style="padding:15px; max-width:600px; background-color:#fff; margin:10px auto; border: 2px solid #eee">'
    html += '       <div style="font:15px Arial, sans-serif; color:#8b8d8e">'
    html += '           Hello,'
    html += '           <p>Join your team on LoyalTT</p>'        
    html += '       </div>'
    html += '       <div style="background-color:#000; max-width:200px; padding:10px; margin:50px auto; text-align:center">'
    html += '           <a href=' + JSON.stringify(link) + ' style="color:white; text-decoration:none"><b>JOIN TEAM</b></a>'
    html += '       </div>'    
    html += '   </div>'
    html += '</div>'

    let htmlToReactParser = new HtmlToReactParser()
    let reactElement = htmlToReactParser.parse(html)

    // Preview Template
    return (
        <Container maxWidth='lg' style={{ padding: 10, minHeight:700  }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 10 }} >
                    <b>Subject:</b> {subject}
                    {reactElement}
                </Paper>
                <div align='right'>
                    <Text variant='caption' gutterBottom>
                        <Link to='/templates'>Bact To Templates</Link>
                    </Text>
                </div>
            </Grid>
        </Grid>
        </Container>
    )
}
export default TeamMemberEmail
