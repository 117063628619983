import React from 'react'
import swal from 'sweetalert2'
import queryString from 'query-string'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateMemberInfo, updateMemberPassword } from '../../../redux/member/actions'

import Loader from '../../components/Loader'
import SubNav from './components/SubNav'
import Password from './components/Password'
import Profile from './Profile'

class SetupContainer extends React.Component {
    qs = queryString.parse(this.props.location.search)
    state = {
        rURL: (this.qs.rURL) ? this.qs.rURL : '/',
        showPwBox: (this.qs.setPw) ? true : false,
        getOldPassword: false,
        loading:false
    }
    skip = () => {
        this.props.history.push({ pathname: this.state.rURL })
    }
    updateInfo = (info) => {
        this.setState({loading:true})
        this.props.updateMemberInfo(info)
        .then(() => {
            this.setState({loading:false})
            this.skip()
        })
        .catch((error) => {
            this.setState({loading:false})
            swal.fire({
                position: 'top-end',
                type: 'error',
                title: error,
                showConfirmButton: false,
                timer: 2000
            })
        })
    }
    updatePassword = (oldPassword, newPassword) => {
        let _this = this
        this.setState({loading:true})
        return new Promise(function (resolve, reject) {
            _this.props.updateMemberPassword(oldPassword, newPassword)    
            .then(() => {
                _this.setState({loading:false, showPwBox:false})
                swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Password Updated',
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch((error) => {
                if (error === 'please enter your current password') {
                    _this.setState({loading:false, getOldPassword:true})    
                } else {
                    _this.setState({loading:false})
                }                
                reject(error)
            })
        })
    }
    setPassword = (status) => {
        this.setState({showPwBox:status})
    }
    render() {
        const {loading, showPwBox, getOldPassword} = this.state
        const {memberInfo} = this.props
        if (!memberInfo) return null
        return (
            <React.Fragment>
                <Loader loading={loading}/> 
                <Password showPwBox={showPwBox} setPassword={this.setPassword} getOldPassword={getOldPassword} updatePassword={this.updatePassword}/>
                <div style={{ minHeight:700, marginBottom:100 }}>
                    <SubNav />
                    <Profile skip={this.skip} setPassword={this.setPassword} update={this.updateInfo} userInfo={memberInfo}/>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    memberInfo: state.auth.userInfo
})
const mapDispatchToProps = {
    updateMemberInfo,
    updateMemberPassword
}
export default connect(mapStateToProps, mapDispatchToProps)(SetupContainer)
