import { MEMBER_NOTIFICATIONS_LOAD } from '../types'

const initialState = {
    lastUpdated: 0,
    notifications: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEMBER_NOTIFICATIONS_LOAD:
      return { 
          ...state, 
          lastUpdated: action.payload.timestamp,
          notifications: action.payload.notifications

      }
	default:
	  return state
  }
}
