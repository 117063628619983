import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

class View extends React.Component {
    render() {
        const {thumb, email, name, gender, dob, nationality, location} = this.props.user
        if (!email) { return null }
        return (
            <div> 
                <div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
                    <img style={{ overflow: 'hidden', display: 'block', width: '100%', padding: 5, borderRadius: 10, border: '1px solid #ccc' }} 
                        src={thumb} 
                        alt={thumb} 
                    />
                </div>
                <Grid container justify='center' alignItems='flex-end'>
                    <Grid item xs={12} sm={12} style={{ padding: 5, textAlign:'center' }}>
                        <Button onClick={() => {this.props.setPassword(true)}}>Set Password</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5, marginTop:20 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Email Address'
                          defaultValue={email}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Name'
                          defaultValue={name}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Gender'
                          defaultValue={gender}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Date of Birth'
                          defaultValue={dob}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Nationality'
                          defaultValue={nationality}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField
                          style={{margin:0}}
                          fullWidth
                          label='Location'
                          defaultValue={location}
                          margin='normal'
                          InputProps={{ readOnly: true }}
                          variant='filled'
                        />
                    </Grid>
                </Grid>
                <div style={{marginTop:20}}><Button fullWidth variant='contained' color='secondary' onClick={() => {this.props.edit(null,1)}}>edit profile</Button></div>
                <div><Button fullWidth onClick={this.props.skip} style={{marginTop:10}}>skip</Button></div>
            </div>
        )
    }
}
export default View
