export const selectorLoadEvents = (events, status) => {
    let filteredEvents = {}
    let currentTime = Math.floor(Date.now() / 1000)
    switch(status) {
        case 'Active':
            Object.keys(events).forEach(function (i) {
                if (events[i].published && events[i].dateTimeEnd.seconds > currentTime) {
                    filteredEvents[i] = events[i]
                }
            })
            break
        case 'Pending':
            Object.keys(events).forEach(function (i) {
                if (!events[i].published) {
                    filteredEvents[i] = events[i]
                }
            })
            break
        case 'Past':
            Object.keys(events).forEach(function (i) {
                if (events[i].published && events[i].dateTimeEnd.seconds < currentTime) {
                    filteredEvents[i] = events[i]
                }
            })
            break
        default:
            filteredEvents = {}
      }
    return filteredEvents
}

export const selectorAttendance = (attendance, type) => {
    let filteredAttendance = []
    switch(type) {
        case 'ticket':
            Object.keys(attendance).forEach(function (i) {
                if (attendance[i].type === 'ticket') {
                    filteredAttendance[i] = attendance[i]
                }
            })
            break   
        case 'guest':
            let guests = attendance.filter(function(attendee) {
                return attendee.type === 'guest'
            })
            /* this is temp
            let guests = [
                {id: 'AG234DP',timestamp: 1211, group: '', guestOf: 'AG234DP', guests:2, firstName: 'Sean', lastName: 'Paul', highlight:''},
                {id: 'AG345TH', timestamp: 1239, group: '', guestOf: 'AG345TH', guests:3, firstName: 'Jerry', lastName: 'Jones', highlight:''},
                {id: 'AG123ED', timestamp: 1288, group: '', guestOf: 'AG123ED', guests:0, firstName: 'Mark', lastName: 'Walt', highlight:''},
                {id: 'AG555TT', timestamp: 1290, group: '', guestOf: 'AG234DP', guests:0, firstName: 'David', lastName: 'Grant', highlight:'VIP'},
                {id: 'AG098PP',timestamp: 1310, group: '', guestOf: 'AG345TH', guests:0, firstName: 'Michael', lastName: 'Bolton', highlight:''},
                {id: 'AG348MN', timestamp: 1322, group: '', guestOf: 'AG348MN', guests:0, firstName: 'Shelly', lastName: 'Belly', highlight:''},
                {id: 'AG234XX',timestamp: 1350, group: '', guestOf: 'AG345TH', guests:0, firstName: 'Sean', lastName: 'Paul', highlight:''},
                {id: 'AG234TT',timestamp: 1358, group: '', guestOf: 'AG345TH', guests:0, firstName: 'xxx', lastName: 'xxx', highlight:''},
                {id: 'AG234TX',timestamp: 1377, group: '', guestOf: 'AG234DP', guests:0, firstName: 'guest', lastName: '2', highlight:''},
              
            ]
            // this is temp */
            filteredAttendance = guests.sort(function(a, b){
                if (a.guestOfId === b.guestOfId) {
                    if (a.guests > b.guests) return -1;
                    if (a.guests < b.guests) return 1;
                }

                if (a.guestOfId > b.guestOfId) return -1;
                if (a.guestOfId < b.guestOfId) return 1;

                if (a.timestamp > b.timestamp) return -1;
                if (a.timestamp < b.timestamp) return 1;
            })
            break
        case 'invitation':
            Object.keys(attendance).forEach(function (i) {
                if (attendance[i].type === 'invitation') {
                    filteredAttendance[i] = attendance[i]
                }
            })
            break    
        default:
            filteredAttendance = []
    }

    return filteredAttendance
}

// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/