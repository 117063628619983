import { combineReducers } from 'redux'
import { AUTH_LOGOUT_USER } from './types'
import appReducer from './app/reducer'
import authReducer from './auth/reducer'
import groupReducer from './group/reducer'
import groupCommunicateReducer from './groupCommunicate/reducer'
import groupEventsReducer from './groupEvents/reducer'
import groupMembersReducer from './groupMembers/reducer'
import groupSupportReducer from './groupSupport/reducer'
import groupTeamReducer from './groupTeam/reducer'
import memberReducer from './member/reducer'
import memberCommunicateReducer from './memberCommunicate/reducer'
import memberSupportReducer from './memberSupport/reducer'

const allReducers = combineReducers({
    app: appReducer,
    auth: authReducer,
    group: groupReducer,
    groupCommunicate: groupCommunicateReducer,
    groupEvents: groupEventsReducer,
    groupMembers: groupMembersReducer,
    groupSupport: groupSupportReducer,
    groupTeam: groupTeamReducer,
    member: memberReducer,
    memberCommunicate: memberCommunicateReducer,
    memberSupport: memberSupportReducer,
})

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT_USER) {
        state = undefined
    }
    return allReducers(state, action)
}
  
export default rootReducer
