import React from 'react'
import moment from 'moment'
import ManageGuestList from './ManageGuestList'

import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  heading: { fontSize: theme.typography.pxToRem(15), flexBasis: '20%', flexShrink: 0 },
  secondaryHeading: { fontSize: theme.typography.pxToRem(15), color: theme.palette.text.secondary, paddingLeft: 5 },
  mutedHeading: { color: '#e6e6e6', fontSize: theme.typography.pxToRem(15), flexBasis: '20%', flexShrink: 0 },
  mutedSecondaryHeading: { color: '#e6e6e6', fontSize: theme.typography.pxToRem(15), paddingLeft: 5 }
}))

function Manage(props){
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const seconds = props.event.dateTimeStart.seconds
    const startDate = moment.unix(seconds).format('ddd Do MMM YYYY - h:mm a')
    return (
        <div>
            <div style={{padding: '10px 15px', margin:10, borderLeft: '4px solid #ff0000'}}>
                <Text color='primary'><b>{props.event.name}</b></Text>
                <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><i>{startDate}</i></Text>
                <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Type:</b> {props.event.type}</Text>
                <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Status:</b> {(props.event.published) ? 'Published' : 'Pending'}</Text>
            </div>
            <div><Text variant='h6' color='primary'><b>MANAGE</b></Text></div>
            
            {/* [START]: Manage Guestlist Section :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} TransitionProps={{ unmountOnExit: true }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Text className={(expanded === 'panel1') ? classes.mutedHeading : classes.heading}>GUESTLIST </Text>
                        <Text className={(expanded === 'panel1') ? classes.mutedSecondaryHeading : classes.secondaryHeading}>
                         <i>Manage the invited guests to your event.</i>
                        </Text>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{padding:0}}>
                        <ManageGuestList 
                            eventId={props.event.eventId}
                            guests={props.guests} 
                            guestlistAddEdit={props.guestlistAddEdit}
                            guestlistDelete={props.guestlistDelete}
                            guestlistImport={props.guestlistImport}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            {/* [END]: Manage Guestlist Section :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

            <div style={{height: 50}}/>
            UNDER CONSTRUCTION
            <ul>
                <li>Invitations</li>
                <li>Discount Codes</li>
                <li>Affilates</li>
                <li>setup team for event</li>
                <li>Promote Event</li>
            </ul>          
        </div>
    )
}
export default Manage
