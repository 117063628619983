import React from 'react'

function CreateEventInfo(){
    return (
        <div>
            <b>Create Event Info</b><br/>
            <ul>
                <li>Quick reference slides highlighting the features of an EVENT.</li>
                <li>One minute video overview of an EVENT.</li>
            </ul>
        </div>
    )
}
export default CreateEventInfo
