import { MEMBER_SUPPORT_LOAD_TICKETS
  //, MEMBER_SUPPORT_OPEN_TICKET, MEMBER_SUPPORT_UPDATE_TICKET, MEMBER_SUPPORT_CLOSE_TICKET 
} from '../types'

const initialState = {
    lastUpdated: 0,
    supportTickets: {
        123: { status: 'Open', group: 'Grilltopia', title:'What are your opening hours?' },
        456: { status: 'Closed', group: 'Grilltopia', title:'Problem with counter staff' },
        789: { status: 'Open', group: 'Friction Ent', title:'When do PHUKET 2020 tickets come out?' },
        101: { status: 'Open', group: 'Friction Ent', title:'Where can I get tickets online?' },
        121: { status: 'Open', group: 'Friction Ent', title:'Does the venue have wheelchair access?' }
    }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEMBER_SUPPORT_LOAD_TICKETS:
      return { 
          ...state, 
          memberships: action.payload
      }
	default:
	  return state
  }
}
