import React from 'react'
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import IconMenu from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class PrivateSubnav extends React.Component {
    state = {
        selectedTab: false,
        anchorEl: null,
        subMenu: {}
    }

    componentDidMount = () => {
        this.handleSetTabs(this.props)
    }
    
    componentWillReceiveProps = (nextProps) => {
        this.handleSetTabs(nextProps)
    }
    
    handleSetTabs = (props) => {
        let tab = false
        let routes = this.props.routes
        if (routes.views) {
            routes.views.map(function (links, i) {
                if (props.location.pathname.startsWith(links.path)) {
                    return tab = i
                }
                return null
            })
        }
        this.setState({ selectedTab: tab, subMenu: routes })
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value, anchorEl: null })
        this.props.history.push({ pathname: this.state.subMenu.views[value].path })
    }

    handleTitleClick = () => {
        this.setState({ anchorEl: null, selectedTab: false })
        this.props.history.push({ pathname: this.state.subMenu.path })        
    }
    
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    
    render() {
        return (
            <Container maxWidth='lg' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} >
                    <AppBar position='static' color='default'>
                        <Toolbar>
                            <Button onClick={() => {this.handleTitleClick()} } >
                            <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                <b>{this.state.subMenu.name}</b>
                            </Text>
                            </Button>
                            {(this.state.subMenu.views) ? ( 
                            <Hidden xsDown>
                                <Tabs
                                style={{ padding: 0, flexGrow:1 }}
                                value={ this.state.selectedTab }
                                onChange={ this.handleTabChange }
                                indicatorColor='primary'
                                textColor='primary'
                                variant='fullWidth'
                                >
                                {this.state.subMenu.views.map((option, index) => (
                                    (!option.hide) ? (
                                        <Tab key={index} label={option.name} style={{maxWidth: 180}} />
                                    ) : (null)
                                ))}
                                </Tabs>
                            </Hidden>
                            ) : (null) }
                            {(this.state.subMenu.views) ? ( 
                            <Hidden smUp>
                                <span style={{ flex: 1 }} />
                                <IconButton color='inherit' aria-haspopup='true'
                                aria-owns={this.state.anchorEl ? 'submenu' : null}
                                onClick={this.handleClick}
                                >
                                <IconMenu />
                                </IconButton>

                                <Menu id='submenu'
                                anchorEl={this.state.anchorEl}
                                open={ Boolean(this.state.anchorEl) }
                                onClose={ this.handleClose }
                                >
                                {this.state.subMenu.views.map((option, index) => (
                                    (!option.hide) ? (
                                    <MenuItem key={index}
                                        selected={index === this.state.selectedTab}
                                        onClick={event => this.handleTabChange(event, index)}
                                    >
                                        {option.name}
                                    </MenuItem>
                                    ) : (null)
                                ))}
                                </Menu>
                            </Hidden>
                            ):(null) }
                        </Toolbar>
                    </AppBar>
                </Grid>
            </Grid>
            </Container>
        )
    }
}

export default withRouter(PrivateSubnav)
