import React from 'react'
//import MemberCard from './components/MemberCard'
import MemberProfilePage from '../../components/MemberProfilePage/'
import MemberCard from '../../components/MemberCard'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const useStyles = (theme) => ({
    thumb: {
        width: 75,
        height: 75, 
        verticalAlign: 'top', 
        marginRight: 10,
        border: '1px solid #ccc', 
        borderRadius: 7
    },
    linkGroupName: {
        color: 'red', 
        textDecoration: 'none',
        lineHeight: 500,
        padding: 0

    },
    linkCreateGroupName: {
        color: '#b3b3b3', 
        textDecoration: 'none',
        padding: 0

    },
    cardContent: {
        display: 'flex',
        padding: 5,
        
    },
    cardActions: {
        backgroundColor: '#f7f8fa',
        padding: 0
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    }
})

class groupTeamSummary extends React.Component {
    state = {
        expanded: false,
        open: false
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.memberId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenMember = (memberId) => {
        let url = '/group/team/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter + '/' + memberId
        this.props.history.push({ pathname: url })
    }
    
    handleCloseMember = () => {
        let url = '/group/team/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter
        this.props.history.push({ pathname: url })        
    }

    handleChange = (panel) => {
        let expanded = (this.state.expanded === panel) ? false : panel
        this.setState({expanded: expanded})
    }

    addTeamMember = (role) => {
        let url = '/group/team/new-team-member/' + role
        this.props.history.push({ pathname: url }) 
    }

    render() {
        let {members, classes, memberId, loading } = this.props
        const {open, expanded} = this.state
        return (
            <React.Fragment>
            <MemberProfilePage open={open} handleCloseMember={this.handleCloseMember} memberId={memberId} />    
            
            <Container maxWidth='xl' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={8} md={7} style={{marginBottom:100}}>
                    
                    {(loading) ? (
                        <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                            <Paper style={{ padding: 20, textAlign: 'center' }} >
                                <Text variant='caption' gutterBottom>
                                    Loading Team
                                </Text>
                            </Paper>
                        </Grid>
                    ) : (null)}                      
                      
                    {(!loading) ? (  
                        <div style={{width: '100%'}}>
                            <ExpansionPanel expanded={expanded === 'panel1'} onChange={() => {this.handleChange('panel1')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Owner</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                        <Text className={classes.secondaryHeading}>{members['Owner'].activeCount} / {members['Owner'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['Owner'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Owner'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                            </ExpansionPanel>
                            
                            <ExpansionPanel expanded={expanded === 'panel2'} onChange={() => {this.handleChange('panel2')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Admins</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['Admin'].activeCount} / {members['Admin'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['Admin'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Admin'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('Admin')}}>Add a Admin</Button></div>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'panel3'} onChange={() => {this.handleChange('panel3')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Managers</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['Manager'].activeCount} / {members['Manager'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>

                                {members['Manager'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Manager'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('Manager')}}>Add a Manager</Button></div>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'panel4'} onChange={() => {this.handleChange('panel4')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Editors</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['Editor'].activeCount} / {members['Editor'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['Editor'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Editor'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('Editor')}}>Add a Editor</Button></div>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'panel5'} onChange={() => {this.handleChange('panel5')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>CSRs</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['CSR'].activeCount} / {members['CSR'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['CSR'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['CSR'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('CSR')}}>Add a CSR</Button></div>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'panel6'} onChange={() => {this.handleChange('panel6')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Attendants</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['Attendant'].activeCount} / {members['Attendant'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['Attendant'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Attendant'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('Attendant')}}>Add a Attendant</Button></div>
                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'panel7'} onChange={() => {this.handleChange('panel7')}}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Text className={classes.heading}>Sales Reps.</Text>
                                    <div style={{flexGrow: 1, textAlign:'right'}}>
                                    <Text className={classes.secondaryHeading}>{members['Sales_Rep'].activeCount} / {members['Sales_Rep'].totalCount}</Text>
                                    </div>
                                </ExpansionPanelSummary>
                                {members['Sales_Rep'].active.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='active' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                {members['Sales_Rep'].pending.map((member) => {
                                    return (<div key={member.docId} style={{margin:10}}><MemberCard hideRoles={true} showRoleStatus={true} roleStatus='pending' member={member} handleOpenMember={this.handleOpenMember} /></div>)
                                })}
                                <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {this.addTeamMember('Sales_Rep')}}>Add a Sales Rep</Button></div>
                            </ExpansionPanel>
                        </div>
                    ) : (null)}                      
                </Grid>
            </Grid>
            </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(groupTeamSummary)
