import React from 'react'
import moment from 'moment'

import SetupTicketAdd from './SetupTicketAdd'
import SetupTicketView from './SetupTicketView'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Text from '@material-ui/core/Typography'

const useStyles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    }
})

class Setup extends React.Component {
    state = {
        ticketsExpanded: false,
        ticketsNew: false
    }

    handleStateToggle = (panel) => {
        this.setState({ [panel]: !this.state[panel] })
    }

    render() {
        const { classes, event, ticketTypes, ticketTypeAddEdit, ticketTypeCopyDelete } = this.props
        const { ticketsExpanded, ticketsNew } = this.state
        const ticketCount = (ticketTypes.length) ? ticketTypes.length : 0
        const seconds = event.dateTimeStart.seconds
        const startDate = moment.unix(seconds).format('ddd Do MMM YYYY - h:mm a')
        return (
            <div>
                <div style={{padding: '10px 15px', margin:10, borderLeft: '4px solid #ff0000'}}>
                    <Text color='primary'><b>{event.name}</b></Text>
                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><i>{startDate}</i></Text>
                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Type:</b> {event.type}</Text>
                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Status:</b> {(event.published) ? 'Published' : 'Pending'}</Text>
                </div>
                <div><Text variant='h6' color='primary'><b>SETUP</b></Text></div>

                {/* [START]: Tickets Section :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
                <ExpansionPanel expanded={ticketsExpanded} onChange={() => {this.handleStateToggle('ticketsExpanded')}}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Text className={classes.heading}>TICKETS</Text>
                        <div style={{flexGrow: 1, textAlign:'right'}}>
                            <Text className={classes.secondaryHeading}>{(ticketCount === 1) ? '1 Ticket' : ticketCount + ' Tickets' }</Text>
                        </div>
                    </ExpansionPanelSummary>

                    {(ticketCount) ? (
                        <div>
                            {ticketTypes.map(ticket => {
                                return (<SetupTicketView key={ticket.ticketId} ticket={ticket} ticketTypeAddEdit={ticketTypeAddEdit} ticketTypeCopyDelete={ticketTypeCopyDelete} />)
                            })}
                        </div>     
                    ) : (
                        <div style={{ padding:20, textAlign:'center' }}>No Tickets Listed</div>
                    )}
                    <Collapse timeout={1200} in={ticketsNew}>
                        <SetupTicketAdd 
                        	handleStateToggle={this.handleStateToggle} 
                        	ticketTypeAddEdit={ticketTypeAddEdit} 
                        	ticketCount={ticketCount}
                        	eventId={event.eventId} 
                            eventPublished={event.published}
                        	eventStartTime={event.dateTimeStart.seconds}
                        	eventEndTime={event.dateTimeEnd.seconds}
                        />
                    </Collapse>
                    <ExpansionPanelActions>
                        <div style={{flexGrow:1, textAlign:'center', color: '#999', fontStyle: 'italic', fontSize: 'small'}}>
                            This is where you create ticket types.<br /> To Sell or Assign tickets, go to MANAGE.
                        </div>
                        <Button style={{whiteSpace: 'nowrap'}} size='small' onClick={() => {this.handleStateToggle('ticketsNew')}} disabled={ticketsNew} color='primary'>
                            Add Ticket
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
                {/* [END]: Tickets Section :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}


                <ul>
                    <li>Guest List</li>
                    <li>Invitations</li>
                    <li>Discount Codes</li>
                    <li>Affilates</li>
                    <li>setup team for event</li>
                    <li>Promote Event</li>
                    
                </ul>          
            </div>
        )
    }
}
export default withStyles(useStyles)(Setup)
