import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import firebase from '../includes/fbConfig'

function loadFromLocalStorage() {
  try {
    const serializedState = window.localStorage.mData
    if (serializedState === undefined) {
      return undefined
    }
    
    return JSON.parse(serializedState)
  } catch (e) {
  	console.log(e)
  	return undefined
  }
}

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    window.localStorage.mData = serializedState
  } catch (e) {
    console.log(e)
  }
}

const persistantState = loadFromLocalStorage()

const store = createStore(
  rootReducer,
  persistantState,
  compose(
  	applyMiddleware(thunk.withExtraArgument(firebase))//,
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)
store.subscribe(() => saveToLocalStorage(store.getState()))
export default store
