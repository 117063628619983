//import { MEMBER_SUPPORT_LOAD_TICKETS, MEMBER_SUPPORT_OPEN_TICKET, MEMBER_SUPPORT_UPDATE_TICKET, MEMBER_SUPPORT_CLOSE_TICKET } from '../types'

export function loadTickets() {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        console.log('load tickets')
        resolve()
    })
}

export function openTicket() {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        console.log('open new ticket')
        resolve()
    })
}

export function updateTicket(ticketId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        console.log('update ticket: ', ticketId)
        resolve()
    })
}

export function closeTicket(ticketId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        console.log('close ticket: ', ticketId)
        resolve()
    })
}
