import { GROUP_EVENTS_LOAD } from '../types'

const initialState = {
    lastUpdated: 0,
    events: {},
    eventDetails: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_EVENTS_LOAD:
      return { 
          ...state, 
          events: action.payload.events,
          lastUpdated: action.payload.lastUpdated
      }
	default:
	  return state
  }
}
