import React from 'react'

function Info(){
    return (
        <div>
            <b>Group Members Dialog</b><br/>
            - List of all group members, registered and unregistered.
        </div>
    )
}
export default Info
