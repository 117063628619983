import React from 'react'
import {  Link, withRouter } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberSupportList(props) {
    const {page, count, sort, filter, tickets} = props
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        {sort} Tickets
                    </Text>
                    <Text variant='caption' gutterBottom>
                        List of previously submitted tickets<br/>
                        List would be filtered and sorted according to settings
                    </Text>
                        {(Object.keys(tickets).length) ? (
                            Object.keys(tickets).map(function (i) {
                                return (
                                    <div key={i} style={{marginTop: 10}}>
                                        <Link to={'/member/support/'+page+'/'+count+'/'+sort+'/'+filter+'/'+i}>go to ticket: {tickets[i].title}</Link>
                                    </div>
                                )
                            })
                        ) : (
                            <div style={{marginTop: 50}}>
                                <Text variant='h5'>no tickets found</Text>
                            </div>
                        )}
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default withRouter(MemberSupportList)
