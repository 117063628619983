import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GetTheApp() {
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        GET THE APP
                    </Text>
                    <Text variant='caption' gutterBottom>
                        This is the GET THE APP page.
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default GetTheApp
