import React from 'react'

function Info(){
    return (
        <div>
            Group Events Dialog<br/>
            this should be quick reference slides highlighting the features of the Group Events section.
        </div>
    )
}
export default Info
