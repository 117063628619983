import React from 'react'
import { withRouter } from 'react-router-dom'
import Info from './Info'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'


class PrivateSubnav extends React.Component {
    state = { 
        sortOptions: ['Summary','Active','Pending'],
        showInfo: false 
    }

    handleSortChange = (event, value) => {
        let path = '/group/team/' + this.props.page + '/' + this.props.count + '/' + this.state.sortOptions[value] + '/' + this.props.filter
        this.props.history.push({ pathname: path })
    }
    
    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }

    render() {
        const {sortOptions, showInfo} = this.state
        const {sort, showOptions} = this.props
        const index = (sortOptions.indexOf(sort) >= 0) ? sortOptions.indexOf(sort) : 0
        return (
            <Container maxWidth='lg' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} >
                    <AppBar position='static' color='default'>
                        <Toolbar variant="dense">
                            <Button onClick={() => {this.props.history.push({ pathname: '/group/team/' }) }} >
                                <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                    <b>TEAM</b>
                                </Text>
                            </Button>
                            <div style={{flexGrow: 1}} />
                            {(showOptions) ? (
                                <Button size="small" onClick={()=> { this.props.history.push({ pathname: '/group/team/new-team-member'})    }}>
                                    add team member
                                </Button>
                            ) : (null)}

                        </Toolbar>
                    </AppBar> 

                    {(showOptions) ? (
                        <div style={{ display: 'flex', borderBottom: '2px solid #f1f1f1'}}>
                            <Tabs
                            value={index}
                            onChange={this.handleSortChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            >

                                {sortOptions.map((option) => (
                                    <Tab key={option} label={option} />
                                ))}
                            </Tabs>
                        
                            <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                            <IconButton onClick= { this.handleShowInfoToggle } >
                                <IconInfo />
                            </IconButton>
                            </div>
                        </div>
                    ) : (null)}
                    <Dialog open={showInfo} onClose={this.handleShowInfoToggle}>
                        <DialogContent>
                            <Info />
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleShowInfoToggle} color='primary' autoFocus>
                            Close
                        </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </Grid>
            </Container>
        )
    }
}
export default withRouter(PrivateSubnav)
