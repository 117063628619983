import React from 'react'
import swal from 'sweetalert2'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringMembers, stopMonitoringMembers, addMembers } from '../../../redux/groupMembers/actions'
import { selectorFilterMembers, selectorFilterMemberStatus } from '../../../redux/groupMembers/selectors'

import AccessDenied from '../../components/AccessDenied'
import Loader from '../../components/Loader'
import SubNav from './components/Subnav'
import New from './New'
import Summary from './Summary'
import LoyalTT from './LoyalTT'
import Emails from './Emails'

const whoHasAccess = [1,2,6]
class GroupMembersContainer extends React.Component {
    state = {
        page: 1,
        count: 10,
        sort: 'Summary',
        filter: 'All',
        memberId: undefined,
        members: [],
        loading: true
    }
    componentDidMount = () => {
        if(whoHasAccess.includes(this.props.roleId)) {
            this.props.startMonitoringMembers()
            .then(() => {
               this.setSort()
            })
            .catch((error) => {
                this.setState({Loading:false})
                console.log('sorry there was an error: ', error)
            })      
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            let pageChanged = (this.props.match.params.sort !== prevProps.match.params.sort) ? true : false
            this.setSort(pageChanged)
        }
    }
    componentWillUnmount = () => {
        this.props.stopMonitoringMembers()
    }
    setSort = (pageChanged) => {
        let page = (this.props.match.params.page) ? this.props.match.params.page : 1
        let count = (this.props.match.params.count) ? this.props.match.params.count : 10
        let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'Summary'
        let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
        let memberId = (this.props.match.params.memberId) ? this.props.match.params.memberId : undefined
        if (pageChanged) {
            page = 1
            count = 10
        }

        let members = []
        switch(sort) {
          case 'Summary':
              members = this.props.allMembers
            break
          case 'LoyalTT':
              members = this.props.loyalttMembers
            break
          case 'Emails':
              members = this.props.emailMembers
            break
          default:
              members = this.props.allMembers
        }
        this.setState({ page: page, count: count, sort: sort, filter: filter, memberId: memberId, members: members, loading: false })
    }

    render() {
        let showOptions = (this.props.location.pathname === '/group/members/add-members') ? 0 : 1
        const {page, count, sort, filter, memberId, members, loading} = this.state
        const {addMembers, statusFilter} = this.props
  
        if(!whoHasAccess.includes(this.props.roleId)) {
            return (<AccessDenied />)
        }

        return (
            <React.Fragment>
                <Loader loading={loading} />
                <SubNav page={page} count={count} sort={sort} filter={filter} showOptions={showOptions} />
                <Switch>
                  <Route path='/group/members/add-members' render={props => <New {...props} 
                      addMembers={addMembers}          
                    />} 
                  />
                  
                  { (sort === 'Summary') ? (
                    <Route path='/group/members' render={props => <Summary {...props}
                        page={page}
                        count={count}
                        sort={sort}
                        filter={filter}
                        statusFilter={statusFilter}
                        members={members} 
                        memberId={memberId}
                      />} 
                    />              
                  ) : ( null )}

                  { (sort === 'LoyalTT') ? (  
                    <Route path='/group/members' render={props => <LoyalTT {...props}
                        page={page}
                        count={count}
                        sort={sort}
                        filter={filter}
                        statusFilter={statusFilter}
                        members={members} 
                        memberId={memberId}
                      />} 
                    />              
                  ) : ( null )}  

                  { (sort === 'Emails') ? (
                    <Route path='/group/members' render={props => <Emails {...props}
                        page={page}
                        count={count}
                        sort={sort}
                        filter={filter}
                        statusFilter={statusFilter}
                        members={members} 
                        memberId={memberId}
                      />} 
                    />              
                  ) : ( null )}
                </Switch>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
    allMembers: state.groupMembers.members,
    loyalttMembers: selectorFilterMembers(state.groupMembers.members, 'LoyalTT'),
    emailMembers: selectorFilterMembers(state.groupMembers.members, 'Emails'),
    statusFilter: selectorFilterMemberStatus(state.groupMembers.members),
})
const mapDispatchToProps = {
  startMonitoringMembers, 
  stopMonitoringMembers,
  addMembers
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersContainer)
