import React from 'react'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconEvent from '@material-ui/icons/Event'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}))

const initialState = {
	eventId: null,
	ticketId: null,
	position: null,
	name: '',
	description: '',
	amountAvailable: 10,
	price: 0,
	serviceFee: 'Customer',
	dateTimeStart: moment(),
    dateTimeEnd: moment(),
    ticketLimit: 10,
    availability: 'both'
}

function SetupTicketAdd(props){
	const classes = useStyles()
	const [disableSave, setDisableSave] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [values, setValues] = React.useState({
		eventId: (props.ticket) ? props.ticket.eventId : props.eventId,
		ticketId: (props.ticket) ? props.ticket.ticketId : null,
		position: (props.ticket) ? props.ticket.position : props.ticketCount + 1,
    	name: (props.ticket) ? props.ticket.name : '',
    	description: (props.ticket) ? props.ticket.description : '',
    	amountAvailable: (props.ticket) ? props.ticket.amount.total : 10,
    	price: (props.ticket) ? (props.ticket.price / 100) : 0,
    	serviceFee: (props.ticket) ? props.ticket.serviceFee : 'Customer',
    	dateTimeStart: (props.ticket) ? moment.unix(props.ticket.dateTimeStart.seconds) : moment(),
        dateTimeEnd: (props.ticket) ? moment.unix(props.ticket.dateTimeEnd.seconds) : moment.unix(props.eventStartTime),
        ticketLimit: (props.ticket) ? props.ticket.ticketLimit : 10,
        availability: (props.ticket) ? props.ticket.availability : 'both'
  	})

  	const handleChange = name => event => {
    	setValues({ ...values, [name]: event.target.value });
  	}

  	const handleCancel = () => {
  		if(!props.ticket){
  			setValues(initialState)
  		}  		
  		props.handleStateToggle('ticketsNew')
  	}

  	const handleSave = () => {
  		if (values.name.length < 5) {
  			return setMessage('Ticket name must be at least 5 characters long.')	
  		}
  		if (!values.amountAvailable || isNaN(values.amountAvailable) || values.amountAvailable < 1) {
  			return setMessage('You must make at least 1 ticket available.')	
  		}
  		if (values.price === '' || isNaN(values.price) || values.price > 1000) {
  			return setMessage('Your ticket price cannot exceed $1,000.')	
  		}
  		// ticket sales cannot start after event
  		if (Math.floor(new Date(values.dateTimeStart) / 1000) > props.dateTimeStart) {
  			return setMessage('Ticket sales cannot start after event start time.')	
  		}
  		// ticket sales must end before event ends
  		if (Math.floor(new Date(values.dateTimeEnd) / 1000) > props.dateTimeEnd) {
  			return setMessage('Ticket sales must end before event ends.')	
  		}

  		// data good so proceed.
  		setMessage('Saving Ticket...')	
  		setDisableSave(true)
  		
  		props.ticketTypeAddEdit(values)  
		.then(() =>{
			console.log('ticket created')
			setMessage('')	
  			setDisableSave(false)  		
			if(!props.ticket){
	  			setValues(initialState)
	  		}
			props.handleStateToggle('ticketsNew')	
		})
		.catch((error) => {
			setMessage('There was an error saving ticket.')	
  			setDisableSave(false) 
			console.log('there was an error creating your ticket - ',error)
		})
  	}

    return (
        <Paper style={{marginTop:10, marginBottom:50}}>

            <div style={{ margin: '0 auto ', padding: 5, textAlign: 'center', backgroundColor:'#e8e8e8'}}>
            	<Text variant='h5' component='h2'>Add/Edit Ticket</Text>
            </div>
            <div style={{ padding: 5, backgroundColor: '#fcfcfc'}}> 
				<form className={classes.container} noValidate autoComplete="off">
			      	<TextField
				      	fullWidth
				        label='Ticket Name'
				        className={classes.textField}
				        value={values.name}
				        onChange={handleChange('name')}
				        margin='dense'
				        variant='outlined'
			      	/>
			      	<TextField
				      	fullWidth
				        label='Ticket Description'
				        className={classes.textField}
				        value={values.description}
				        onChange={handleChange('description')}
				        margin='dense'
				        variant='outlined'
				        multiline
	        			rows='2'
			      	/>
			      	<TextField
				      	fullWidth
				        label='Amount Available'
				        className={classes.textField}
				        value={values.amountAvailable}
				        onChange={handleChange('amountAvailable')}
				        margin='dense'
				        variant='outlined'
			      	/>
			      	<TextField
				      	fullWidth
				        label='Price'
				        className={classes.textField}
				        value={values.price}
				        onChange={handleChange('price')}
				        margin='dense'
				        variant='outlined'
				        InputProps={{
				          startAdornment: (<InputAdornment position='start'>$</InputAdornment>),
	        			}}
			      	/>
				    <TextField
				    	fullWidth
				        select
				        label='Online Service Fees'
				        className={classes.textField}
				        value={values.serviceFee}
				        onChange={handleChange('serviceFee')}
				        helperText='Flat fee of $1.50 US per ticket.'
				        margin='normal'
				        variant='outlined'
				    >
				        <MenuItem value='Customer'>Customer Pays</MenuItem>
				        <MenuItem value='Host'>Absorb Fees</MenuItem>
				    </TextField>
		            <MuiPickersUtilsProvider utils={MomentUtils}>
		                <DateTimePicker
		                    fullWidth
		                    margin="normal"
		                    label="Ticket Sale - Start Date & Time"
		                    minutesStep={5}
		                    invalidDateMessage= 'Invalid Date Format - Click Calendar Icon'
		                    minDate={new Date()}
		                    value={values.dateTimeStart}
		                    onChange={date => {setValues({ ...values, dateTimeStart: date })}}
		                    format="ddd Do MMM YYYY - LT"
		                    inputVariant='outlined'
		                    className={classes.textField}
		                    InputProps={{
					          endAdornment: (<InputAdornment position="end"><IconButton style={{padding:0}}><IconEvent /></IconButton></InputAdornment>)
					        }}
		                />
		                <DateTimePicker
		                    fullWidth
		                    margin="normal"
		                    label="Ticket Sale - End Date & Time"
		                    minutesStep={5}
		                    invalidDateMessage= 'Invalid Date Format - Click Calendar Icon'
		                    minDateMessage='End Date must be after Start Date'
		                    minDate={new Date()}
		                    value={values.dateTimeEnd}
		                    onChange={date => {setValues({ ...values, dateTimeEnd: date })}}
		                    format="ddd Do MMM YYYY - LT"
		                    inputVariant='outlined'
		                    className={classes.textField}
		                    InputProps={{
					          endAdornment: (<InputAdornment position="end"><IconButton style={{padding:0}}><IconEvent /></IconButton></InputAdornment>)
					        }}
		                 />
		            </MuiPickersUtilsProvider>
					<TextField
				    	fullWidth
				        select
				        label='Ticket Limit Per User'
				        className={classes.textField}
				        value={values.ticketLimit}
				        onChange={handleChange('ticketLimit')}
				        margin='normal'
				        variant='outlined'
				        helperText='Total amount of this type of ticket, each user is allowed to purchase.'
				    >
				        <MenuItem value='1'>1</MenuItem>
				        <MenuItem value='2'>2</MenuItem>
				        <MenuItem value='3'>3</MenuItem>
				        <MenuItem value='4'>4</MenuItem>
				        <MenuItem value='5'>5</MenuItem>
				        <MenuItem value='10'>10</MenuItem>
				        <MenuItem value='-' disabled> >10 Through Invoices </MenuItem>
				    </TextField>
					<TextField
				    	fullWidth
				        select
				        label='Availability'
				        className={classes.textField}
				        value={values.availability}
				        onChange={handleChange('availability')}
				        margin='normal'
				        variant='outlined'
				    >
				        <MenuItem value='both'>Online & Sales Reps</MenuItem>
				        <MenuItem value='online'>Online Only</MenuItem>
				        <MenuItem value='salesReps'>Sales Reps Only</MenuItem>
				        <MenuItem value='hidden'>Hidden (Available Through Invoices Only)</MenuItem>
				    </TextField>
			    </form>
            </div>
            <div style={{textAlign: 'center', padding: 10}}>
            	<Text variant='caption' style={{ color: 'red' }}>
                    {message}
                </Text>
            </div>
            <div style={{ margin: '0 auto ', padding: 5, textAlign: 'center', backgroundColor:'#e8e8e8'}}>
            	<Button size='small' onClick={handleCancel} style={{marginRight: 50}}>Cancel</Button>
            	<Button size='small' onClick={handleSave} color='primary' disabled={disableSave}>Save</Button>
            </div>

        </Paper>
    )
}
export default SetupTicketAdd
