import React from 'react'

function Info(){
    return (
        <div>
            TEAM<br/>
            <p>TEAM is a list of all the members that play a role in your group. </p>
            <p>CREW is a subset of the team and are assigned to specific tasks, projects, branches, etc. </p>
            <p>CREWS are created as templates to make it easier to assign teams to tasks like events etc. </p>
            <p>TEAM members can be on more than one CREW at a time.</p>
            SECURITY (access)<br/>
            <ul>
                <li>Owner/Admin 1 - Can do everything</li>
                <li>Everyone else - Can view approved team members (so they can see contact info)</li>
            </ul>
        </div>
    )
}
export default Info
