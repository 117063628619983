export function chkEmail(email) {
    let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(email)) {
        return true
    }
    return false
}

export function chkPassword(password) {
    let regExp = /^[a-zA-Z0-9!@#$%^&*]{6,26}$/
    if (regExp.test(password)) {
        return true
    }
    return false
}

export function customDelay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}