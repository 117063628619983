import React from 'react'

import SubNav from './components/Subnav'
import Summary from './Summary'

class MemberSettingsContainer extends React.Component {
  render() {
    return (
      <>
        <SubNav />
        <Summary />
      </>
    )
  }
}

export default MemberSettingsContainer
