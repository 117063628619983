import React from 'react'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import Summary from './Summary'

class GroupDashboardContainer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <SubNav />
                <Summary />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupDashboardContainer)
