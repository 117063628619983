import React from 'react'
import ImagePreview from './ImagePreview'
import ImageEditor from './ImageEditor'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/CropOriginal'
import CloseIcon from '@material-ui/icons/Close'
import withMobileDialog from '@material-ui/core/withMobileDialog'

class AddImage extends React.Component {
    state = {
        step: 'default',
        image: null,
        width: null,
        height: null
    }
    handlePreviewStep = (image, width, height) => {
        this.setState({ step: 'preview', image: image, width: width, height: height })
    }

    handleChangeImage = () => {
        this.setState({ step: 'default', image: null, width: null, height: null })
    }

    handleSaveImage = () => {
        let image = this.state.image
        this.setState({ step: 'default', image: null, width: null, height: null })
        this.props.cb(image)
        this.props.closeUi()
    }
    render() {
        const { fullScreen } = this.props
        let view
        if (this.state.step === 'preview') {
            view = (<ImagePreview
                  image={ this.state.image }
                  width={ this.state.width }
                  height={ this.state.height }
                  borderRadius={ this.props.borderRadius }
                  changeImage={ this.handleChangeImage }
                  saveImage={ this.handleSaveImage }
            />)
        } else {
            view = (<ImageEditor
                defaultImage={ this.props.defaultImage }
                ratio={ this.props.ratio }
                borderRadius={ this.props.borderRadius }
                handlePreviewStep={ this.handlePreviewStep }
            />)
        }        
        return (
            <Dialog
                open={ this.props.openUi }
                fullScreen={ fullScreen }
                scroll = { (fullScreen) ? 'paper' : 'body' }
                disableBackdropClick={ true }
            >
                <AppBar style={{ position: 'relative' }} >
                    <Toolbar>
                        <ImageIcon style={{ marginRight: 5 }} />
                        <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                            Image Upload
                        </Text>
                        <IconButton color='inherit' onClick={this.props.closeUi}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {view}
                </DialogContent>
                <DialogActions>
                    <Button onClick={ this.props.closeUi } variant='contained'>
                        Cancel
                    </Button>
                    <Button onClick={ this.props.closeUi } variant='contained' color='primary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default withMobileDialog()(AddImage)
