import React from 'react'

function PageNotFound() {
    return (
        <div>
            <h4>404 - Page Not Found</h4>            
        </div>
    )
}

export default PageNotFound
