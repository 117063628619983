import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringTeam, stopMonitoringTeam, addTeamMember } from '../../../redux/groupTeam/actions'
import { selectorLoadTeam } from '../../../redux/groupTeam/selectors'

import Loader from '../../components/Loader'
import SubNav from './components/Subnav'
import Summary from './Summary'
import List from './List'
import New from './New'

class GroupTeamContainer extends React.Component {
    state = {
      page: 1,
      count: 10,
      sort: 'Summary',
      filter: 'All',
      memberId: undefined,
      members: [],
      loading: true
    }
    componentDidMount = () => {
      this.props.startMonitoringTeam()
        .then(() => {
          this.setSort()
        })
        .catch((error) => {
          console.log('sorry there was an error - ', error)
        })  
    }
    componentDidUpdate = (prevProps) => {
      if (this.props !== prevProps) {
        let pageChanged = (this.props.match.params.sort !== prevProps.match.params.sort) ? true : false
        this.setSort(pageChanged)
    }
    }
    componentWillUnmount = () => {
      this.props.stopMonitoringTeam()
    }
    setSort = (pageChanged) => {
      let page = (this.props.match.params.page) ? this.props.match.params.page : 1
      let count = (this.props.match.params.count) ? this.props.match.params.count : 10
      let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'Summary'
      let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
      let memberId = (this.props.match.params.memberId) ? this.props.match.params.memberId : undefined
      if (pageChanged) {
        page = 1
        count = 10
      }

      let members = this.props.team
      this.setState({ page: page, count: count, sort: sort, filter: filter, memberId: memberId, members: members, loading: false })
    }

    render() {
      let showOptions = (this.props.location.pathname.startsWith('/group/team/new-team-member')) ? 0 : 1
      const {page, count, sort, filter, members, memberId, loading} = this.state
      const {addTeamMember} = this.props
        return (
            <React.Fragment>
                <Loader loading={loading} />
                <SubNav page={page} count={count} sort={sort} filter={filter} showOptions={showOptions} />
                <Switch>
                  <Route path='/group/team/new-team-member/:role?' render={props => <New {...props} 
                      addTeamMember={addTeamMember}      
                    />} 
                  />      

                  { (sort === 'Summary') ? (
                    <Route path='/group/team' render={props => <Summary {...props}
                        page={page}
                        count={count}
                        sort={sort}
                        filter={filter}
                        members={members} 
                        memberId={memberId}
                        loading={loading}
                      />} 
                    />              
                  ) : ( null )}

                  { (sort === 'Active' || sort === 'Pending') ? ( 
                    <Route path='/group/team' render={props => <List {...props}
                        page={page}
                        count={count}
                        sort={sort}
                        filter={filter}
                        members={members} 
                        memberId={memberId}
                        loading={loading}
                      />} 
                    />
                  ) : ( null )}
                </Switch>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    team: selectorLoadTeam(state.groupTeam.team)
})
const mapDispatchToProps = {
  startMonitoringTeam, 
  stopMonitoringTeam,
  addTeamMember
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupTeamContainer)
