import React from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function ProfileSummary(props) {
    return (
        <Container maxWidth='lg' style={{ padding: 10, marginBottom: 100 }}>
        <Grid container justify='center' spacing={2}>
           

        <Grid item xs={12} sm={9} md={7} >
                    <Paper style={{ padding: 20 }} >
                        <Grid container justify='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <img src={props.userInfo.photoThumb} alt='profile pic' style={{ borderRadius: 10, width: '100%', border: '1px solid #ccc', padding: 5 }} />
                                <Button variant='outlined' onClick={() => { props.history.push({ pathname: '/member/profile/photos' })}}>Edit Photos</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Text variant="h5" gutterBottom color='primary'>
                                    {props.userInfo.name}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>EMAIL</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.email}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>LOYALTT ID</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.id}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>DATE OF BIRTH</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.dob}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>GENDER</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.gender}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>LOCATION</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.location}
                                </Text>
                                <Text variant="caption" gutterBottom>
                                    <b>NATIONALITY</b>
                                </Text>
                                <Text variant="body2" gutterBottom  color='textSecondary'>
                                    {props.userInfo.nationality}
                                </Text>
                                <Button variant='outlined' onClick={() => { props.history.push({ pathname: '/member/profile/edit' })}}>Edit Profile</Button>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
           
           
           
           
           
            {/*
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        PROFILE - SUMMARY
                    </Text>
                    <Text variant='caption' gutterBottom>
                        This is your PROFILE SUMMARY page.
                        <pre>
                        {JSON.stringify(props.userInfo, null, 4)}
                        </pre>

                    </Text>
                </Paper>
            </Grid>
            */}
        </Grid>
        </Container>
    )
}

export default ProfileSummary