export const selectorLoadTeam = (members) => {
    let team = {
        Owner: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        Admin: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        Manager: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        Editor: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        CSR: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        Attendant: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
        Sales_Rep: {
            activeCount: 0,
            pendingCount: 0,
            totalCount: 0,
            active:[],
            pending:[]
        },
    }

    if (members.length){
        members.map(function (member) {
            if (member.roles.length){
                for (let i=0; i < member.roles.length; i++) {
                    let role = member.roles[i].role
                    if (member.roles[i].accepted) {
                        team[role].activeCount = team[role].activeCount + 1 
                        team[role].totalCount = team[role].totalCount + 1
                        team[role].active.push(member)
                    } else {
                        team[role].pendingCount = team[role].pendingCount + 1 
                        team[role].totalCount = team[role].totalCount + 1
                        team[role].pending.push(member)
                    }
                }
            }
            return true
        })
    }
    return team
}
