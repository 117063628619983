import React from 'react'

function Info(){
    return (
        <div>
            Notifications Info Dialog<br/>
            this should be quick reference slides highlighting the features of the Notifications.
        </div>
    )
}
export default Info
