import React from 'react'
import { withRouter } from 'react-router-dom'

import Text from '@material-ui/core/Typography'

function AddMembersUpload(props) {
    return (
        <div>
            <Text variant='overline' gutterBottom>
                this feature is under development
            </Text>
        </div>
    )
}
export default withRouter(AddMembersUpload)
