import React from 'react'
import { connect } from 'react-redux'
import { loadTickets, openTicket, updateTicket, closeTicket } from '../../../redux/memberSupport/actions'
import { selectorLoadTickets, selectorFilterList } from '../../../redux/memberSupport/selectors'

import SubNav from './components/Subnav'
import New from './New'
import List from './List'
import Ticket from './Ticket'

class MemberSupportContainer extends React.Component {
  state = {
    page: 1,
    count: 10,
    sort: 'New',
    filter: 'All',
    ticketId: null,
    tickets: {}
  }
  componentDidMount = () => {
    this.setSortFilter()
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.match.params !== prevProps.match.params) {
      this.setSortFilter()
    }
  }
  setSortFilter = () => {
    let page = (this.props.match.params.page) ? this.props.match.params.page : 1
    let count = (this.props.match.params.count) ? this.props.match.params.count : 10
    let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'New'
    let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
    let ticketId = (this.props.match.params.ticketId) ? this.props.match.params.ticketId : null
    
    let tickets = {}
    if (ticketId) {
        let allTickets = this.props.allTickets
        if (allTickets[ticketId]) {
          tickets = { [ticketId]: allTickets[ticketId] }
        }
    } else {
        switch(sort) {
          case 'Open':
              tickets = this.props.openTickets
            break
          case 'Closed':
              tickets = this.props.closedTickets
            break
          default:
              tickets = {}
        }

        if (Object.keys(tickets).length && filter !== 'All'){
          let filtered = {}
          Object.keys(tickets).map(function (i) {
              if (tickets[i].group === filter.replace(/_/g, ' ')) {
                  return filtered[i] = tickets[i]
              } else {
                return null
              }
          }) 
          tickets = filtered
        }
    }
    this.setState({ page: page, count: count, sort: sort, filter: filter, ticketId: ticketId, tickets: tickets })
  }
  
  render() {
    const {page, count, sort, filter, ticketId, tickets} = this.state
    const {filterGroupList, userGroups, userGroupsCategories, openTicket, updateTicket, closeTicket} = this.props
    return (
      <>
        <SubNav page={page} count={count} sort={sort} filter={filter} options={filterGroupList} />
        { (sort === 'New') ? (
          <New filter={filter} list={userGroups} categories={userGroupsCategories} openTicket={openTicket} />
        ) : (
          (ticketId) ? (
            <Ticket tickets={tickets} ticketId={ticketId} updateTicket={updateTicket} closeTicket={closeTicket} />
          ) : (
            <List page={page} count={count} sort={sort} filter={filter} tickets={tickets} />
          )
        )      
      }        
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allTickets: state.memberSupport.supportTickets,
  openTickets: selectorLoadTickets(state.memberSupport.supportTickets, 'Open'),
  closedTickets: selectorLoadTickets(state.memberSupport.supportTickets, 'Closed'),
  filterGroupList: selectorFilterList(state.memberSupport.supportTickets),
  userGroups: undefined, // list of all the groups the user is a member of
  userGroupsCategories: undefined // list of ticket categories for the selected group
})

const mapDispatchToProps = {
  loadTickets,
  openTicket,
  updateTicket,
  closeTicket
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSupportContainer)
