import React from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberNotificationNotification(props) {
    const {notificationId, notifications} = props
    console.log(notifications[0].saved)
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        Notification
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <ul>
                            <li>Notification ID: {notificationId}</li>
                        </ul>
                        <Button onClick={() => {props.saveNotificationToggle(notificationId, !notifications[0].saved)}}>Save this note</Button>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default MemberNotificationNotification
