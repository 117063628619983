import React from 'react'
import { connect } from 'react-redux'
import { startMonitoringEventDetails, stopMonitoringEventDetails } from '../../../../../redux/groupEvents/actions' 
import { ticketTypeAddEdit, ticketTypeCopyDelete } from '../../../../../redux/groupEvents/actions'
import { guestlistAddEdit, guestlistDelete, guestlistImport } from '../../../../../redux/groupEvents/actions'
import { selectorAttendance } from '../../../../../redux/groupEvents/selectors'

import Summary from './Summary'
import Manage from './Manage'
import Setup from './Setup'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconEvent from '@material-ui/icons/Event'
import Slide from '@material-ui/core/Slide'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

class Event extends React.Component {
    state = {
        tabValue: 0
    }
    componentDidMount = () => {
        this.props.startMonitoringEventDetails(this.props.eventId)
    }
    componentWillUnmount = () => {
        this.props.stopMonitoringEventDetails()
    }
    handleClose = () => {
        this.props.toggleEvent(undefined)
    }
    handleChange = (event, tabValue) => {
        this.setState({ tabValue })
    }
    render() {
        let {event, ticketTypes, tickets, guests, invitations} = this.props
        let {ticketTypeAddEdit, ticketTypeCopyDelete} = this.props
        let {guestlistAddEdit, guestlistDelete, guestlistImport} = this.props
        if(!event) { return null }
        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
            >
                <AppBar style={{ position: 'relative' }} >
                    <Toolbar>
                        <IconEvent style={{ marginRight: 5 }} />
                        <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                            {event.name} Details
                        </Text>
                        <IconButton color='inherit' onClick={this.handleClose}>
                            <IconClose />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ padding:0, margin: 0 }}>
                    <img src={event.headerImages[event.headerImageSelected].thumb} style={{ width: '100%'}} alt={event.name} />
                    <AppBar position="static" color="default">
                        <Tabs value={this.state.tabValue} onChange={this.handleChange} variant="fullWidth" indicatorColor="primary" textColor="primary">
                            <Tab label="Summary" />
                            <Tab label="Manage" />
                            <Tab label="Setup" />
                        </Tabs>
                    </AppBar>
                    <div style={{padding: 10, minHeight: 500}}>
                        {(this.state.tabValue === 0) ? (
                            <Summary />
                        ) : ( null )}
                        {(this.state.tabValue === 1) ? (
                            <Manage
                                event={event} 
                                tickets={tickets}
                                guests={guests}
                                invitations={invitations}
                                guestlistAddEdit={guestlistAddEdit}
                                guestlistDelete={guestlistDelete}
                                guestlistImport={guestlistImport}
                            />
                        ) : ( null )}
                        {(this.state.tabValue === 2) ? (
                            <Setup 
                                event={event} 
                                ticketTypes={ticketTypes} 
                                ticketTypeAddEdit={ticketTypeAddEdit}
                                ticketTypeCopyDelete={ticketTypeCopyDelete} 
                            />
                        ) : ( null )}
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:'#f1f1f1'}}>
                    <Button onClick={this.handleClose} color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
const mapStateToProps = (state, props) => ({
    event: state.groupEvents.events[props.eventId],
    ticketTypes: state.groupEvents.ticketTypes,
    tickets: selectorAttendance(state.groupEvents.attendance, 'ticket'),
    guests: selectorAttendance(state.groupEvents.attendance, 'guest'),
    invitations: selectorAttendance(state.groupEvents.attendance, 'invitation'),
})
const mapDispatchToProps = {
    startMonitoringEventDetails, 
    stopMonitoringEventDetails,
    ticketTypeAddEdit,
    ticketTypeCopyDelete,
    guestlistAddEdit, 
    guestlistDelete, 
    guestlistImport
}
export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(Event))
