import React from 'react'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import Summary from './Summary'
import List from './List'

class MemberDashboardContainer extends React.Component {
  state = {
    page: 1,
    count: 10,
    filter: 'Summary',
    posts: {}
  }
  componentDidMount = () => {
    this.setFilter()
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.match.params !== prevProps.match.params) {
      this.setFilter()
    }
  }
  setFilter = () => {
    let page = (this.props.match.params.page) ? this.props.match.params.page : 1
    let count = (this.props.match.params.count) ? this.props.match.params.count : 10
    let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'Summary'

    let posts = {}
    switch(filter) {
      case 'Posts':
          posts = {}
        break
      case 'Bookmarked':
          posts = {}
        break
      case 'Browse':
          posts = {}
        break
      default:
          posts = {}
    }

    this.setState({ page: page, count: count, filter: filter, posts: posts })
  }

    render() {
      const {page, count, filter, posts} = this.state
        return (
            <React.Fragment>
                <SubNav page={page} count={count} filter={filter} />
                {(filter === 'Summary') ? (
                  <Summary />
                ) : (
                  <List page={page} count={count} posts={posts} />
                )}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberDashboardContainer)
