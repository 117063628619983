import React from 'react'
import AddImage from './AddImage'
import SwipeableViews from 'react-swipeable-views'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import MobileStepper from '@material-ui/core/MobileStepper'

class ImageSelector extends React.Component {
    state = {
        activeStep: 0,
        addImage: false,
        images: this.props.images,
        maxSteps: this.props.images.length
    }
    componentDidMount = () => {
        if (this.props.currentImage) {
            let imagePosition = this.props.images.indexOf(this.props.currentImage) 
            if (imagePosition >= 0) {
                this.setState({ activeStep: imagePosition })
                this.props.selectImage(this.props.currentImage)
            } else {
                this.addNewImage(this.props.currentImage)
            }
        } else {
            this.props.selectImage(this.state.images[0])
        }
    }

    handleNext = () => {
        this.props.selectImage(this.state.images[this.state.activeStep+1])
        this.setState({ activeStep: this.state.activeStep+1 })
    }
    
    handleBack = () => {
        this.props.selectImage(this.state.images[this.state.activeStep-1])
        this.setState({ activeStep: this.state.activeStep-1 })
    }

    handleStepChange = (step) => {
        this.props.selectImage(this.state.images[step])
        this.setState({ activeStep: step })
    }    
    
    addImageOpen = () => {
        this.setState({ addImage: true })
    }
    addImageClose = () => {
        this.setState({ addImage: false })
    }
    addNewImage = (src) => {
        let images = this.state.images
        images.push(src)
        this.setState({ images: images, activeStep: images.length-1, maxSteps: images.length })
        this.props.selectImage(src)
    }
    render(){
        const {images, addImage, activeStep, maxSteps} = this.state
        const {imgRatio, imgBorderRadius, imgPadding, imgBorder} = this.props
        return (
            <>
            <AddImage 
                defaultImage={images[0]}
                ratio={imgRatio}
                borderRadius={imgBorderRadius}
                openUi={addImage} 
                closeUi={this.addImageClose} 
                cb={this.addNewImage} 
            />

            <div style={{position: 'relative'}}>
            <SwipeableViews
                axis='x'
                index={activeStep}
                onChangeIndex={this.handleStepChange}
                enableMouseEvents
            >
                {images.map((image, index) => (
                <div key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                    <img 
                        style={{
                            overflow: 'hidden',
                            display: 'block',
                            width: '100%',                        
                            padding: imgPadding, 
                            borderRadius: imgBorderRadius, 
                            border: imgBorder
                        }} 
                        src={image} 
                        alt={index} 
                    />
                    ) : null}
                </div>
                ))}
            </SwipeableViews>
            <Fab onClick={this.addImageOpen} size='small' color='secondary' aria-label='add' style={{position: 'absolute', bottom: 10, right:10}}>
            <AddIcon />
            </Fab>
            </div>
            {(maxSteps > 1) ? (
                <MobileStepper
                    steps={maxSteps}
                    position='static'
                    variant='text'
                    activeStep={activeStep}
                    nextButton={
                    <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                        Next <KeyboardArrowRight />
                    </Button>
                    }
                    backButton={
                    <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft /> Back
                    </Button>
                    }
                />
            ) : (null)}            

            </>
        )
    }
}
export default ImageSelector