import React from 'react'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function AccessDenied(){
    return (
        <div style={{ 
          zIndex: 1, 
          width: '100%', 
          height: '100%',
          backgroundColor: 'RGBA(0, 0, 0, 0.5)', // black
          position: 'fixed'
        }}>
            <div style={{ 
              zIndex: 2, 
              position: 'relative', 
              width: '100%', 
              maxWidth: 400, 
              margin: '0 auto', 
              padding: 10, 
              top: 50
            }}>
                <Paper style={{opacity: 1, padding: 20}}>
                  <Text variant="h5" component="h3">
                    Access Denied!
                  </Text>
                  <Text component="p">
                    Sorry, you do not have permission to access this page.
                  </Text>
                </Paper>
            </div>
        </div>
    )
}
export default AccessDenied
