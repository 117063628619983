import { GROUP_TEAM_LOAD, GROUP_TEAM_RESET_REDUCER } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Team Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorTeam
export function startMonitoringTeam() {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        console.log('start monitoring team')

        // delay this snapshot if userInfo not loaded as yet
        if(getstate().auth.userInfo == null){
            await customDelay(3000)
        }

        // set needed variables
        let sectionGroupId = getstate().groupTeam.groupId
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        // reset section reducer if groupId doesnt match sectionGroupId
        if (sectionGroupId && sectionGroupId !== gId) {
            dispatch({type: GROUP_TEAM_RESET_REDUCER})
        }

        // get all Team Members
        let teamRef = await firebase.firestore().collection('memberships')
            .where('groupId', '==', gId)
            .where('rolesCount', '>', 0)

        // monitor team changes
        monitorTeam = teamRef.onSnapshot((querySnapshot) => {
            let team = []
            querySnapshot.forEach((doc) => {
                let member = doc.data()
                member.docId = doc.id
                team.push(member)
            })
            let payload = {
                groupId: gId,
                team: team,
                timestamp: Math.floor(Date.now() / 1000)
            }
            resolve()
            dispatch({type: GROUP_TEAM_LOAD, payload: payload})
        })
    })
}        
export function stopMonitoringTeam(){
    return () => {
        console.log('stop monitoring team')
        monitorTeam && monitorTeam()
    }
}
// [END]: Team Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function addTeamMember(firstName,lastName,email,role) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let gId = getstate().groupTeam.groupId
        let roleIds = { Admin:1, Manager:2, Editor:3, CSR:4, Attendant:5, Sales_Rep:6 }

        // Setup return link
        let link = 'https://'+window.location.hostname+'/email/confirm/'
        if (window.location.hostname === 'localhost') {
            link = 'http://localhost:8000/email/confirm/'
        }

        // Setup actionCodeSettings
        let actionCodeSettings = {
            url: link + '?lttSetup=1&setPw=1&rURL=/page/accept-role&email='+email,
            handleCodeInApp: true
        }

        // call function
        const addTeamMember = firebase.functions().httpsCallable('addTeamMember')
        addTeamMember({ 
            actionCodeSettings:actionCodeSettings, 
            gId:gId, 
            firstName:firstName, 
            lastName:lastName, 
            email:email, 
            role:role, 
            roleId:roleIds[role] 
        })
        .then((returned) => {
            console.log('success - ', returned.data)
            resolve('success')
        })
        .catch((err) => {
            console.log('error - ', err)
            reject(err)
        })             
    })
}
