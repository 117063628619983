import React from 'react'
import MemberProfilePage from '../../components/MemberProfilePage/'
import MemberCard from '../../components/MemberCard'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class groupMembersSummary extends React.Component {
    state = {
        open: false
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.memberId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenMember = (memberId) => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter + '/' + memberId
        this.props.history.push({ pathname: url })
    }
    
    handleCloseMember = () => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter
        this.props.history.push({ pathname: url })        
    }

    render() {
        let {members, memberId } = this.props
        const {open} = this.state
        return (
            <React.Fragment>
                <MemberProfilePage open={open} handleCloseMember={this.handleCloseMember} memberId={memberId} />    
                
                <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
                <Grid container justify='center' spacing={2}>
                    <Grid item xs={12} sm={12} md={10}>
                        <Grid container justify='flex-start' spacing={2} style={{padding:6}}>
                            
                            <Grid container justify='flex-start' spacing={2} style={{padding:6}}>
                                <Grid item md={6} sm={6} xs={6}>
                                    <Paper style={{ padding: 20, minHeight: 100 }} >
                                        MEMBERS<br/>
                                        LoyalTT 22<br/>
                                        Emails  8<br/>
                                        Total   30
                                    </Paper>
                                </Grid>
                                <Grid item md={6} sm={6} xs={6}>
                                    <Paper style={{ padding: 20, minHeight: 100 }} >
                                        STATUS<br/>
                                        Approved 19<br/>
                                        Other    3<br/>
                                        Total    22
                                    </Paper>
                                </Grid>
                            </Grid>                    

                            <Grid style={{marginTop:10, padding:6}} container justify='flex-start' spacing={2}>
                                {(members.length) ? (
                                    members.map((member, index) =>{
                                        return (
                                            <Grid key={index} item md={4} sm={6} xs={12}>
                                                <MemberCard 
                                                    member={member}
                                                    handleOpenMember={this.handleOpenMember}
                                                />
                                            </Grid>
                                        )
                                    }) 
                                ) : (null)}                    
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                </Container>
            </React.Fragment>
        )
    }
}
export default groupMembersSummary
