import { chkPassword }  from '../../includes/functions'

// [START]: Group Functions ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function createGroup(groupInfo) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        const createGroup = firebase.functions().httpsCallable('createGroup')
        createGroup({ 
            name: groupInfo.name, 
            summary: groupInfo.summary, 
            type: groupInfo.type, 
            headerImage: groupInfo.headerImage, 
            profileImage: groupInfo.profileImage
        })
        .then((returned) => {
            // groupID is returned
            resolve(returned.data)
        })
        .catch((err) => {
            reject(err.message)
        })
    })
}
export function getGroupInfo(groupId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let groupInfo = firebase.firestore().collection('groups').doc(groupId)
        groupInfo.get()
            .then(doc => {
                if (doc.exists) {
                    resolve(doc.data())
                } else {
                    console.log('Document does not exist.');
                }
            })
            .catch(error => {
                console.log('error', error)
            })
    })
}
// [END]: Group Functions ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Account Setup ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export function updateMemberInfo(userInfo) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
        const updateProfile = firebase.functions().httpsCallable('updateProfile')
        updateProfile({ userInfo:userInfo })
        .then(() => {
          resolve('success')
        })
        .catch((err) => {
          reject(err.message)
        })

    })
}
export function updateMemberPassword(oldPassword, newPassword) {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        // Reauthenticate user with current password.
        if (oldPassword !== '') {
            try {
                const userEmail = getstate().auth.userInfo.email
                const credentials = firebase.auth.EmailAuthProvider.credential(userEmail,oldPassword)
                await firebase.auth().currentUser.reauthenticateWithCredential(credentials)
            }
            catch (error) {
                if (error.message === 'The password is invalid or the user does not have a password.') {
                    return reject('The Current Password you entered is invalid, please try again or login through your email.')
                } else {
                    return reject('Error authenticating your account, please re-enter your current password or login through your email then set your password.')    
                }
            }
        } 
        // Set new password
        if(newPassword){
            firebase.auth().currentUser.updatePassword(newPassword)
            .then(function() {
                resolve('Password updated')
            })
            .catch(function(error) {
                if (error.code === 'auth/requires-recent-login') {
                    reject('please enter your current password')    
                } else {
                    reject('Sorry, there was an error: ', error.message)  
                }
            })
        } else {
            reject('Please enter your password.')
        }        
    })
}
// [END]: Account Setup ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
