import React from 'react'

function DashboardList(){
    return (
        <div>
            Dashboard List
        </div>
    )
}
export default DashboardList
