import { AUTH_USER, AUTH_USER_INFO, AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_LOAD_USER, AUTH_LOAD_LOGIN_OPTIONS, AUTH_CHANGE_LOGIN_OPTION, MEMBER_MEMBERSHIPS_LOAD } from '../types'

// [START]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function logIn(email, password) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
            dispatch({ type: AUTH_LOGIN_USER })  
            resolve()
        }).catch((err) => {
            reject(err.message)
        })
    })
}

export function emailLogIn(email, type, rURL) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        // Setup return link
        let link = 'https://'+window.location.hostname+'/email/confirm/'
        if (window.location.hostname === 'localhost') {
            link = 'http://localhost:8000/email/confirm/'
        }
        if(rURL === ''){
            rURL = '?rURL='
        }
        // Setup actionCodeSettings
        let actionCodeSettings = {
            url: link + type + rURL + '&lttSetup=1',
            handleCodeInApp: true
        }

        firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function() {
            window.localStorage.setItem('emailForSignIn', email)
            resolve('complete')
        })
        .catch(function(err) {
            reject(err.message)
        })
    })
}

export function emailLogInConfirm(email, url){
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        if(email && url){
            firebase.auth().signInWithEmailLink(email, url)
            .then(function(result) {
                window.localStorage.removeItem('emailForSignIn') 
                dispatch({ type: AUTH_LOGIN_USER })
                resolve()
            })
            .catch(function(err) {
                reject(err.message)
            })
        } else {
            reject('Please enter your email address')
        }
    })
}

export function logOut(){
    return (dispatch, getstate, firebase) => {
        firebase.auth().signOut()
        .then(() => {
            dispatch({ type: AUTH_LOGOUT_USER })      
        }).catch((err) => {
            dispatch({ type: AUTH_LOGOUT_USER })
        })
    }
}
// [END]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function changeOption(option) {
    return (dispatch, getstate) => {
        let optionsCount = getstate().auth.loginOptions.length
        if (option >= optionsCount) {
            option = 0
        }
        dispatch({ type: AUTH_CHANGE_LOGIN_OPTION, payload: option })
    }
}

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ALL LISTENERS BELOW HERE ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Auth Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorLogin
let monitorUser
let monitorMemberships
export function monitorLoginStatus(){
    return (dispatch, getstate, firebase) => {
        monitorLogin = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                // save firebase auth.user info to reducer
                // THIS IS NOT BEING USED, CAN BE REMOVED IN PRODUCTION!!!
                dispatch({ type: AUTH_USER, payload: user })
                // activate member listener (member listener will activate membership listener)
                dispatch(monitorUserInfo(user.uid))
            } else {
                dispatch({ type: AUTH_LOGOUT_USER })
            }
        })
    }
}
export function stopMonitoringLoginStatus(){
    return () => {
        monitorLogin && monitorLogin()
        monitorUser && monitorUser()
        monitorMemberships && monitorMemberships()
    }
}
// [END]: Auth Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function monitorUserInfo(uid) {
    return (dispatch, getstate, firebase) => {   
        monitorUser = firebase.firestore().collection('users').doc(uid).onSnapshot(querySnapshot => {
            if (querySnapshot.data()) {
                let data = querySnapshot.data()
                data.thumb = data.images[data.imageSelected].thumb

                // save userInfo to reducer
                dispatch({ type: AUTH_USER_INFO, payload: data })
                // Create USER login options
                let user = {
                    login: 'member',
                    id: data.id,
                    name: data.name,
                    role: '',
                    roleId: 0,
                    thumb: data.thumb,
                    initials: data.initials        
                }
                dispatch({ type: AUTH_LOAD_USER, payload: user })
                // activate memberships listener and load additional login options
                dispatch(monitorMembershipsInfo(data.id, user))
            }
        }, err => {
            console.log('monitorUserInfo error:', err)
        })
    }
}
// [END]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Memberships Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function monitorMembershipsInfo(mId, user) {
    return (dispatch, getstate, firebase) => {   
        let db = firebase.firestore().collection('memberships')
        monitorMemberships = db.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
            // 1. update memberships --------------------------------------------
            let payload
            let memberships = []
            querySnapshot.forEach((doc) => {
                let member = doc.data()
                member.docId = doc.id
                memberships.push(member)
            })
            payload = {
                memberships: memberships,
                timestamp: Math.floor(Date.now() / 1000)
            }
            dispatch({type: MEMBER_MEMBERSHIPS_LOAD, payload: payload })
            // 2. update login options ------------------------------------------
            let options = []
            options.push(user)
            for (let i = 0; i < memberships.length; i++) {
                if (memberships[i].roles.length) {
                    memberships[i].roles.forEach(role => {
                        let option = {
                            login: 'group',
                            id: memberships[i].groupId,
                            name: memberships[i].groupName,
                            role: role.role,
                            roleId: role.roleId,
                            thumb: memberships[i].groupThumb,
                            initials: memberships[i].groupName.charAt(0).toUpperCase()
                        }
                        options.push(option)
                    })
                }
            }
            dispatch({ type: AUTH_LOAD_LOGIN_OPTIONS, payload: options })
        })
    }
}
// [END]: Memberberships Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
