import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import Summary from './Summary'

class MemberScannerContainer extends React.Component {
  render() {
    return (
      <>
        <SubNav/>
        <Switch>
          <Route path='/member/scanner' render={props => <Summary {...props} />} />
        </Switch>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberScannerContainer)
