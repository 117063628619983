import React from 'react'

function Summary(props){
    return (
        <div>
            <ul>
                <li>Days till event</li>
                <li>Tickets Sold</li>
                <li>Guest List</li>
            </ul>
            <p>What would you like to do?</p>
            <ul>
                <li>Sell Tickets</li>
                <li>Create a Guest list</li>
                <li>Send Invitations</li>
                <li>Create Discount Codes</li>
                <li>Setup Affilates</li>
                <li>--------</li>
                <li>View Ticket Holders</li>
                <li>Manage Guestlist</li>
                <li>View Invoices</li>
                <li>Create Invoice</li>
            </ul>
        </div>
    )
}
export default Summary
