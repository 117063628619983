import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import SubNav from '../../components/PrivateSubnav'
import Edit from './Edit'
import Photos from './Photos'
import Summary from './Summary'

const SubNavRoutes = {
  name: 'My Profile',
  path: '/member/profile',
  infoMessage: 'This is the message for your profile',
  views: [
    { name: 'Edit Profile', path: '/member/profile/edit', infoMessage: 'This is the edit message' },
    { name: 'Edit Photos', path: '/member/profile/photos', infoMessage: 'This is the photos' }
  ]
}

// use this module for address auto complete
//https://www.npmjs.com/package/react-places-autocomplete


class MemberProfileContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SubNav routes={SubNavRoutes}/>
        <Switch>
          <Route path='/member/profile/photos' render={props => <Photos {...props} 
              userInfo={this.props.userInfo}
            />} 
          />  
          <Route path='/member/profile/edit' render={props => <Edit {...props} 
              userInfo={this.props.userInfo}
            />} 
          />
          <Route path='/member/profile' render={props => <Summary {...props} 
              userInfo={this.props.userInfo}
            />} 
          />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state,ownProps) => ({
      userInfo: state.auth.userInfo
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfileContainer)
