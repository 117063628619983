import React from 'react'
import { Link } from 'react-router-dom'
import Info from './components/CreateGroupInfo'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class New extends React.Component {
    state = { 
        group: {
            name: '',
            summary: '',
            type: 'PUBLIC',
            headerImage: null,
            profileImage: null
        }, 
        headerImages: [ // ImageSelector variable,
            '/images/default-groupHeader.jpg',
            '/images/groups/groupHeader-1.jpg',
            '/images/groups/groupHeader-2.jpg',
            '/images/groups/groupHeader-3.jpg',
            '/images/groups/groupHeader-4.jpg'
        ],
        profileImages: [ // ImageSelector variable,
            '/images/default-groupProfile.jpg',
            '/images/groups/groupProfile-1.jpg',
            '/images/groups/groupProfile-2.jpg',
            '/images/groups/groupProfile-3.jpg',
            '/images/groups/groupProfile-4.jpg'
        ],
        activeStep: 0,
        showInfo: false
    }
    
    updateGroupInfo = (name, summary, type) => {
        let group = this.state.group
        group.name = name
        group.summary = summary
        group.type = type
        this.setState({ group:group })
        this.handleNext()
    }

    updateImage = (img, src) => {
        let group = this.state.group
        group[img] = src
        
        let images = []
        if (img === 'headerImage') {
            images = this.state.headerImages
        } else if (img === 'profileImage') {
            images = this.state.profileImages
        }
        if (!images.includes(src)) {
            images.push(src)
        }
        let name = img + 's'
        
        this.setState({ group:group, [name]: images })
        this.handleNext()
    }

    handleCreateGroup = (cb) => {
        this.props.createGroup(this.state.group)
        .then((groupId) => {
            this.props.history.push({ pathname: '/member/memberships/1/10/Roles/' + groupId })
        })
        .catch((error) => {
            cb({type:'error', message:error})
        })
    }

    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }   
    handleNext = () => { 
        this.setState({activeStep: this.state.activeStep + 1 })
    }
    
    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1 })
    }

    render() {
        const { group, activeStep, showInfo, headerImages, profileImages } = this.state
        return (
            <>
            <Grid container justify='center' style={{ paddingLeft: 8, paddingRight: 8 }}>
                <Grid item xs={12} sm={7} l={6}>
                <Paper elevation={4} style={{ width: '100%', maxWidth: 600, margin: '10px auto', padding: 10 }}>
                    <div style={{ display: 'flex' }}>
                        <Text variant='h5'>Create A Group</Text>
                        <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                        <IconButton onClick= {() => {this.handleShowInfoToggle()} } >
                            <IconInfo />
                        </IconButton>
                        </div>
                    </div>
                    <Stepper activeStep={activeStep} orientation='vertical' style={{ margin: -10, paddingLeft: 10 }}>
                    {/* [START]: STEP 1 - Details ::::::::::::::::::::: */}    
                        <Step key={1}>
                        <StepLabel>Group Details</StepLabel>
                        <StepContent>
                            <Step1 
                                group={group}
                                updateInfo={this.updateGroupInfo}
                            /> 
                        </StepContent>
                        </Step>
                    {/* [END]: STEP 1 - Details ::::::::::::::::::::::: */}

                    {/* [START]: STEP 2 - Header Image ::::::::::::::::::::: */}    
                        <Step key={2}>
                        <StepLabel>Header Image</StepLabel>
                        <StepContent>
                            <Step2 
                            currentImage={group.headerImage}
                            headerImages ={headerImages} 
                            updateImage={this.updateImage}
                            handleBack={this.handleBack}
                            />
                        </StepContent>
                        </Step>
                    {/* [END]: STEP 2 - Header Image ::::::::::::::::::::::: */}

                    {/* [START]: STEP 3 - Profile Image ::::::::::::::::::::: */}    
                    <Step key={3}>
                        <StepLabel>Profile Image</StepLabel>
                        <StepContent>
                            <Step3 
                            currentImage={group.profileImage}
                            profileImages ={profileImages} 
                            updateImage={this.updateImage}
                            handleBack={this.handleBack}
                            />
                        </StepContent>
                        </Step>
                    {/* [END]: STEP 3 - Profile Image ::::::::::::::::::::::: */}

                    {/* [START]: STEP 4 - Review Info ::::::::::::::::::::: */}    
                        <Step key={4}>
                        <StepLabel>Review</StepLabel>
                        <StepContent>
                            <Step4 
                            group={group}
                            handleBack={ this.handleBack }
                            handleCreateGroup={ this.handleCreateGroup }
                            />
                        </StepContent>
                        </Step>
                    {/* [END]: STEP 4 - Review Info ::::::::::::::::::::::: */}

                    </Stepper>
                </Paper>
                <div elevation={4} style={{ width: '100%', maxWidth: 600, margin: '1px auto', textAlign: 'right' }}>
                    <Button variant='outlined' size='small' color='primary' component={Link} to='/member/memberships'>
                    view memberships
                    </Button>
                </div>
                <div style={{ height: 150}}/>
                </Grid>
            </Grid>

            <Dialog open={showInfo} onClose= {() => {this.handleShowInfoToggle()} }>
                <DialogContent>
                    <Info />
                </DialogContent>
                <DialogActions>
                <Button onClick= {() => {this.handleShowInfoToggle()} } color='primary' autoFocus>
                    Close
                </Button>
                </DialogActions>
            </Dialog>
            </>
        )
    }
}
export default New
