import { MEMBER_MEMBERSHIPS_LOAD } from '../types'

const initialState = {
  lastUpdated: 0,
  memberships: [] // this is loaded in /auth/actions/monitorMembershipsInfo()
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEMBER_MEMBERSHIPS_LOAD: // this is loaded in /auth/actions/monitorMembershipsInfo()
      return { 
          ...state, 
          lastUpdated: action.payload.timestamp,
          memberships: action.payload.memberships
      }
	default:
	  return state
  }
}
