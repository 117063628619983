import React from 'react'
import { connect } from 'react-redux'
import { getGroupInfo } from '../../../redux/member/actions'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/CropOriginal'
import CloseIcon from '@material-ui/icons/Close'

class GroupProfile extends React.Component {
    state = {
        tabValue: 0,
        groupDetails: {
            headerImage: '/images/default-groupHeader.jpg',
            summary: 'zzzz',
            login: 'billy',
            groupId: 22
        }
    }

    componentDidUpdate = (prevProps) => {
                    console.log('xxxxxxxxxxxxxx')
        let _this = this
        if (this.props.groupId !== prevProps.groupId) {
            if (this.props.groupId) {
                this.props.getGroupInfo(this.props.groupId)
                .then((data) => {
                    let groupDetails = {
                        groupId: '_this.props.groupId',
                        headerImage: data.headerImages[data.headerImageSelected].photo
                    }
                   this.setState({ groupDetails: groupDetails})
                    console.log(data)
                })
                .catch((error) => {
                    console.log('show error', error)
                })
            } else {
                console.log('close')
                this.setState({ groupDetails: {}})
            }
        }
    }

    handleChange = (event, tabValue) => {
        this.setState({ tabValue })
    }

    render() {
        const {headerImage} = this.state.groupDetails
        return (
            <>
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.toggleGroup}
            >
                <AppBar style={{ position: 'relative' }} >
                        <Toolbar>
                            <ImageIcon style={{ marginRight: 5 }} />
                            <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                                Group Details
                            </Text>
                            <IconButton color='inherit' onClick={this.props.toggleGroup}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                <DialogContent style={{ padding:0, margin: 0}}>
                    <img src={headerImage} style={{ width: '100%'}} alt=''/>
                    <AppBar position="static" color="default">
                        <Tabs
                        variant="fullWidth" 
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        >
                        <Tab label="About" />
                        <Tab label="Posts" />
                        <Tab label="Connect" />
                        </Tabs>
                    </AppBar>
                    <div style={{padding: 10}}>
                        {(this.state.tabValue === 0) ? (
                            <About groupDetails={this.state.groupDetails}/>
                        ) : ( null )}
                        {(this.state.tabValue === 1) ? (
                            <Posts />
                        ) : ( null )}
                        {(this.state.tabValue === 2) ? (
                            <Connect />
                        ) : ( null )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.toggleGroup} color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
    getGroupInfo
}
export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(GroupProfile))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const About = (props) => {
    return (
        <div>
            <p><b>Description of group. Member Status in Group. Option to join</b></p>
            <p>GroupId: {props.groupDetails.groupId}</p>
        </div>
    )
}

const Posts = () => {
    return (
        <div>
            <p><b>list of all current posts</b></p>           
        </div>
    )
}

const Connect = () => {
    return (
        <div>
            <p><b>Options for Member to connect to group</b></p>
            <ul>
                <li>ask a question (support ticket)</li>
                <li>give suggestions</li>
                <li>make a complaint</li>
                <li>submit a review</li>
            </ul>            
        </div>
    )
}
