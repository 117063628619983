import React from 'react'
import { connect } from 'react-redux'
//import { updatePassword } from '../../../redux/auth/actions'
import { chkPassword }  from '../../../includes/functions'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'


class ProfileEdit extends React.Component {
    state = { 
        password: null, 
        confirm: null,
        message: null,
        btnText: 'submit',
        btnDisabled: false
      }
    
      handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value, message: null})
      }
    
      handleSubmit = (e) => {
        e.preventDefault()
        if (!chkPassword(this.state.password)) {
          return this.setState({ message: 'Sorry, please enter a valid password [6-26 chars].' })
        }
        if (this.state.password !== this.state.confirm) {
          return this.setState({ message: 'Sorry, re-entered password must match password.' })
        }
        this.setState({ btnText: 'updating password', btnDisabled: true })
  /*
        this.props.updatePassword(this.state.password)
        .then((message) => {
            this.setState({ btnText: 'Complete', message: message })
          })
        .catch((err) => {
          this.setState({ btnText: 'Submit', btnDisabled: false, message: err })
        })
    */
    }

    render() {  
        const { message, btnText, btnDisabled } = this.state
        return (
            <Container maxWidth='xl' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={8} md={8} >
                    <Paper style={{ padding: 20 }} >
                        <Text variant='h5' gutterBottom>
                            PROFILE - EDIT
                        </Text>
                        <Text variant='caption' gutterBottom>
                            This is your PROFILE EDIT page.
                        </Text>
                    </Paper>


                    <Paper style={{ padding: 20 }} >
                        <Text variant='h5' gutterBottom>
                            CHANGE PASSWORD
                        </Text>
                        <Text style={{ marginTop: 10, color: 'red', height: 15 }} variant='caption' component='p' align='center'>
                            { message }
                        </Text>
                        <form onSubmit={ this.handleSubmit }>
                            <TextField
                            type='text'
                            id='password'
                            label='Password'
                            placeholder='Enter a password'
                            margin='normal'
                            fullWidth={true}
                            onChange={ this.handleChange }
                            />
                            <TextField
                            type='text'
                            id='confirm'
                            label='Confirm'
                            placeholder='Re-enter password'
                            margin='normal'
                            fullWidth={true}
                            onChange={ this.handleChange }
                            />
                            <div style={{ height: 15 }} />
                            <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                            { btnText }
                            </Button>

                        </form>
                    </Paper>


                </Grid>
            </Grid>
            </Container>
        )
    }
}

const mapDispatchToProps = {
    //updatePassword
}
export default connect(null, mapDispatchToProps)(ProfileEdit)
  