import React from 'react'
import swal from 'sweetalert2'

import Loader from '../../components/Loader'
import Info from './components/Info'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const roles = {
    Admin: 'This is an admin. Admin have alot of power so choose whisely and even ;more if i mae it wider',
    Manager: 'this is ah manager',
    Editor:'this is ah editor',
    CSR:'this is a csr',
    Attendant:'attendant',
    Sales_Rep:'watta sales rep'
}

class groupTeamNew extends React.Component {
    state = {
        roleEl: null,
        showInfo: false,
        showMessage: true,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        message: '',
        submitButtonText: 'Add Team Member',
        submitButtonDisabled: false,
        loading: false
    }

    componentDidMount = () => {
        let role = (this.props.match.params.role) ? this.props.match.params.role : ''
        this.setState({role: role})
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, message: '' })
    }

    addTeamMember = () => {
        let firstName = this.state.firstName
        let lastName = this.state.lastName
        let email = this.state.email
        let role = this.state.role
        if(firstName === '' || lastName === '' || email === '' || role === '') {
            this.setState({ message: 'Sorry, all fields are required.'})
            return false
        }
        this.setState({ loading:true, submitButtonDisabled:true, submitButtonText:'updating...' })
        this.props.addTeamMember(firstName,lastName,email,role)
            .then(() => {
                this.props.history.push({ pathname: '/group/team/1/10/Pending' })
            })
            .catch((error) => {
                this.setState({ loading:false, submitButtonDisabled:false, submitButtonText:'Add Team Member' })
                swal.fire({
                    position: 'top-end',
                    type: 'error',
                    title: 'Error:'+ error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    } 

    render() {
        const {roleEl, showInfo, loading, showMessage, firstName, lastName, email, role} =this.state
        return (
            <React.Fragment>
                <Loader loading={loading} />
                <Container maxWidth='xl' style={{ padding: 10 }}>
                <Grid container justify='center' spacing={2}>

                    {(showMessage) ? (
                        <Grid item xs={12} sm={7} md={7} style={{margin: '0 auto'}}>
                        <div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
                            <span style={{flexGrow: 1}}>
                                <Text style={{marginBottom: 0, lineHeight:1}} variant='overline' display='block' gutterBottom>
                                    this action <u><b>would</b></u> send an email invitation to the address you enter here.
                                </Text>
                            </span>
                            <span>
                                <IconButton aria-label='close' size='small' onClick={() => {this.setState({showMessage: false})}}>
                                    <IconClose style={{color: '#c0c0c0'}} />
                                </IconButton>
                            </span>
                        </div>  
                        </Grid>
                    ) : ( null )} 

                    <Grid item xs={12} sm={7} md={6} style={{marginBottom: 100}} >
                        <Paper style={{ padding: 20 }} >
                            <div style={{ display: 'flex' }}>
                                <Text variant='h5'>Add Team Member</Text>
                                <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                                <IconButton onClick= {() => {this.handleShowInfoToggle()} } >
                                    <IconInfo />
                                </IconButton>
                                </div>
                            </div>
                            <Grid container justify='center'>
                            <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                                <TextField fullWidth
                                    label='First Name'
                                    name='firstName'
                                    value={firstName}
                                    onChange={this.handleChange}
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                                <TextField fullWidth
                                    label='Last Name'
                                    name='lastName'
                                    value={lastName}
                                    onChange={this.handleChange}
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                                <TextField fullWidth
                                    label='Email Address'
                                    name='email'
                                    value={email}
                                    onChange={this.handleChange}
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                                <List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
                                    <ListItem button onClick={(event) => { this.setState({roleEl: event.currentTarget}) }} style={{padding:10}}>
                                        <ListItemText primary={(role) ? 'Role: ' + role.replace(/_/g, ' ') : 'Select Role'} secondary={roles[role] || 'Select Team Member Role'} />
                                    </ListItem>
                                </List>             

      
                                <Menu 
                                    dense='true' 
                                    anchorEl={roleEl}
                                    open={Boolean(roleEl)}
                                    onClose={() => {this.setState({roleEl: null}) }}
                                >
                                    <MenuItem value='Admin' selected={role === 'Admin' } onClick={() => {this.setState({role: 'Admin', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Admin' secondary={roles['Admin']} />
                                    </MenuItem>
                                    <MenuItem value='Manager' selected={role === 'Manager' } onClick={() => {this.setState({role: 'Manager', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal'}} primary='Manager' secondary={roles['Manager']} />
                                    </MenuItem>
                                    <MenuItem value='Editor' selected={role === 'Editor' } onClick={() => {this.setState({role: 'Editor', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal'}} primary='Editor' secondary={roles['Editor']} />
                                    </MenuItem>
                                    <MenuItem value='CSR' selected={role === 'CSR' } onClick={() => {this.setState({role: 'CSR', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal'}} primary='CSR' secondary={roles['CSR']} />
                                    </MenuItem>
                                    <MenuItem value='Attendant' selected={role === 'Attendant' } onClick={() => {this.setState({role: 'Attendant', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal'}} primary='Attendant' secondary={roles['Attendant']} />
                                    </MenuItem>
                                    <MenuItem value='Sales_Rep' selected={role === 'Sales_Rep' } onClick={() => {this.setState({role: 'Sales_Rep', roleEl: null})}}>
                                      <ListItemText style={{whiteSpace: 'normal'}} primary='Sales Rep' secondary={roles['Sales_Rep']} />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        <div align='center' style={{ height: 25 }}>
                            <Text variant='caption' style={{ color: 'red' }}>
                                {this.state.message}
                            </Text>
                        </div>
                        <div align='right'>
                            <Button variant='contained' color='primary' disabled={this.state.submitButtonDisabled} onClick={this.addTeamMember}>
                                {this.state.submitButtonText}
                            </Button>
                        </div>
                        </Paper>
                        <div style={{ textAlign: 'right', width: '100%', maxWidth: 600, padding: 10, marginBottom: 80 }}>
                            <Button onClick={() => {this.props.history.push({ pathname: '/group/team' })}}>Back to Team</Button>
                        </div>
                    </Grid>
                </Grid>
                </Container>

                <Dialog open={showInfo} onClose= {() => {this.handleShowInfoToggle()} }>
                    <DialogContent>
                        <Info />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick= {() => {this.handleShowInfoToggle()} } color='primary' autoFocus>
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}
export default groupTeamNew
