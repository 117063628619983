import React from 'react'
import moment from 'moment'
import EventPage from './components/event/Index'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/RateReview'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const useStyles = {
    thumb: {
        width: 75,
        height: 75, 
        verticalAlign: 'top', 
        marginRight: 10,
        border: '1px solid #ccc', 
        borderRadius: 7
    },
    button: {
        padding:0, 
        margin:0, 
        lineHeight:1, 
        textAlign:'left', 
        textTransform:'none', 
        display:'inline'
    }
}

class GroupEventsList extends React.Component {    
    state = { open: false }

    componentDidUpdate = (prevProps) => {
        if (this.props.match.params !== prevProps.match.params) {
            if (this.props.eventId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenEvent = (eventId) => {
        let url = '/group/events/' + this.props.page + '/' + this.props.count + '/' + this.props.sort + '/' + eventId
        this.props.history.push({ pathname: url })
    }

    handleCloseEvent = () => {
        let url = '/group/events/' + this.props.page + '/' + this.props.count + '/' + this.props.sort
        this.props.history.push({ pathname: url })        
    }

    render() {
        const {open} = this.state
        const {classes, loading, events, eventId} = this.props

        return (
            <React.Fragment>
                {(eventId) ? (
                    <EventPage open={open} toggleEvent={this.handleCloseEvent} eventId={eventId} />
                ) : ( null)}

                <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
                <Grid container justify='center' spacing={2}>
                    <Grid item xs={12} sm={12} md={10}>
                        <Grid container justify='flex-start' spacing={2}>

                            {(loading) ? (
                                <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                    <Paper style={{ padding: 20, textAlign: 'center' }} >
                                        <Text variant='caption' gutterBottom>
                                            Loading Events
                                        </Text>
                                    </Paper>
                                </Grid>
                            ) : (null)}

                            {(!loading && !Object.keys(events).length) ? (
                                <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                    <Paper style={{ padding: 20, textAlign: 'center' }} >
                                        <Text variant='caption' gutterBottom>
                                            No Events Listed
                                        </Text>
                                    </Paper>
                                </Grid>
                            ) : (null)}

                            {(Object.keys(events).length) ? (
                                <Grid style={{marginTop:10, padding:6}} container justify='flex-start' spacing={2}>
                                    {Object.keys(events).map(i => {
                                        let seconds = events[i].dateTimeStart.seconds
                                        let startDate = moment.unix(seconds).format('ddd Do MMM YYYY - h:mm a')
                                        return (
                                            <Grid key={i} item md={4} sm={6} xs={12}>
                                                <Paper style={{display: 'flex', padding: 5}}>
                                                    <span><img onClick={() => { this.handleOpenEvent(i) }} src={events[i].profileImages[events[i].profileImageSelected].thumb} className={classes.thumb} alt={events[i].name} /></span>
                                                    <span style={{flexGrow: 1}}>
                                                    <Button onClick={() => { this.handleOpenEvent(i) }} className={classes.button}>{events[i].name}</Button>
                                                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><i>{startDate}</i></Text>
                                                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Type:</b> {events[i].type}</Text>
                                                    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' ><b>Status:</b> {this.props.sort}</Text>
                                                    </span>
                                                    <span>
                                                    <IconButton onClick={() => { this.handleOpenEvent(i) }} size='small'>
                                                        <IconEdit style={{color: '#c0c0c0'}} />
                                                    </IconButton>
                                                    </span>
                                                </Paper>
                                            </Grid>
                                        )
                                    })} 
                                </Grid>
                            ) : (null)}
                        </Grid>
                    </Grid>
                </Grid>
                </Container>
            </React.Fragment>
        )
    }
}
export default withStyles(useStyles)(GroupEventsList)
