import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconMenu from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        marginLeft: -20,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}))

const PublicTopnav = (props) => {
    const style = useStyles()
    return (
        <AppBar position='static'>
            <Toolbar>
                <IconButton className={ style.menuButton } color='inherit' onClick={ props.sideNavToggle }>
                    <IconMenu />
                </IconButton>
                <div className={ style.title }>
                    <Button size='small' color='inherit' component={ Link } to='/'>
                        <Text variant='h6' color='inherit'>LoyalTT.com</Text>
                    </Button>
                </div>
                <Button size='small' color='inherit' component={ Link } to={'/login' + props.location.search}>Login</Button>
                <Button size='small' color='inherit' component={ Link } to={'/email/register' + props.location.search}>Register</Button>
            </Toolbar>
        </AppBar>
    )
}

export default withRouter(PublicTopnav)
