import React from 'react'

function CreateGroupInfo(){
    return (
        <div>
            <b>Create Group Info</b><br/>
            <ul>
                <li>Quick reference slides highlighting the features of a GROUP.</li>
                <li>One minute video overview of a GROUP.</li>
            </ul>
        </div>
    )
}
export default CreateGroupInfo
