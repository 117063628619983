const initialState = {
    lastUpdated: 0,
    groupId: null,
    members: {
        fbdocid: { accepted: 1, crew:'', name: 'David Grant', email: 'email', thumb: 'Invoice', role: 'Admin', registered: 0 },
        cccddf: { accepted: 0, crew:'', name: 'Sean Paul', email: 'email', thumb: 'Invoice', role: 'Admin', registered: 0 },
        cyhjyy: { accepted: 0, crew:'', name: 'Michael Moore', email: 'email', thumb: 'Invoice', role: 'Admin', registered: 0 },
        cdsplk: { accepted: 0, crew:'', name: 'Shelly Belly', email: 'email', thumb: 'Invoice', role: 'Admin', registered: 0 },
    }
}
    
export default function reducer(state = initialState, action) {
    switch (action.type) {
    case 'GROUP_MEMBERS_LOADxxx':
        return { 
            ...state, 
            lastUpdated: action.payload.lastUpdated,
            members: action.payload.members
        }
    default:
        return state
    }
}
