import React from 'react'
import GroupProfilePage from '../../components/GroupProfilePage/'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/RateReview'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const useStyles = {
    thumb: {
        width: 75,
        height: 75, 
        verticalAlign: 'top', 
        marginRight: 10,
        border: '1px solid #ccc', 
        borderRadius: 7
    },
    linkGroupName: {
        color: 'red', 
        textDecoration: 'none',
        lineHeight: 500,
        padding: 0

    },
    linkCreateGroupName: {
        color: '#b3b3b3', 
        textDecoration: 'none',
        padding: 0

    },
    cardContent: {
        display: 'flex',
        padding: 5,
        
    },
    cardActions: {
        backgroundColor: '#f7f8fa',
        padding: 0
    }
}

class MemberMembershipsSummary extends React.Component {   
    state = { open: false, showCount: true }
    
    componentDidUpdate = (prevProps) => {
        if (this.props.match.params !== prevProps.match.params) {
            if (this.props.groupId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenGroup = (groupId) => {
        let url = '/member/memberships/' + this.props.page + '/' + this.props.count + '/' + this.props.sort + '/' + groupId
        this.props.history.push({ pathname: url })
    }
    
    handleToggleGroup = () => {
        let url = '/member/memberships/' + this.props.page + '/' + this.props.count + '/' + this.props.sort
        this.props.history.push({ pathname: url })        
    }

    render() {
        let {open, showCount} = this.state
        let {classes, sort, memberships, groupId} = this.props
        let message
        switch (sort) {
            case 'Inactive':
                message = 'You have no INACTIVE memberships.'
                break
            case 'Roles':
                message = 'You currenly have no active role in any group.'
                break
            default:
                message = 'Currently you have no ACTIVE memberships.'   
        }
        return (
            <React.Fragment>
            {(groupId) ? (
                <GroupProfilePage open={open} toggleGroup={this.handleToggleGroup} groupId={groupId} />    
            ) : ( null)}

            <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10}>
                    <Grid container justify='flex-start' spacing={2} style={{padding:6}}>

                        {(!memberships.length) ? (
                            <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                <Paper style={{ padding: 20, textAlign: 'center' }} >
                                    <Text variant='caption' gutterBottom>
                                        {message}
                                    </Text>
                                </Paper>
                            </Grid>
                        ) : (null)}

                        {(memberships.length) ? (    
                            (showCount) ? (
                                <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                <div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
                                    <span style={{flexGrow: 1}}>
                                        <Text style={{marginBottom: 0}} variant='overline' display='block' gutterBottom>you have {memberships.length} {sort} memberships</Text>
                                    </span>
                                    <span>
                                        <IconButton aria-label='close' size='small' onClick={() => {this.setState({showCount: false})}}>
                                            <IconClose style={{color: '#c0c0c0'}} />
                                        </IconButton>
                                    </span>
                                </div>  
                                </Grid>
                            ) : ( null )
                        ) : (null)}   

                        {(memberships.length) ? (
                            <Grid style={{marginTop:10, padding:6}} container justify='flex-start' spacing={2}>
                                {memberships.map((membership, index) =>{
                                    let rolesArray = []
                                    for (let x = 0; x < membership.roles.length; x++) {
                                        rolesArray.push(membership.roles[x]['role'])
                                    }
                                    let roles = (rolesArray.length) ? rolesArray.join(', ') : ''
                                    return (
                                        <Grid key={index} item md={4} sm={6} xs={12}>
                                            <Paper style={{display: 'flex', padding: 5}}>
                                                <span><img onClick={() => { this.handleOpenGroup(membership.groupId) }} src={membership.groupThumb} className={classes.thumb} alt="Logo" /></span>
                                                <span style={{flexGrow: 1}}>
                                                <Button onClick={() => { this.handleOpenGroup(membership.groupId) }} style={{padding:0, margin:0, lineHeight:1, textAlign:'left'}}>{membership.groupName}</Button>
                                                <Text style={{ lineHeight:1.4}} variant="caption" display="block" color="textSecondary" ><b>Group Type:</b> {membership.groupType}</Text>
                                                <Text style={{ lineHeight:1.4}} variant="caption" display="block" color="textSecondary" ><b>Status:</b> {membership.groupStatus}</Text>
                                                { (roles) 
                                                    ? (<Text variant="caption" display="block" color="textSecondary" ><b>Role:</b> { roles}</Text>)
                                                    : (null)
                                                }
                                                </span>
                                                <span>
                                                <IconButton onClick={() => { this.handleOpenGroup(membership.groupId) }} aria-label="delete" size="small">
                                                    <IconEdit style={{color: '#c0c0c0'}} />
                                                </IconButton>
                                                </span>
                                            </Paper>
                                        </Grid>
                                    )
                                })} 
                            </Grid>
                        ) : (null)}

                    </Grid>
                </Grid>
            </Grid>
            </Container>
            </React.Fragment>
        )
    }
}
export default withStyles(useStyles)(MemberMembershipsSummary)
