import React, {useState} from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/SwipeableDrawer'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconEvent from '@material-ui/icons/Event'
import IconPortrait from '@material-ui/icons/Portrait'
import IconListAlt from '@material-ui/icons/ListAlt'
import IconLiveHelp from '@material-ui/icons/LiveHelp'
import IconMessage from '@material-ui/icons/Message'
import IconMyGroups from '@material-ui/icons/GroupWork'
import IconMyMemberships from '@material-ui/icons/GroupAdd'
import IconRecentActors from '@material-ui/icons/RecentActors'
import IconSettings from '@material-ui/icons/Tune'
import IconShare from '@material-ui/icons/Share'
import IconSlideshow from '@material-ui/icons/Slideshow'
import IconWidgets from '@material-ui/icons/Widgets'

const useStyles = makeStyles(theme => ({
    list: {
        width: 300
    },
    active: {
        backgroundColor: '#E8E8E8'
    },
    inactive: {
        backgroundColor: '#ffffff'
    }
}))

const PrivateSidenav = (props) => {
    const style = useStyles()
    const [openMembers, setOpenMembers] = useState(false)
    const [openTeams, setOpenTeams] = useState(false)
    const clickLink = (link) => {
        props.history.push({ pathname: link })
        props.sideNavToggle()
        setOpenMembers(false)
        setOpenTeams(false)
    }
    return (
        <div>
            <Drawer open={props.sideNavOpen} onClose={props.sideNavToggle} onOpen={props.sideNavToggle} >
            <div className={style.list}>
                {(props.selectedOption.login === 'group') ? (
                    <React.Fragment>
                        <div align='center'><img src={ props.selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
                        <List>
                            <ListItem button onClick={() => { clickLink('/group/settings') }}
                                className={ (props.location.pathname.includes('/group/settings')) ? style.active : style.inactive }
                            >
                                <ListItemIcon>
                                <IconSettings />
                                </ListItemIcon>
                                <ListItemText primary='Group Settings' />
                            </ListItem>

                            <ListItem button onClick={() => { clickLink('/group/dashboard') }}
                                className={ (props.location.pathname.includes('/group/dashboard')) ? style.active : style.inactive }
                            >
                                <ListItemIcon>
                                <IconDashboard />
                                </ListItemIcon>
                                <ListItemText primary='Group Dashboard' />
                            </ListItem>

                            <ListItem style={{paddingTop:0, paddingBottom:0}}>
                                <Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
                                    onClick={() => { clickLink('/group/team') }}
                                >
                                    <ListItemIcon>
                                    <IconMyGroups />
                                    </ListItemIcon>
                                    <ListItemText primary='Team' />
                                </Button>
                                {openTeams 
                                    ? <IconButton onClick={() => { setOpenTeams(false)}}><ExpandLess /></IconButton> 
                                    : <IconButton onClick={() => { setOpenTeams(true)}}><ExpandMore/></IconButton>
                                }
                            </ListItem>

                                <Collapse in={openTeams} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => { clickLink('/group/crews') }} 
                                            style={{paddingLeft: 50}}
                                            className={ (props.location.pathname.includes('/group/crews')) ? style.active : style.inactive }
                                        >
                                            <ListItemIcon style={{width:30}}>
                                            <IconRecentActors />
                                            </ListItemIcon>
                                            <ListItemText primary='Crews' />
                                        </ListItem>
                                    </List>
                                </Collapse>

                            <ListItem style={{paddingTop:0, paddingBottom:0}}>
                                <Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
                                    onClick={() => { clickLink('/group/members') }}
                                >
                                    <ListItemIcon>
                                    <IconMyMemberships />
                                    </ListItemIcon>
                                    <ListItemText primary='Members' />
                                </Button>
                                {openMembers 
                                    ? <IconButton onClick={() => { setOpenMembers(false)}}><ExpandLess /></IconButton> 
                                    : <IconButton onClick={() => { setOpenMembers(true)}}><ExpandMore/></IconButton>
                                }
                            </ListItem>

                                <Collapse in={openMembers} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => { clickLink('/group/lists') }} 
                                            style={{paddingLeft: 50}}
                                            className={ (props.location.pathname.includes('/group/lists')) ? style.active : style.inactive }
                                        >
                                            <ListItemIcon style={{width:30}}>
                                            <IconListAlt />
                                            </ListItemIcon>
                                            <ListItemText primary='Lists & Filters' />
                                        </ListItem>
                                    </List>
                                </Collapse>

                            <ListItem button onClick={() => { clickLink('/group/support') }}
                                className={ (props.location.pathname.includes('/group/support')) ? style.active : style.inactive }
                            >
                                <ListItemIcon>
                                <IconLiveHelp />
                                </ListItemIcon>
                                <ListItemText primary='Support Desk' />
                            </ListItem>

                            <ListItem button onClick={() => { clickLink('/group/communicate') }}
                                className={ (props.location.pathname.includes('/group/communicate')) ? style.active : style.inactive }
                            >
                                <ListItemIcon>
                                <IconShare />
                                </ListItemIcon>
                                <ListItemText primary='Communicate' />
                            </ListItem>

                            <ListItem button onClick={() => { clickLink('/group/events') }}
                                className={ (props.location.pathname.includes('/group/events')) ? style.active : style.inactive }
                            >
                                <ListItemIcon>
                                <IconEvent />
                                </ListItemIcon>
                                <ListItemText primary='Events' />
                            </ListItem>
                        </List>
                    </React.Fragment>
                ) : (null)}

                {(props.selectedOption.login === 'member') ? (
                    <React.Fragment>
                        <div align='center'><img src={ props.selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
                        <List>                
                        <ListItem button onClick={() => { clickLink('/member/dashboard') }}
                            className={ (props.location.pathname.includes('/member/dashboard')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconDashboard />
                            </ListItemIcon>
                            <ListItemText primary='Dashboard' />
                        </ListItem>

                        <ListItem button onClick={() => { clickLink('/member/profile') }}
                            className={ (props.location.pathname.includes('/member/profile')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconPortrait />
                            </ListItemIcon>
                            <ListItemText primary='Profile Settings' />
                        </ListItem>

                        <ListItem button onClick={() => { clickLink('/member/memberships') }}
                            className={ (props.location.pathname.includes('/member/memberships')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconMyMemberships />
                            </ListItemIcon>
                            <ListItemText primary='Memberships' />
                        </ListItem>

                        <ListItem button onClick={() => { clickLink('/member/notifications') }}
                            className={ (props.location.pathname.includes('/member/notifications')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconMessage />
                            </ListItemIcon>
                            <ListItemText primary='Notifications' />
                        </ListItem>
                        {/*}
                        <ListItem button onClick={() => { clickLink('/member/groups') }}
                            className={ (props.location.pathname.includes('/member/groups')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconMyGroups />
                            </ListItemIcon>
                            <ListItemText primary='My Groups' />
                        </ListItem>
                        */}


                        <ListItem button onClick={() => { clickLink('/member/support') }}
                            className={ (props.location.pathname.includes('/member/support')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconLiveHelp />                                       
                            </ListItemIcon>                            
                            <ListItemText primary='Support Desk' />
                            <Badge style={{ marginRight: 10 }} badgeContent={2} color='primary'><div /></Badge>
                        </ListItem>

                        <ListItem button onClick={() => { clickLink('/member/loyaltt') }}
                            className={ (props.location.pathname.includes('/member/loyaltt')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconSlideshow />
                            </ListItemIcon>
                            <ListItemText primary='LoyalTT Explained' />
                        </ListItem>

                        <ListItem button onClick={() => { clickLink('/member/share') }}
                            className={ (props.location.pathname.includes('/member/share')) ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconShare />
                            </ListItemIcon>
                            <ListItemText primary='Share LoyalTT' />
                        </ListItem>
                        
                        <ListItem button onClick={() => { clickLink('/member/setup') }}
                            className={ (props.location.pathname === '/member/setup') ? style.active : style.inactive }
                        >
                            <ListItemIcon>
                            <IconWidgets />
                            </ListItemIcon>
                            <ListItemText primary='Account Setup' />
                        </ListItem>
                        </List>
                    </React.Fragment>
                ) : (null)}
            </div>
            </Drawer>
        </div>
    )
}

export default withRouter(PrivateSidenav)
