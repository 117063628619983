import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
//import Text from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      //height: 255,
      //maxWidth: 400,
      overflow: 'hidden',
      display: 'block',
      width: '100%',
      borderRadius: 10,
      border: '1px solid #ccc', 
      padding: 5
    },
  }))

function ProfilePhotos(props) {
    const classes = useStyles();
    //const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const photos = props.userInfo.photos
    const maxSteps = photos.length
  
    function handleNext() {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  
    function handleBack() {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleStepChange(step) {
        setActiveStep(step);
    }
    return (
        <Container maxWidth='lg' style={{ padding: 10, marginBottom: 100 }}>
        <Grid container justify='center' spacing={2}>


            <Grid item xs={12}>
                <Paper style={{ padding: 20, maxWidth: 400, margin:'0 auto' }} >
                    <SwipeableViews
                        axis='x'
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {photos.map((step, index) => (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.photoThumb} alt={index} />
                            ) : null}
                        </div>
                        ))}
                    </SwipeableViews>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next <KeyboardArrowRight />
                        </Button>
                        }
                        backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft /> Back
                        </Button>
                        }
                    />
                </Paper>
            </Grid>


        </Grid>
        </Container>
    )
}

export default ProfilePhotos
