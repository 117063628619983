import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import SubNav from '../../components/PrivateSubnav'
import Breadcrumb from '../../components/PrivateBreadcrumb'
import CommunicateInfo from './components/CommunicateInfo'
import Summary from './Summary'

const InfoComponent = <CommunicateInfo/>
const SubNavRoutes = {
  name: 'Communicate',
  alias: 'Connect with members',
  path: '/group/communicate'
}

class GroupCommunicateContainer extends React.Component {
    state = {
        breadcrumb_InfoIcon: true,  // true, false
        breadcrumb_LayoutStyle: 'list' // null, 'list', 'tile'    
    }

    breadcrumb_LayoutStyleToggle = (list) => {
      this.setState({ breadcrumb_LayoutStyle: list })
    }

    render() {
        return (
            <React.Fragment>
                <SubNav routes={SubNavRoutes}/>
                <Breadcrumb
                  routes={SubNavRoutes}
                  infoIcon={this.state.breadcrumb_InfoIcon}
                  layoutStyle={this.state.breadcrumb_LayoutStyle}
                  layoutStyleToggle={this.breadcrumb_LayoutStyleToggle}
                  infoComponent={InfoComponent}
                />
                <Switch>
                <Route path='/group/communicate' render={props => <Summary {...props} />} />
                </Switch>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupCommunicateContainer)
