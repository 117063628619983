import { GROUP_TEAM_LOAD, GROUP_TEAM_RESET_REDUCER } from '../types'

const initialState = {
    groupId: undefined,
    lastUpdated: 0,
    team: [],
    crews: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GROUP_TEAM_LOAD:
            return { 
                ...state, 
                groupId: action.payload.groupId,
                lastUpdated: action.payload.timestamp,
                team: action.payload.team
            }
        case GROUP_TEAM_RESET_REDUCER:
            return initialState
        default:
            return state
    }
}
