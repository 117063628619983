import React from 'react'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import Summary from './Summary'
import AccessDenied from '../../components/AccessDenied'

const whoHasAccess = [1]
class GroupSettingsContainer extends React.Component {
    render() {
        if(!whoHasAccess.includes(this.props.roleId)) {
            return (<AccessDenied page='settings' />)
        }

        return (
            <React.Fragment>
                <SubNav />
                <Summary />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
  roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSettingsContainer)
