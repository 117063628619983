import React from 'react'
import Paper from '@material-ui/core/Paper'

function SupportInfo(){
    return (
        <Paper elevation={4} style={{ height: 100, width: '90%', margin: '10px auto', padding: 10}}>
            <div style={{ color: '#ff0000'}}>This is the Support Info Component</div>
        </Paper>
    )
}
export default SupportInfo
