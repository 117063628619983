import React from 'react'
import Edit from './components/Edit'
import View from './components/View'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

class Profile extends React.Component {
    state = {
        value:0
    }
    
    setValue = (e,value) => {
        this.setState({value:value})
    }

    render(){
        const {value} = this.state
        const {userInfo, skip, update, setPassword} = this.props
        return (
            <Container maxWidth='lg' style={{ padding: 10, minHeight:700 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={8} md={6} >
                    <Paper square>
                        <Tabs value={value} indicatorColor='primary' textColor='primary' onChange={(e,value) => {this.setValue(e,value)}} style={{borderBottom: '2px solid #f1f1f1'}}>
                            <Tab label='VIEW' style={{width:'50%'}} />
                            <Tab label='EDIT' style={{width:'50%'}} />
                        </Tabs>
                        <div style={{padding:20, marginBottom:50}}>
                            {(value) ? (
                                <Edit user={userInfo} skip={skip} update={update} />
                            ) : (
                                <View user={userInfo} skip={skip} edit={this.setValue} setPassword={setPassword} />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            </Container>
        )
    }
}
export default Profile
