import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

function ManageGuestImport(props){
    return (
    	<Dialog 
        	open={props.open}
            onClose={() => {props.toggle(false)}}
        >
    		<DialogContent>
		        <Paper style={{marginTop:10, marginBottom:50}}>
        			import
			    </Paper>
			</DialogContent>
            <DialogActions><Button onClick={() => {props.toggle(false)}}>close</Button></DialogActions>
        </Dialog>
    )
}
export default ManageGuestImport
