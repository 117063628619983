export const selectorFilterMembers = (members, status) => {
    let filteredMembers = []
    if(members.length){
        switch(status) {
            case 'LoyalTT':
                filteredMembers = members.filter((member) => { return member.memberStatus === 'registered' })
                break
            case 'Emails':
                filteredMembers = members.filter((member) => { return member.memberStatus === 'unregistered' })
                break
            default:
                filteredMembers = []
        }
    }
    return filteredMembers
}

export const selectorFilterMemberStatus = (members) => {
    let status = []
    if(members.length){
        let loyaltt = members.filter((member) => { return member.memberStatus === 'registered' })
        status = [...new Set(loyaltt.map(member => member.status.charAt(0).toUpperCase() + member.status.slice(1)))]
        status.unshift('All')
    }
    return status
}
