//import { ALERT_SAMPLE_ACTION } from '../types'

const initialState = {
  updateIntervals: { // update intervals in seconds
  	memberships: 60,
    members: 60,
    notifications: 60
  },
}


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'ALERT_SAMPLE_ACTION':
	  return { 
	    ...state, 
        lastUpdated: action.payload.timestamp,
        id: action.payload.id
	  }
	default:
	  return state
  }
}
