import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { createGroup } from '../../../redux/member/actions'
import { selectorLoadMemberships } from '../../../redux/member/selectors'

import SubNav from './components/Subnav'
import New from './New'
import List from './List'

class MemberMembershipsContainer extends React.Component {
  state = {
    page: 1,
    count: 10,
    sort: 'All',
    groupId: undefined,
    memberships: []
  }

  componentDidMount = () => {
    this.setSortFilter()
  }
  
  componentDidUpdate = (prevProps) => {
    if (this.props.match.params !== prevProps.match.params) {
      this.setSortFilter()
    }
  }
  
  setSortFilter = () => {
    let page = (this.props.match.params.page) ? this.props.match.params.page : 1
    let count = (this.props.match.params.count) ? this.props.match.params.count : 10
    let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'All'
    let groupId = (this.props.match.params.groupId) ? this.props.match.params.groupId : undefined

    let memberships = []
    switch(sort) {
      case 'Inactive':
        memberships = this.props.inactiveMemberships
        break
      case 'Roles':
        memberships = this.props.rolesMemberships
        break
      default:
        memberships = this.props.activeMemberships
    }
      this.setState({ page: page, count: count, sort: sort, groupId: groupId, memberships: memberships })
    }
        
  render() {
    let showOptions = (this.props.location.pathname === '/member/memberships/create-group') ? 0 : 1
    const {page, count, sort, groupId, memberships} = this.state
    const {createGroup} = this.props
    return (
      <React.Fragment>
        <SubNav page={page} count={count} sort={sort} showOptions={showOptions} />
        <Switch>
          <Route path='/member/memberships/create-group' render={props => <New {...props} 
              createGroup={createGroup}        
            />} 
          />
          <Route path='/member/memberships' render={props => <List {...props}
              page={page}
              count={count}
              sort={sort}
              groupId={groupId}
              memberships={memberships} 
            />} 
          />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  activeMemberships: selectorLoadMemberships(state.member.memberships, 'Active'),
  inactiveMemberships: selectorLoadMemberships(state.member.memberships, 'Inactive'),
  rolesMemberships: selectorLoadMemberships(state.member.memberships, 'Roles')
})
const mapDispatchToProps = {
  createGroup
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberMembershipsContainer)
