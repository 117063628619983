import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Info from './components/CreateEventInfo'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Text from '@material-ui/core/Typography'

class New extends React.Component {
    state = { 
        event: {
            name: '',
            tagline: '',
            summary: '',
            dateTimeStart: moment(),
            dateTimeEnd: moment(),
            type: 'Public',
            listedOnSecureTix: true,
            headerImage: null,
            profileImage: null
        }, 
        headerImages: [ // ImageSelector variable,
            '/images/default-eventHeader.jpg',
            '/images/events/eventHeader-1.jpg',
            '/images/events/eventHeader-2.jpg',
            '/images/events/eventHeader-3.jpg',
            '/images/events/eventHeader-4.jpg'
        ],
        profileImages: [ // ImageSelector variable,
            '/images/default-eventProfile.jpg',
            '/images/events/eventProfile-1.jpg',
            '/images/events/eventProfile-2.jpg',
            '/images/events/eventProfile-3.jpg',
            '/images/events/eventProfile-4.jpg'
        ],
        activeStep: 0,
        showInfo: false
    }
    
    updateEventInfo = (name, tagline, summary, dateTimeStart, dateTimeEnd, type, listedOnSecureTix) => {
        let event = this.state.event
        event.name = name
        event.tagline = tagline
        event.summary = summary
        event.dateTimeStart = dateTimeStart
        event.dateTimeEnd = dateTimeEnd
        event.type = type
        event.listedOnSecureTix = listedOnSecureTix
        this.setState({ event:event })
        this.handleNext()
    }

    updateEventLocation = () => {
        console.log('updateEventLocation')
        this.handleNext()
    }

    updateImage = (img, src) => {
        let event = this.state.event
        event[img] = src
        
        let images = []
        if (img === 'headerImage') {
            images = this.state.headerImages
        } else if (img === 'profileImage') {
            images = this.state.profileImages
        }
        if (!images.includes(src)) {
            images.push(src)
        }
        let name = img + 's'
        
        this.setState({ event:event, [name]: images })
        this.handleNext()
    }

    handleCreateEvent = (cb) => {
        this.props.createEvent(this.state.event)
        .then((eventId) => {
            this.props.history.push({ pathname: '/group/events/1/10/Pending/' + eventId })
        })
        .catch((error) => {
            cb({type:'error', message:error})
        })
    }

    handleShowInfoToggle = () => {
        this.setState({ showInfo: !this.state.showInfo })
    }   
    handleNext = () => { 
        this.setState({activeStep: this.state.activeStep + 1 })
    }
    
    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1 })
    }

    render() {
        const { event, activeStep, showInfo, headerImages, profileImages } = this.state
        return (
            <React.Fragment>
                <Grid container justify='center' style={{ paddingLeft: 8, paddingRight: 8 }}>
                    <Grid item xs={12} sm={7} l={6}>
                    <Paper elevation={4} style={{ width: '100%', maxWidth: 600, margin: '10px auto', padding: 10 }}>
                        <div style={{ display: 'flex' }}>
                            <Text variant='h5'>Create an Event</Text>
                            <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                            <IconButton onClick= {() => {this.handleShowInfoToggle()} } >
                                <IconInfo />
                            </IconButton>
                            </div>
                        </div>
                        <Stepper activeStep={activeStep} orientation='vertical' style={{ margin: -10, paddingLeft: 10 }}>
                        {/* [START]: STEP 1 - Details ::::::::::::::::::::: */}    
                            <Step key={1}>
                            <StepLabel>Event Details</StepLabel>
                            <StepContent>
                                <Step1 
                                    event={event}
                                    updateInfo={this.updateEventInfo}
                                /> 
                            </StepContent>
                            </Step>
                        {/* [END]: STEP 1 - Details ::::::::::::::::::::::: */}

                        {/* [START]: STEP 2 - Header Image ::::::::::::::::::::: */}    
                            <Step key={2}>
                            <StepLabel>Event Location</StepLabel>
                            <StepContent>
                                <Step2 
                                updateEventLocation={this.updateEventLocation}
                                handleBack={this.handleBack}
                                />
                            </StepContent>
                            </Step>
                        {/* [END]: STEP 2 - Header Image ::::::::::::::::::::::: */}

                        {/* [START]: STEP 3 - Header Image ::::::::::::::::::::: */}    
                            <Step key={3}>
                            <StepLabel>Header Image</StepLabel>
                            <StepContent>
                                <Step3 
                                currentImage={event.headerImage}
                                headerImages ={headerImages} 
                                updateImage={this.updateImage}
                                handleBack={this.handleBack}
                                />
                            </StepContent>
                            </Step>
                        {/* [END]: STEP 3 - Header Image ::::::::::::::::::::::: */}

                        {/* [START]: STEP 4 - Profile Image ::::::::::::::::::::: */}    
                        <Step key={4}>
                            <StepLabel>Profile Image</StepLabel>
                            <StepContent>
                                <Step4 
                                currentImage={event.profileImage}
                                profileImages ={profileImages} 
                                updateImage={this.updateImage}
                                handleBack={this.handleBack}
                                />
                            </StepContent>
                            </Step>
                        {/* [END]: STEP 4 - Profile Image ::::::::::::::::::::::: */}

                        {/* [START]: STEP 5 - Review Info ::::::::::::::::::::: */}    
                            <Step key={5}>
                            <StepLabel>Review</StepLabel>
                            <StepContent>
                                <Step5 
                                event={event}
                                handleBack={ this.handleBack }
                                handleCreateEvent={ this.handleCreateEvent }
                                />
                            </StepContent>
                            </Step>
                        {/* [END]: STEP 5 - Review Info ::::::::::::::::::::::: */}

                        </Stepper>
                    </Paper>
                    <div elevation={4} style={{ width: '100%', maxWidth: 600, margin: '1px auto', textAlign: 'right' }}>
                        <Button variant='outlined' size='small' color='primary' component={Link} to='/group/events'>
                        view events
                        </Button>
                    </div>
                    <div style={{ height: 150}}/>
                    </Grid>
                </Grid>

                <Dialog open={showInfo} onClose= {() => {this.handleShowInfoToggle()} }>
                    <DialogContent>
                        <Info />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick= {() => {this.handleShowInfoToggle()} } color='primary' autoFocus>
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}
export default New
