import React from 'react'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function List() {
    return (
        <Container maxWidth='lg' style={{ padding: 10, minHeight:700  }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={10} sm={8} md={6} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='caption' gutterBottom>
                        <ul>
                            <li><Link to='/templates/team-member-email'>Team Member Email</Link></li>
                        </ul>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}
export default List
