import moment from 'moment-timezone'
import { 
    GROUP_EVENTS_RESET_REDUCER, 
    GROUP_EVENTS_LOAD, 
    GROUP_EVENTS_TICKET_TYPES_LOAD,
    GROUP_EVENTS_ATTENDANCE_LOAD, 
    GROUP_EVENTS_EVENT_RESET 
} from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Events Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorEvents
export function startMonitoringEvents() {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        console.log('start monitoring events')

        // delay this snapshot if userInfo not loaded as yet
        if(getstate().auth.userInfo == null){
            await customDelay(1500)
        }

        // set needed variables
        let sectionGroupId = getstate().groupEvents.groupId
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        // reset section reducer if groupId doesnt match sectionGroupId
        if (sectionGroupId && sectionGroupId !== gId) {
            dispatch({type: GROUP_EVENTS_RESET_REDUCER})
        }

        let eventsRef = await firebase.firestore().collection('events').where('groupId', '==', gId)
        monitorEvents = eventsRef.onSnapshot((querySnapshot) => {
            let events = {}
            querySnapshot.forEach((doc) => {
                let event = doc.data()
                events[doc.id] = event
            })
            let payload = {
                groupId: gId,
                events: events,
                timestamp: Math.floor(Date.now() / 1000)
            }
            dispatch({type: GROUP_EVENTS_LOAD, payload: payload})
            resolve()
        }, error => {
            reject(error.message)
        })
    })
}        
export function stopMonitoringEvents(){
    return () => {
        console.log('stop monitoring events')
        monitorEvents && monitorEvents()
    }
}
// [END]: Events Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Event Details Listener *************************************************************
let monitorTicketTypes
let monitorAttendance
export function startMonitoringEventDetails(eventId) {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        console.log('start monitoring event details - ', eventId)
        
        // [START]: monitorTicketTypes ----->
        let ticketTypesRef = await firebase.firestore().collection('eventsTicketTypes').where('eventId', '==', eventId)     
        monitorTicketTypes = ticketTypesRef.onSnapshot((querySnapshot) => {
            let ticketTypes = []
            querySnapshot.forEach((doc) => {
                ticketTypes.push(doc.data())
            })
            dispatch({type: GROUP_EVENTS_TICKET_TYPES_LOAD, payload: ticketTypes})
            resolve()
        }, error => {
            reject(error.message)
        })
        // [END]: monitorTicketTypes ------->

        // [START]: monitorAttendance ----->
        let attendanceRef = await firebase.firestore().collection('eventsAttendance').where('eventId', '==', eventId)     
        monitorAttendance = attendanceRef.onSnapshot((querySnapshot) => {
            let attendance = []
            querySnapshot.forEach((doc) => {
                attendance.push(doc.data())
            })
            dispatch({type: GROUP_EVENTS_ATTENDANCE_LOAD, payload: attendance})
            resolve()
        }, error => {
            reject(error.message)
        })
        // [END]: monitorAttendance ------->
    })
}        
export function stopMonitoringEventDetails(){
    return (dispatch) => {
        console.log('stop monitoring eventdetails')
        dispatch({type: GROUP_EVENTS_EVENT_RESET})
        monitorTicketTypes && monitorTicketTypes()
        monitorAttendance && monitorAttendance()
    }
}
// [END]: Event Details Listener ***************************************************************

export function createEvent(eData) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let timezone = moment.tz.guess()
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        let sDate = new Date(eData.dateTimeStart).getTime()
        let eDate = new Date(eData.dateTimeEnd).getTime()
        
        const createEvent = firebase.functions().httpsCallable('eventAddEdit')
        createEvent({ 
            gId: gId,
            name: eData.name, 
            tagline: eData.tagline,
            summary: eData.summary, 
            type: eData.type,
            dateTimeStart: sDate,
            dateTimeEnd: eDate,             
            headerImage: eData.headerImage, 
            profileImage: eData.profileImage,
            listedOnSecureTix: eData.listedOnSecureTix,
            timezone: timezone
        })
        .then((returned) => {
            // eventId is returned
            resolve(returned.data)
        })
        .catch((err) => {
            reject(err.message)
        })    
    })
}


// [START]: Ticket Types Functions ******************************************************************************
// **************************************************************************************************************
export function ticketTypeAddEdit(tData) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        let sDate = new Date(tData.dateTimeStart).getTime()
        let eDate = new Date(tData.dateTimeEnd).getTime()
        const ticket = firebase.functions().httpsCallable('ticketTypeAddEdit')
        ticket({ 
            gId: gId,
            eventId: tData.eventId,
            ticketId: tData.ticketId,
            position: tData.position,
            name: tData.name,
            description: tData.description,  
            amountAvailable: tData.amountAvailable,
            price: tData.price,
            serviceFee: tData.serviceFee,
            dateTimeStart: sDate,
            dateTimeEnd: eDate,    
            ticketLimit: tData.ticketLimit,    
            availability: tData.availability
        })
        .then((returned) => {
            // ticketId is returned
            resolve(returned.data)
        })
        .catch((err) => {
            reject(err.message)
        })    
    })
}
    
export function ticketTypeCopyDelete(action, ticketId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id
       
        const ticket = firebase.functions().httpsCallable('ticketTypeCopyDelete')
        ticket({ 
            gId: gId,
            action: action,
            ticket: ticketId
        })
        .then((returned) => {
            // ticketId is returned
            resolve(returned.data)
        })
        .catch((err) => {
            reject(err.message)
        })    
    })
}    
// **************************************************************************************************************
// [END]: Ticket Types Functions ********************************************************************************


// [START]: GuestList Functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function guestlistAddEdit(eventId, guest) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        const guestlistAddEdit = firebase.functions().httpsCallable('guestlistAddEdit')
        guestlistAddEdit({ 
            gId: gId,
            eventId: eventId,
            guestId: guest.guestId,
            guests: guest.guests,
            guestOfId: guest.guestOfId,
            guestOfName: guest.guestOfName,
            firstName: guest.firstName,
            lastName: guest.lastName,
            gender: guest.gender,
            highlight: guest.highlight,
            note: guest.note,        
            email: guest.email,
            cell: guest.cell,
            company: guest.company,
            title: guest.title,   
            notify: guest.notify 
        })
        .then((returned) => {
            // guestId is returned
            resolve(returned.data)
        })
        .catch((err) => {
            reject(err.message)
        })    
    })
}
export function guestlistDelete(eventId, guestId, guestOfId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        const guestlistDelete = firebase.functions().httpsCallable('guestlistDelete')
        guestlistDelete({ 
            gId: gId,
            eventId: eventId,
            guestId: guestId,
            guestOfId: guestOfId
        })
        .then(() => {
            resolve()
        })
        .catch((err) => {
            reject(err.message)
        })

    })
}
export function guestlistImport(eventId, guest) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        console.log('coming soon!')
    })
}            
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: GuestList Functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::



// addEditAttendee
// deleteAttendee
// import attendees


// checkIn attendee
// checkOut attendee
// reset attendee
