import React from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconMenu from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
    list: {
        width: 300
    },
    active: {
        backgroundColor: '#E8E8E8'
    },
    inactive: {
        backgroundColor: '#ffffff'
    }
}))

const PublicSidenav = (props) => {
    const style = useStyles()

    const clickLink = (link) => {
        props.history.push({ pathname: link })
        props.sideNavToggle()
    }

    return (
        <Drawer open={props.sideNavOpen} onClose={props.sideNavToggle} onOpen={ props.sideNavToggle } >
          <div className={style.list}>
            <List>
              <ListItem button onClick={() => { clickLink('/') }}
                className={ (props.location.pathname === '/') ? style.active : style.inactive }
              >
                <ListItemIcon>
                  <IconMenu />
                </ListItemIcon>
                <ListItemText primary='Home' />
              </ListItem>

              <ListItem button onClick={() => { clickLink('/about') }}
                className={ (props.location.pathname === '/about') ? style.active : style.inactive }
              >
                <ListItemIcon>
                  <IconMenu />
                </ListItemIcon>
                <ListItemText primary='About Us' />
              </ListItem>

              <ListItem button onClick={() => { clickLink('/contact') }}
                className={ (props.location.pathname === '/contact') ? style.active : style.inactive }
              >
                <ListItemIcon>
                  <IconMenu />
                </ListItemIcon>
                <ListItemText primary='Contact Us' />
              </ListItem>

              <ListItem button onClick={() => { clickLink('/howitworks') }}
                className={ (props.location.pathname === '/howitworks') ? style.active : style.inactive }
              >
                <ListItemIcon>
                  <IconMenu />
                </ListItemIcon>
                <ListItemText primary='How it Works' />
              </ListItem>

              <ListItem button onClick={() => { clickLink('/gettheapp') }}
                className={ (props.location.pathname === '/gettheapp') ? style.active : style.inactive }
              >
                <ListItemIcon>
                  <IconMenu />
                </ListItemIcon>
                <ListItemText primary='Get The App' />
              </ListItem>
            </List>
          </div>
        </Drawer>
    )
}

export default withRouter(PublicSidenav)
