import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

import TeamMemberEmail from './TeamMemberEmail'
import List from './List'

class Templates extends React.Component {
    render() {
        return (
        	<React.Fragment>
                <Container maxWidth='lg' style={{ padding: 10 }}>
                <Grid container justify='center' spacing={2}>
                    <Grid item xs={12} sm={12} md={10} >
                        <AppBar position='static' color='default'>
                            <Toolbar variant="dense">
                                <Button component={Link} to='/templates'>
                                    <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                        <b>Email Templates</b>
                                    </Text>
                                </Button>
                            </Toolbar>
                        </AppBar> 
                    </Grid>
                </Grid>
                </Container>
            	<Switch>
           			<Route path='/templates/team-member-email' component={TeamMemberEmail} />
           			<Route path='/templates' component={List} />
            	</Switch>
            </React.Fragment>
        )
    }
}
export default Templates
