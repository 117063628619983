import React from 'react'
import {  withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberNotificationList(props) {
    const {page, count, sort, filter, notifications} = props
    
    const openNote = (id, open) => {
        props.openNotificationToggle(id, open)
        let url = '/member/notifications/' + page + '/' + count + '/' + sort + '/' + filter + '/' + id
        props.history.push({ pathname: url })
    }
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        Notifications List
                    </Text>
                    <Text variant='caption' gutterBottom>
                        List of notifications that match the current sort and filter<br/>
                        notifications open in an expansion panel<br/>
                        this link show what a direct link to a notification would look like
                        <p></p>
                        {(notifications.length) ? (
                            notifications.map((note, i) => {
                                return (
                                    <div key={i} style={{marginTop: 10}}>
                                        <Button onClick={() => {openNote(note.docId, true)}} >open notification: {note.title}</Button>
                                    </div>
                                )
                            })
                        ) : (
                            <div style={{marginTop: 50}}>
                                <Text variant='h5'>no notifications found</Text>
                            </div>
                        )}
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default withRouter(MemberNotificationList)
