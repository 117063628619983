import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSupportSummary() {
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        GROUP SUPPORT
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <b>ABOUT</b><br/>
                        <p>This is a Support Ticket Service like zend desk.</p>
                        
                        <p><b>TABS</b></p>
                        - Help Desk<br/>


                        <p><b>ACCESS</b></p>
                        Accessable by Level 1 (Owner/Admin), Level 2 (General Manager), Level 3 (Content Manager).
                        Some features would be accessed by Special Access Levels (levels higher than 3, eg. Level 5 - CSRs).<br/>

                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default GroupSupportSummary
