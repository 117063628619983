export const selectorLoadNotifications = (notifications, status) => {
    let filteredNotifications = []
    switch(status) {
        case 'New':
            filteredNotifications = notifications.filter((note) => { return !note.viewed })
            break
        case 'Viewed':
            filteredNotifications = notifications.filter((note) => { return note.viewed })
            break
        case 'Saved':
            filteredNotifications = notifications.filter((note) => { return note.saved })
            break
        default:
            filteredNotifications = []
    }
    return filteredNotifications
}

export const selectorFilterCategories = (notifications) => {
    let list = []
    notifications.map(notification => {
        return list.push(notification.category)
    })
    list = [...new Set(list)]
    return list
}

// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/