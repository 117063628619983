import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberLoyalttSummary(props) {
    const filter = props.filter.replace(/_/g, ' ')

    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        LOYALTT EXPLAINED - {filter}
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <ul>
                            <li><b>Blog</b> - list of blog articles </li>
                            <li><b>How To</b> - slides explaining how to use LoyalTT features</li>
                            <li><b>Tutorials</b> - videos showing how to use LoyalTT in real world situations</li>
                            <li><b>FAQs</b> - list of fequently asked questions </li>
                            <li><b>One on One</b> - Schedule a free one on one web meeting with a LoyalTT implemation expert</li>
                            <li>(Note: search bar below subnav, search will display results from all above categories)</li>
                        </ul>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}
export default MemberLoyalttSummary
