import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import swal from 'sweetalert2'
import { chkEmail }  from '../../../../includes/functions'

//import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'


function AddMembersMultiple(props) {
//    const [firstName, setFirstName] = useState('')
//    const [lastName, setLastName] = useState('')
    const [emailString, setEmailString] = useState('')


    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [submitButtonText, setSubmitButtonText] = useState('Add Members')
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (set, value) => {
        set(value)
        setMessage('')
    }

    const handleAddMembers = () => {
        // replace newline and spaces with comma, split by semicolon then by comma
        let emails = emailString.replace(/\n| /g, ',').split(';').join(',').split(',')
        // filter out invalid emails
        let emailsArray = emails.filter(function(email) { return chkEmail(email) })

        if(!emailsArray.length) {
            setMessage('Please enter valid email addresses seperated by either a comma, a semicolon or a new line.')
            return false
        }

        setMessage('')
        setSubmitButtonText('updating...')
        setSubmitButtonDisabled(true)
        setLoading(true)

        // turn emails array into a comma seperated string
        let list = [...new Set(emailsArray)].join()
        props.addMembers('multiple', 'listName', list)
            .then((newUser) => {
                // redirect to pending roles
                if (newUser) {
                    props.history.push({ pathname: '/group/members/1/10/Emails/Pending' })
                } else {
                    props.history.push({ pathname: '/group/members/1/10/LoyalTT/Pending' })
                }
            })
            .catch((error) => {
                setLoading(false)
                setSubmitButtonDisabled(false)
                setSubmitButtonText('Add Members')
                swal.fire({
                    position: 'top-end',
                    type: 'error',
                    title: 'sorry there was an error: ' + error,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
    }

    return (
        <div style={{ margin: '0 auto', width: '100%'}}>
            <Text variant='h5' gutterBottom>
                ADD MEMBERS
            </Text>
            <Grid container justify='center'>
                <Grid item xs={12} style={{ padding: 5, textAlign: 'center' }}>
                    <TextField
                        style={{ padding: 5, border: '#1c1c1c', maxWidth: 400}}
                        fullWidth
                        id="standard-multiline-static"
                        label="Enter email addresses"
                        multiline
                        rows="6"
                        //defaultValue="Default Value"
                        //className={classes.textField}
                        margin="normal"
                        helperText="enter each email on a new line or seperate with a commer  - ,"
                        value={emailString}
                        onChange={(e) => {setEmailString(e.target.value)}}
                    />
                </Grid>
            </Grid>
            <div align='center' style={{ height: 25 }}>
                <Text variant='caption' style={{ color: 'red' }}>
                    {message}
                </Text>
            </div>
            <div align='right'>
                <Button variant='contained' color='primary' disabled={submitButtonDisabled} onClick={() => {handleAddMembers()}}>
                    {submitButtonText}
                </Button>
            </div>
        </div>
    )
}
export default withRouter(AddMembersMultiple)
