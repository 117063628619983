import React from 'react'
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconFilter from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class PrivateSubnav extends React.Component {
    state = { anchorEl: null }

    handleFilterChange = (value) => {
        this.setState({ anchorEl: null })
        let path = '/member/loyaltt/' + this.props.page + '/' + this.props.count + '/' + this.props.sort + '/' + value.replace(/ /g, '_')
        this.props.history.push({ pathname: path })
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    
    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const {anchorEl} = this.state
        const filter = this.props.filter.replace(/_/g, ' ')
        const options = ['Blog', 'How To', 'Tutorials', 'One on One']
        return (
            <Container maxWidth='lg' style={{ padding: 10 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} >
                    <AppBar position='static' color='default'>
                        <Toolbar variant="dense">
                            <Button onClick={() => {this.props.history.push({ pathname: '/member/loyaltt' }) }} >
                                <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                    <b>LOYALTT</b>
                                </Text>
                            </Button>
                            <div style={{flexGrow: 1}} />
                            <Button size="small" onClick={this.handleClick}>
                                <IconFilter/> {filter}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                <MenuItem selected={filter === 'FAQs'} onClick={() => {this.handleFilterChange('FAQs')}}>FAQs</MenuItem>
                                {options.map((option) => (
                                    <MenuItem 
                                        key={option}
                                        selected={option === filter}
                                        onClick={() => {this.handleFilterChange(option)}}
                                    >{option}</MenuItem>
                                ))}
                            </Menu>
                        </Toolbar>
                    </AppBar> 
                </Grid>
            </Grid>
            </Container>
        )
    }
}
export default withRouter(PrivateSubnav)
