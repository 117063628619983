import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import IconExitApp from '@material-ui/icons/ExitToApp'
import IconMoreVert from '@material-ui/icons/MoreVert'
import IconSettings from '@material-ui/icons/Settings'
import IconMenu from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        marginLeft: -20,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    header: {
        paddingLeft: 5,
        color: '#999'
    },
    loggedin: {
        marginRight: 12,
        lineHeight: 1.20
    }
}))

const PrivateTopnav = (props) => {
    const style = useStyles()
    const [menuEl, setMenuEl] = useState(null)

    const handleClickLink = (link) => {
        setMenuEl(null)
        props.history.push({ pathname: link })
    }

    const handleMenuItemClick = (option) => {
        setMenuEl(null)
        props.handleOptionChange(option)
    }
    
    return (
        <div>
            <AppBar position='static'>
                <Toolbar>
                    <IconButton className={ style.menuButton } color='inherit' onClick={ props.sideNavToggle }>
                        <IconMenu />
                    </IconButton>
                    <div className={ style.title } />
                        
                    <List component='nav' color='inherit'>
                    <ListItem
                        button
                        aria-haspopup='true'
                        aria-controls='lock-menu'
                        aria-label='Logged in as'
                        onClick={(event) => { setMenuEl(event.currentTarget) }}
                    >
                        <Text variant='subtitle1' component='div' color='inherit' className={ style.loggedin }>
                        <small><i>Logged in as:</i></small><br/>
                        {props.selectedOption.name} <small><i>{props.selectedOption.role ? '(' + props.selectedOption.role + ')' : ''} </i></small><br/>
                        </Text>

                        <Avatar style={{ border: '1.5px solid #ddd', margin: 0, color: '#fff' }}
                        alt={ props.selectedOption.name }
                        src={ props.selectedOption.thumb }
                        >
                        { props.selectedOption.initials }
                        </Avatar>         
                        <IconMoreVert />
                    </ListItem>
                    </List>
            
                    <Menu
                    dense='true'
                    id='lock-menu'
                    anchorEl={menuEl}
                    open={Boolean(menuEl)}
                    onClose={() => { setMenuEl(null) }}
                    
                    >
                        <Text variant='body2' component='p' className={ style.header }>
                            Log in as:
                        </Text>
                        <Divider />
                        {props.options && props.options.map((option, index) => (
                            <MenuItem
                            key={index}
                            selected={option.name === props.selectedOption.name && option.role === props.selectedOption.role }
                            onClick={(event) => {handleMenuItemClick(index)}}
                            >
                            <Avatar style={{ margin: 0, color: '#fff' }}
                                alt={ option.name }
                                src={ option.thumb }
                            >
                                { option.initials }
                            </Avatar>
                            <ListItemText style={{ marginLeft: 15}} primary={option.name} secondary={option.role ? 'Role: ' + option.role.replace(/_/g, ' ') : ''} />
                            </MenuItem>
                        ))}                          
                                              
                        <Divider />
                        { (props.selectedOption.login === 'member') &&
                            <MenuItem button onClick={() => {handleClickLink('/member/settings')}} >
                            <ListItemAvatar>
                                <Avatar>
                                <IconSettings />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary='App Settings' />
                            </MenuItem>
                        }
                        <Divider />
                        <MenuItem button onClick={() => {props.logOut()}} >
                            <ListItemAvatar>
                            <Avatar>
                                <IconExitApp />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary='Logout' />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </div>
    )
}
export default withRouter(PrivateTopnav)
