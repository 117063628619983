export const selectorLoadMemberships = (memberships, status) => {
    let filteredMemberships = []
    switch(status) {
        case 'Active':
            filteredMemberships = memberships.filter((membership) => { return membership.status === 'approved' })
            break
        case 'Inactive':
            filteredMemberships = memberships.filter((membership) => { return membership.status !== 'approved' })
            break
        case 'Roles':
            filteredMemberships = memberships.filter((membership) => { return membership.roles.length > 0 })
            break
        default:
            filteredMemberships = []
    }
    return filteredMemberships
}
