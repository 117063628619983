import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberShareSummary() {
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        SHARE THE APP
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <ul>
                            <li>links to download app through LoyalTT.com website</li>
                            <li>directions to download the app from Android and iPhone app store</li>
                            <li>social links to share the app with friends</li>
                            <li>links to review the app</li>
                            <li>plugins and widgets for websites and social sites</li>
                        </ul>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default MemberShareSummary
