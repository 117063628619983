import React from 'react'
import AvatarEditor from 'react-avatar-editor'

import Fab from '@material-ui/core/Fab'
import RotateIcon from '@material-ui/icons/RotateRight'
import Slider from '@material-ui/lab/Slider'
import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'

class ImageEditor extends React.Component {
    myInputRef = React.createRef()
    editedImageRef = React.createRef()
    state = {
        defaultImage: this.props.defaultImage,
        image: this.props.defaultImage,
        ratio: this.props.ratio,
        borderRadius: this.props.borderRadius,
        canvasWidth: 200,
        canvasHeight: 200,
        width: 200,
        height: 200,
        scale: 1.2,
        rotate: 0,
        scaleSlider: 20,
        editorBorder: 25
    }

    componentDidMount = () => {
        let ratio = this.state.ratio
        let width = this.state.width
        let height = this.state.height
        switch (ratio) {
        case '4:1':
            width = this.myInputRef.current.offsetWidth - 100
            height = width / 4
            if (height > 200) {
                width = 400
                height = 100
            }
            break
        case '2:1':
            width = this.myInputRef.current.offsetWidth - 100
            height = width / 2
            if (height > 200) {
                width = 400
                height = 200
            }
            break
        case '1:1.5':
            width = this.myInputRef.current.offsetWidth - 100
            height = width * 1.5
            if (height > 201) {
                width = 134
                height = 201
            }
            break
        default:
            // 1:1
            width = this.myInputRef.current.offsetWidth - 100
            height = width
            if (height > 200) {
                width = 200
                height = 200
            }
        }
        this.setState({ width: width, height: height, canvasWidth: width, canvasHeight: height })
    }

    handleUploadPic = (e) => {
        if(e.target.files[0]) {
            this.setState({ image: e.target.files[0], rotate: 0, canvasWidth: this.state.width, canvasHeight: this.state.height })
        }      
    }

    handleRotateChange = () => {    
        let currentDirection = this.state.rotate
        let canvasWidth = this.state.canvasWidth
        let canvasHeight = this.state.canvasHeight
        let newDirection = (currentDirection === 270) ? 0 : currentDirection + 90
        this.setState({ rotate: newDirection, canvasWidth: canvasHeight, canvasHeight: canvasWidth  })
    }

    handleScaleChange = (event, value) => {
        let newScale = (value + 100) / 100
        this.setState({ scaleSlider: value, scale: newScale })
    }

    handlePreviewStep = () => {
        const img = this.editedImageRef.current.getImage().toDataURL('image/jpeg',0.8)
        this.props.handlePreviewStep(img, this.state.width, this.state.height)
    }

    render(){
        return (
            <div ref={this.myInputRef} align='center' style={{ maxWidth: 500, margin: '20px auto' }}>
                <div style={{ height: 15 }}/>
                <AvatarEditor
                    ref={this.editedImageRef}
                    image={this.state.image}
                    width={this.state.canvasWidth}
                    height={this.state.canvasHeight}
                    rotate={this.state.rotate}
                    scale={this.state.scale}
                    borderRadius={this.state.borderRadius}
                    border={this.state.editorBorder}
                    color={[255, 255, 255, 0.6]} // RGBA
                /><br/>

                <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id='file-upload'
                    type='file'
                    onChange={ this.handleUploadPic }
                />
                <label htmlFor='file-upload'>
                    <Button variant='contained' component='span' color='primary' size='small' style={{ margin: 10 }}>
                          Choose Photo
                    </Button>
                </label>

                <Fab size='small' color='primary' style={{ margin: 10 }} onClick={ this.handleRotateChange }>
                    <RotateIcon />
                </Fab>

                <div style={{ textAlign: 'left', width: 250 }}>
                    <Text id='label'>Scale</Text>
                    <Slider style={{ touchAction: 'manipulation' }} value={this.state.scaleSlider} onChange={this.handleScaleChange} />
                </div>

                <Button disabled={(this.state.image === this.state.defaultImage) ? true : false} variant='contained' color='secondary' size='small' style={{ width: 250, marginTop: 20 }}
                  onClick={this.handlePreviewStep}
                >
                    Preview Image
                </Button><br/>
                <Text variant='caption' gutterBottom align='center' style={{ padding: 10 }}>
                    <i>The resulting image will have the same resolution as the original image. For
                    best results, upload high resolution images.</i>
                </Text>
            </div>
        )
    }

}
export default ImageEditor