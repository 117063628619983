import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/RateReview'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    thumb: {
        width: 75,
        height: 75, 
        verticalAlign: 'top', 
        marginRight: 10,
        border: '1px solid #ccc', 
        borderRadius: 7
    }
}))

const Registered = ({status}) => {
  let word = (status === 'registered') ? 'REGISTERED' : 'NOT REGISTERED'
  let color = (status === 'registered') ? '#4caf50' : '#fbc02d'
  return (
    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
      <span style={{backgroundColor:color, padding:'1px 5px', borderRadius:5, color:'white', fontSize:10, fontWeight:'bold'}}>{word}</span>
    </Text>
  )
}

const Status = ({status}) => {
  let word = status.toUpperCase()
  let color = '#fbc02d'
  if (status === 'approved') {
      color = '#4caf50'
  }
  return (
    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
      <b>Group Status: </b>
      <span style={{color:color, fontSize:10, fontWeight:'bold'}}>{word}</span>
    </Text>
  )
}

const Roles = ({roles}) => {
  let rolesArray = []
  for (let x = 0; x < roles.length; x++) {
    rolesArray.push(roles[x]['role'].replace(/_/g, ' '))
  }
  let list = (rolesArray.length) ? rolesArray.join(', ') : ''
  return (
    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
      <b>Roles: </b>
      {list}      
    </Text>
  )
}


const RoleStatus = ({status}) => {
  let word = status.toUpperCase()
  let color = '#fbc02d'
  if (status === 'active') {
      color = '#4caf50'
  }
  return (
    <Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
      <b>Role Status: </b>
      <span style={{color:color, fontSize:10, fontWeight:'bold'}}>{word}</span>
    </Text>
  )
}

const MemberCard = (props) => {
    const style = useStyles()
    const {member, handleOpenMember} = props
    const hideRoles = (props.hideRoles) ? props.hideRoles : false
    const showRoleStatus = (props.showRoleStatus) ? props.showRoleStatus : false

    if (!member) return null
    return (
        <Paper style={{display: 'flex', padding: 5}}>
            <span><img onClick={() => { handleOpenMember(member.memberId) }} src={member.memberThumb} className={style.thumb} alt={member.memberId} /></span>
            <span style={{flexGrow: 1}}>
                <Button onClick={() => { handleOpenMember(member.memberId) }} style={{padding:0, margin:0, lineHeight:1, textAlign:'left'}}>{member.memberName}</Button>
                <Text style={{ lineHeight:1.4}} variant="caption" display="block" color="textSecondary" >{ member.memberEmail}</Text>
                <Registered status={member.memberStatus}/>
                <Status status={member.status}/>
                {(!hideRoles) ? (
                  <Roles roles={member.roles}/>
                ):( null )}
                {(showRoleStatus) ? (
                  <RoleStatus status={props.roleStatus}/>
                ):( null )}
                
            </span>
            <span>
            <IconButton onClick={() => { handleOpenMember(member.memberId) }} size='small'>
                <IconEdit style={{color: '#c0c0c0'}} />
            </IconButton>
            </span>
        </Paper>
    )
}
export default MemberCard
