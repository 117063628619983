import { GROUP_MEMBERS_LOAD, GROUP_MEMBERS_RESET_REDUCER } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Members Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorMembers
export function startMonitoringMembers() {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        console.log('start monitoring members')

        // delay this snapshot if userInfo not loaded as yet
        if(getstate().auth.userInfo == null){
            await customDelay(3000)
        }

        // set needed variables
        let sectionGroupId = getstate().groupMembers.groupId
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id

        // reset section reducer if groupId doesnt match sectionGroupId
        if (sectionGroupId && sectionGroupId !== gId) {
            dispatch({type: GROUP_MEMBERS_RESET_REDUCER})
        }

        // [START]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::
        let memberRef
        let memberId = getstate().auth.userInfo.id
        let loginInfo = loginOptions[loginOptionID]
        if (loginInfo.roleId === 1 || loginInfo.roleId === 2) {
            memberRef = await firebase.firestore().collection('memberships')
            .where('groupId', '==', loginInfo.id)
        } else if (loginInfo.roleId === 6) {
            memberRef = await firebase.firestore().collection('memberships')
            .where('groupId', '==', loginInfo.id)
            .where('registeredBy.id', '==', memberId)
        } else {
            reject('You do not have permission to view this page.')
            return
        }
        // [END]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::::

        monitorMembers = memberRef.onSnapshot((querySnapshot) => {
            let members = []
            querySnapshot.forEach((doc) => {
                let member = doc.data()
                member.docId = doc.id
                members.push(member)
            })
            let payload = {
                groupId: gId,
                members: members,
                timestamp: Math.floor(Date.now() / 1000)
            }
            resolve()
            dispatch({type: GROUP_MEMBERS_LOAD, payload: payload})
        })
    })
}        
export function stopMonitoringMembers(){
    return () => {
        console.log('stop monitoring members')
        monitorMembers && monitorMembers()
    }
}
// [END]: Members Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function getMemberInfo(mId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let gId = getstate().auth.loginOptions[getstate().auth.loginOptionID].id
        let memberInfo = firebase.firestore().collection('memberships').where('groupId', '==', gId).where('memberId', '==', mId)
        memberInfo.get()
            .then(doc => {
                if (doc.exists) {
                    resolve(doc.data())
                } else {
                    reject('Document does not exist.')
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function addMembers(type, listName, list){
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        const gId = getstate().groupMembers.groupId
        const createGroup = firebase.functions().httpsCallable('addMembers')
        createGroup({ gId:gId, type:type, listName:listName, list:list })
        .then((returned) => {
            console.log('returned - ', returned.data)
            resolve(returned.data)
        })
        .catch((err) => {
            console.log(err.message)
            reject(err.message)
        })
                
    })
}

export function deleteUnregisteredMember(mId) {
    return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let gId = loginOptions[loginOptionID].id
        let memberIds = [mId]
        const deleteMember = firebase.functions().httpsCallable('deleteUnregisteredMembers')
        deleteMember({ gId: gId, memberIds: memberIds })
        .then((returned) => {
            console.log('success - ', returned.data)
            resolve('success')
        })
        .catch((err) => {
            console.log('error - ', err)
            reject(err)
        })
    })
}
