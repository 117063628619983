export const selectorLoadTickets = (tickets, status) => {
    let filteredTickets = {}
    Object.keys(tickets).forEach(function (i) {
        if (tickets[i].status === status) {
            filteredTickets[i] = tickets[i]
        }
    })
    return filteredTickets
}

export const selectorFilterList = (tickets) => {
    let ticketsArray = Object.values(tickets) 
    let list = []
    ticketsArray.map(ticket => {
        return list.push(ticket.group)
    })
    list = [...new Set(list)]
    return list
}

// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/