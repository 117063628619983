import React from 'react'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class GoogleLocation extends React.Component {
  render() {
    return (
        <div>
          GoogleLocation
        </div>
    )
  }
}
export default GoogleLocation
