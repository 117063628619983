export const AUTH_USER = 'auth:user'
export const AUTH_USER_INFO= 'auth:userInfo'
export const AUTH_LOGIN_USER = 'auth:loginUser'
export const AUTH_LOGOUT_USER = 'auth:logoutUser'
export const AUTH_LOAD_USER = 'auth:loadUser'
export const AUTH_LOAD_LOGIN_OPTIONS = 'auth:loadLoginOptions'
export const AUTH_CHANGE_LOGIN_OPTION = 'auth:changeLoginOption'

export const MEMBER_MEMBERSHIPS_LOAD = 'memberMemberships:loadMemberships'




export const MEMBER_NOTIFICATIONS_LOAD = 'memberCommunicate:loadNotifications'
export const MEMBER_NOTIFICATIONS_SAVE = 'memberCommunicate:saveNotification'
export const MEMBER_NOTIFICATIONS_UPDATE = 'memberCommunicate:updateNotification'

export const MEMBER_SUPPORT_LOAD_TICKETS = 'memberSupport:loadTickets'
export const MEMBER_SUPPORT_OPEN_TICKET = 'memberSupport:openTicket'
export const MEMBER_SUPPORT_UPDATE_TICKET = 'memberSupport:updateTicket'
export const MEMBER_SUPPORT_CLOSE_TICKET = 'memberSupport:closeTicket'


// GROUP-TEAM
export const GROUP_TEAM_LOAD = 'groupTeam:loadTeam'
export const GROUP_TEAM_RESET_REDUCER = 'groupTeam:resetReducer'

// GROUP-MEMBERS
export const GROUP_MEMBERS_LOAD = 'groupMembers:loadMembers'
export const GROUP_MEMBERS_RESET_REDUCER = 'groupMembers:resetReducer'

// EVENTS
export const GROUP_EVENTS_RESET_REDUCER = 'groupEvents:resetEvents'
export const GROUP_EVENTS_LOAD = 'groupEvents:loadEvents'
export const GROUP_EVENTS_TICKET_TYPES_LOAD = 'groupEvents:loadTicketTypes'
export const GROUP_EVENTS_ATTENDANCE_LOAD = 'groupEvents:loadAttendance'
export const GROUP_EVENTS_EVENT_RESET = 'groupEvents:resetEvent'