import React from 'react'
import ImageSelector from '../../components/ImageSelector'
import Button from '@material-ui/core/Button'

class Step2 extends React.Component {
    state = {
        currentImage: this.props.currentImage,
        selectedImage: null, // ImageSelector variable
        images: this.props.profileImages // ImageSelector variable
    }

    // [START]: ImageSelector Function :::::::::
    handleSelectImage = (src) => {
        this.setState({selectedImage:src})
    }
    // [END]: ImageSelector Function :::::::::::

    handleNext = () => {
      this.props.updateImage('profileImage', this.state.selectedImage)
    }

    render() {
  	    return (
            <div>
                <div>
                    {/** ImageSelector Starts Here */}
                    <div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
                        <ImageSelector 
                            imgBorder='1px solid #ccc'
                            imgBorderRadius={10}
                            imgPadding={5}
                            imgRatio='1:1' 
                            images={this.state.images}
                            currentImage={this.state.currentImage}
                            selectImage={this.handleSelectImage}
                        />
                    </div>
                    {/** ImageSelector Ends Here */}
                    <div align='right' style={{ padding: 20 }}>
                        <Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={this.props.handleBack}>Back</Button>
                        <Button variant='contained' color='primary' onClick={this.handleNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Step2
