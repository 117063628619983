import React from 'react'
import MemberProfilePage from '../../components/MemberProfilePage/'
import MemberCard from '../../components/MemberCard'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import Text from '@material-ui/core/Typography'

class groupMembersEmails extends React.Component {
    state = {
        open: false,
        showEmailCount: true
    }  

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.memberId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenMember = (memberId) => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter + '/' + memberId
        this.props.history.push({ pathname: url })
    }
    
    handleCloseMember = () => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter
        this.props.history.push({ pathname: url })        
    }

    render() {
        let {members, memberId} = this.props
        const {open, showEmailCount} = this.state
        return (
            <React.Fragment>
                <MemberProfilePage open={open} handleCloseMember={this.handleCloseMember} memberId={memberId} />    

                <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
                <Grid container justify='center' spacing={2}>
                    <Grid item xs={12} sm={12} md={10}>
                        <Grid container justify='flex-start' spacing={2} style={{padding:6}}>
                            
                            {(showEmailCount) ? (
                                <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                <div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107', marginBottom: 15 }}>
                                    <span style={{flexGrow: 1}}>
                                        <Text style={{marginBottom: 0}} variant='overline' display='block' gutterBottom>Total unregistered members: {members.length}</Text>
                                    </span>
                                    <span>
                                        <IconButton aria-label='close' size='small' onClick={() => {this.setState({showEmailCount: false})}}>
                                            <IconClose style={{color: '#c0c0c0'}} />
                                        </IconButton>
                                    </span>
                                </div>  
                                </Grid>
                            ) : ( null )}

                            {(members.length) ? (
                                <Grid container justify='flex-start' spacing={2} style={{padding:6}}>
                                    {members.map((member, index) =>{
                                        return (
                                            <Grid key={index} item md={4} sm={6} xs={12}>
                                                <MemberCard 
                                                    member={member}
                                                    handleOpenMember={this.handleOpenMember}
                                                />
                                            </Grid>
                                        )
                                    })} 
                                </Grid> 
                            ) : (null)}

                        </Grid>
                    </Grid>
                </Grid>
                </Container>
            </React.Fragment>
        )
    }
}
export default groupMembersEmails
