import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import SetupTicketAdd from './SetupTicketAdd'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  gray: {
    color: '#999',
    fontStyle: 'italic',
    fontSize: 'small'
  },
  mySwal: {
    zIndex: 10000
  }
}))

const availability = {
    both: 'Online & Sales Reps',
    online:'Online Only',
    salesReps:'Sales Reps Only',
    hidden:'Through Invoices Only'
}
const note = {
    Public: 'This is a PUBLIC event.\n Anyone can purchase tickets.',
    Members: 'This is a MEMBERS event. Only members can purchase tickets, but may purchase for guests.',
    Private: 'This is a PRIVATE event. Only members can purchase and only members can attend.'
}

function SetupTicketView(props){
    const classes = useStyles()
    const [openTicket, setOpenTicket] = React.useState(false)
    const [openInfo, setOpenInfo] = React.useState(false) 
    const [disableButtons, setDisableButtons] = React.useState(false) 

    const handleStateToggle = () => {
        setOpenInfo(false)
        setOpenTicket(false)
    }

    const handleCopyDeleteTicket = (action, ticket, published) => {
        let title = (action === 'Copy') ? 'Copy this Ticket?' : 'Delete this Ticket?'
        let text = (action === 'Copy') ? 'You can edit its details after.' : 'You won\'t be able to revert this!'    
        let type = (action === 'Copy') ? 'warning' : 'info'
        let buttonText = (action === 'Copy') ? 'Copy Ticket' : 'Delete Ticket'

        Swal.fire({
          title: title,
          text: text,
          showCancelButton: true,
          confirmButtonColor: '#00e600',
          cancelButtonColor: '#d33',
          confirmButtonText: buttonText,
          customClass: {container:classes.mySwal}
        }).then((result) => {
          if (result.value) {
            setDisableButtons(true)
            props.ticketTypeCopyDelete(action,ticket)
            .then(() => {
                if(action === 'Copy') {
                    setDisableButtons(false)
                    handleStateToggle()
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Sorry, Error!',
                    text: error,
                    customClass: {container:classes.mySwal}
                })
            })
          }
        })
    }

    return (
        <div>
            <List component="nav" style={{backgroundColor:'#f1f1f1', padding:2, margin:5}}>
                <div style={{display: 'flex'}}>
                <ListItem button 
                    style={{padding:10, flexGrow: 1}}
                    onClick={() => { (openTicket) ? handleStateToggle() : setOpenInfo(!openInfo)} }
                >
                    <ListItemText 
                        primary={props.ticket.name} 
                        secondary={
                            <React.Fragment>
                                <span style={{display:'block'}}>{props.ticket.description}</span>
                                <span>{props.ticket.amount.total + ' @ $' + (props.ticket.price/100)}</span>                            
                            </React.Fragment>
                        } 
                    />
                    {openTicket ? <ExpandLess /> : <ExpandMore/>}
                </ListItem>
                </div>
                <Collapse in={openInfo} style={{padding:5, width: '100%'}}>
                    <div className={classes.gray}><b>Sale Starts:</b> {moment.unix(props.ticket.dateTimeStart.seconds).format('ddd Do MMM YYYY - h:mm a')}</div>
                    <div className={classes.gray}><b>Sale Ends:</b> {moment.unix(props.ticket.dateTimeEnd.seconds).format('ddd Do MMM YYYY - h:mm a')}</div>
                    <div className={classes.gray}><b>Total Tickets Available:</b> {props.ticket.amount.total}</div>
                    <div className={classes.gray}><b>Price of Tickets:</b> ${(props.ticket.price / 100)}</div>
                    <div className={classes.gray}><b>Online Service Fees Paid By:</b> {props.ticket.serviceFee}</div>
                    <div className={classes.gray}><b>Ticket Limit Per User:</b> {props.ticket.ticketLimit}</div>
                    <div className={classes.gray}><b>Tickets are Available:</b> {availability[props.ticket.availability]}</div>
                    <hr/>
                    <div className={classes.gray}><b>Note:</b> {note[props.ticket.eventType]}</div>
                    <hr/>
                    <Grid container direction='row' justify='space-around' alignItems='center'>
                        <Grid item><Button disabled={disableButtons} onClick={() => { setOpenTicket(!openTicket)}} variant="contained" size="small" color="primary">edit</Button></Grid>
                        <Grid item><Button disabled={disableButtons} onClick={() => { handleCopyDeleteTicket('Copy',props.ticket.ticketId,props.ticket.eventPublished)}} variant="outlined" size="small" style={{color:'#999'}}>copy</Button></Grid>
                        <Grid item><Button disabled={disableButtons} onClick={() => { handleCopyDeleteTicket('Delete',props.ticket.ticketId,props.ticket.eventPublished)}} variant="outlined" size="small" style={{color:'#999'}}>delete</Button></Grid>
                    </Grid>
                    
                </Collapse>        
            </List>
            <Collapse timeout={1000} in={openTicket} style={{padding:5}}>
                <SetupTicketAdd handleStateToggle={handleStateToggle} ticketTypeAddEdit={props.ticketTypeAddEdit} ticket={props.ticket} />
            </Collapse> 
        </div>
    )
}
export default SetupTicketView
