import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SubNav from './components/Subnav'
import Summary from './Summary'

class MemberLoyalttContainer extends React.Component {
  state = { 
    page: 1,
    count: 10,
    sort: 'All',
    filter: 'Summary',
    docId: null, 
  }

  componentDidMount = () => {
    this.setFilter()
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.filter !== prevProps.match.params.filter) {
      this.setFilter()
    }
  }
  setFilter = () => {
    let page = (this.props.match.params.page) ? this.props.match.params.page : 1
    let count = (this.props.match.params.count) ? this.props.match.params.count : 10
    let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'All'
    let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'Summary'
    let docId = (this.props.match.params.docId) ? this.props.match.params.docId : null
    this.setState({ page: page, count: count, sort: sort, filter: filter, docId: docId })
  }

  render() {
    const {page, count, sort, filter} = this.state
    return (
      <React.Fragment>
        <SubNav page={page} count={count} sort={sort} filter={filter} />
        <Switch>
          <Route path='/member/loyaltt' render={props => <Summary {...props} 
              filter={this.state.filter}
            />} 
          />
        </Switch>
      </React.Fragment>
    )
  }
}
export default MemberLoyalttContainer
