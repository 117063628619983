import React from 'react'
import MemberProfilePage from '../../components/MemberProfilePage/'
import MemberCard from '../../components/MemberCard'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class groupTeamList extends React.Component {
    state = {
        open: false
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.memberId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
        }
    }    

    handleOpenMember = (memberId) => {
        let url = '/group/team/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter + '/' + memberId
        this.props.history.push({ pathname: url })
    }
    
    handleCloseMember = () => {
        let url = '/group/team/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter
        this.props.history.push({ pathname: url })        
    }

    render() {
        let {members, sort, memberId, loading } = this.props
        const {open} = this.state
        return (
            <React.Fragment>
            <MemberProfilePage open={open} handleCloseMember={this.handleCloseMember} memberId={memberId} />    
            
            <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10}>
                    <Grid container justify='flex-start' spacing={2} style={{padding:6}}>
                        
                        {(loading) ? (
                            <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                                <Paper style={{ padding: 20, textAlign: 'center' }} >
                                    <Text variant='caption' gutterBottom>
                                        Loading Team
                                    </Text>
                                </Paper>
                            </Grid>
                        ) : (null)}
                
                        {(!loading) ? (
                            <React.Fragment>                            
                                {(members['Owner'][sort.toLowerCase()].length) ? (
                                    <div style={{ width:'100%', marginBottom: 20}}>
                                        <div style={{ borderBottom: '2px solid #dddddd' }}>
                                            <Text variant='h6' gutterBottom style={{marginBottom:0}}>Owner</Text>
                                        </div>  
                                        <Grid container justify='flex-start' spacing={2}>
                                            {members['Owner'][sort.toLowerCase()].map((member) => {
                                                return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                            })}
                                        </Grid>
                                    </div>
                                ) : (null)}

                                <div style={{ width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>Admins</Text>
                                </div>  
                                {(members['Admin'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['Admin'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} Admins Listed.</div>
                                )}

                                <div style={{ marginTop:20, width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>Managers</Text>
                                </div>  
                                {(members['Manager'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['Manager'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} Managers Listed.</div>
                                )}

                                <div style={{ marginTop:20, width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>Editors</Text>
                                </div>  
                                {(members['Editor'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['Editor'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} Editors Listed.</div>
                                )}

                                <div style={{ marginTop:20, width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>CSRs</Text>
                                </div>  
                                {(members['CSR'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['CSR'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} CSRs Listed.</div>
                                )}

                                <div style={{ marginTop:20, width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>Attendants</Text>
                                </div>  
                                {(members['Attendant'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['Attendant'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} Attendants Listed.</div>
                                )}

                                <div style={{ marginTop:20, width:'100%', borderBottom: '2px solid #dddddd' }}>
                                    <Text variant='h6' gutterBottom style={{marginBottom:0}}>Sales Reps</Text>
                                </div>  
                                {(members['Sales_Rep'][sort.toLowerCase()].length) ? (
                                    <Grid container justify='flex-start' spacing={2}>
                                        {members['Sales_Rep'][sort.toLowerCase()].map((member) => {
                                            return (<Grid key={member.docId} item md={4} sm={6} xs={12}><MemberCard hideRoles={true} member={member} handleOpenMember={this.handleOpenMember} /></Grid>)
                                        })}
                                    </Grid>
                                ) : (
                                    <div style={{width: '100%', backgroundColor: '#f1f1f1', padding:10, color: '#999999'}}>No {sort} Sales Reps Listed.</div>
                                )}
                            </React.Fragment>
                        ) : (null)}                    
                    </Grid>
                </Grid>
            </Grid>
            </Container>
            </React.Fragment>
        )
    }
}
export default groupTeamList
