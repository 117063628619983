import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Summary from './Summary'
import EventsList from './EventsList'
import Event from './Event'

class GroupScannerContainer extends React.Component {
  render() {
    return (
        <Switch>
        	<Route path='/group/scanner/event/:id?' render={props => <Event {...props} />} />
          	<Route path='/group/scanner/events' render={props => <EventsList {...props} />} />
          	<Route path='/group/scanner' render={props => <Summary {...props} />} />
        </Switch>
    )
  }
}
export default GroupScannerContainer
