import React from 'react'
import MemberProfilePage from '../../components/MemberProfilePage/'
import MemberCard from '../../components/MemberCard'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/RateReview'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'


class groupMembersLoyalTT extends React.Component {
    state = {
        membersType: '',
        members: this.props.members,
        open: false,
        anchorEl: null,
        showLoyalTTCount: true
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.memberId) {
                this.setState({open: true})
            } else{
                this.setState({open: false})
            }
         
            let membersType = ''
            let members = this.props.members
            let filter = this.props.filter
            if (members.length && filter !== 'All'){
                membersType = '('+filter.replace(/_/g, ' ')+')'
                members = members.filter((member) => { return member.status === filter.replace(/_/g, ' ').toLowerCase() })             
            }
            this.setState({membersType: membersType, members: members})
        }
    }    

    handleOpenMember = (memberId) => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter + '/' + memberId
        this.props.history.push({ pathname: url })
    }
    
    handleCloseMember = () => {
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort+ '/' + this.props.filter
        this.props.history.push({ pathname: url })        
    }

    // -----------------------------
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleFilterChange = (value) => {
        this.setState({ anchorEl: null })
        let url = '/group/members/' + this.props.page + '/' + this.props.count + '/' + this.props.sort + '/' + value.replace(/ /g, '_')
        this.props.history.push({ pathname: url })
    }
    // -----------------------------

    render() {
        let {memberId, filter, statusFilter} = this.props
        const {membersType, members, open, anchorEl, showLoyalTTCount} = this.state
        return (
            <React.Fragment>
            <MemberProfilePage open={open} handleCloseMember={this.handleCloseMember} memberId={memberId} />    
            
            <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10}>
                    <Grid container justify='flex-start' spacing={2} style={{padding:6}}>

                        {(showLoyalTTCount) ? (
                            <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                            <div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
                                <span style={{flexGrow: 1}}>
                                    <Text style={{marginBottom: 0}} variant='overline' display='block' gutterBottom>you have {members.length} {membersType} loyaltt members</Text>
                                </span>
                                <span>
                                    <IconButton aria-label='close' size='small' onClick={() => {this.setState({showLoyalTTCount: false})}}>
                                        <IconClose style={{color: '#c0c0c0'}} />
                                    </IconButton>
                                </span>
                            </div>  
                            </Grid>
                        ) : ( null )}

                        <Grid item xs={12} sm={8} md={8} style={{margin: '0 auto'}}>
                            <Paper style={{ margin: '0 auto', padding: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 400}}>
                                <Button fullWidth aria-controls='filter-menu' aria-haspopup='true' onClick={this.handleClick}>
                                status filter: {filter.replace(/_/g, ' ')}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >    
                                    <Text variant='body2' component='p' style={{paddingLeft: 5, color: '#999'}}>
                                        Member Status Filter:
                                    </Text>
                                    <Divider />                                
                                    {statusFilter.map((option) => (
                                        <MenuItem 
                                            key={option}
                                            selected={option === filter.replace(/_/g, ' ')}
                                            onClick={() => {this.handleFilterChange(option)}}
                                        >{option} Members</MenuItem>
                                    ))}
                                </Menu>
                            </Paper>
                        </Grid>

                        {(members.length) ? (
                            <Grid style={{marginTop:10, padding:6}} container justify='flex-start' spacing={2}>
                                {members.map((member, index) =>{
                                    return (
                                        <Grid key={index} item md={4} sm={6} xs={12}>
                                            <MemberCard 
                                                member={member}
                                                handleOpenMember={this.handleOpenMember}
                                            />
                                        </Grid>
                                    )
                                })} 
                            </Grid>
                        ) : (null)}                    
                                
                    </Grid>
                </Grid>
               
            </Grid>
            </Container>
            </React.Fragment>
        )
    }
}
export default groupMembersLoyalTT
