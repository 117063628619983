import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsSummary() {
    return (
        <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={8} md={8} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        GROUP SETTINGS
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <b>ABOUT</b><br/>
                        <p>This section shows the general info for the group. You can change it's profile picture,
                        edit it's descriptions etc.</p>
                        
                        <p><b>TABS</b></p>
                        - group settings<br/>
                        - payment setting (accept)<br/>
                        - payment settings (payment)<br/>

                        <p><b>ACCESS</b></p>
                        - Accessable by Level 1 only (owners and admins)<br/>

                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}
export default GroupSettingsSummary
