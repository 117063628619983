import React from 'react'
import GoogleLocation from '../../components/GoogleLocation'
import Button from '@material-ui/core/Button'

class Step2 extends React.Component {
    state = {
        location: null
    }

    // [START]: ImageSelector Function :::::::::
    handleSelectImage = (src) => {
        this.setState({selectedImage:src})
    }
    // [END]: ImageSelector Function :::::::::::

    handleNext = () => {
      this.props.updateEventLocation(this.state.location)
    }

    render() {
  	    return (
            <div>
                <div>
                    {/** ImageSelector Starts Here */}
                    <div align='center' style={{ maxWidth: 400, margin:'0 auto'}}>
                        <GoogleLocation/>
                    </div>
                    {/** ImageSelector Ends Here */}
                    <div align='right' style={{ padding: 20 }}>
                        <Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={this.props.handleBack}>Back</Button>
                        <Button variant='contained' color='primary' onClick={this.handleNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Step2
