import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const stageConfig = {
  apiKey: "AIzaSyDhUnNPc903938FO8s8rrMhHjqBpsLTQsQ",
  authDomain: "stage-646a9.firebaseapp.com",
  databaseURL: "https://stage-646a9.firebaseio.com",
  projectId: "stage-646a9",
  storageBucket: "stage-646a9.appspot.com",
  messagingSenderId: "243881228035",
  appId: "1:243881228035:web:48bc837650adac62"
}

const liveConfig = {
  apiKey: "AIzaSyAgaL21OVWqj_GnyUhvI0AnlO4MBixn6Ec",
  authDomain: "live-5778f.firebaseapp.com",
  databaseURL: "https://live-5778f.firebaseio.com",
  projectId: "live-5778f",
  storageBucket: "live-5778f.appspot.com",
  messagingSenderId: "916820668476",
  appId: "1:916820668476:web:75f1827ff9fc509bf69339",
  measurementId: "G-QYDJW3V52T"
}

// use stageConfig locally and on stage project.
let config = stageConfig

// use liveConfig on live project.
if (window.location.hostname === 'loyaltt.com' || window.location.hostname === 'loyaltt.app') {
	config = liveConfig
}

// Initialize Firebase
firebase.initializeApp(config)
export default firebase
