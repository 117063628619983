import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupDashboardSummary() {
    return (
        <Container maxWidth='lg' style={{ padding: 10, marginBottom: 100 }}>
        <Grid container justify='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10}>
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item md={4} sm={4} xs={4}>
                        <Paper style={{ padding: 20, minHeight: 100 }} >120<br/>Total Members</Paper>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <Paper style={{ padding: 20, minHeight: 100 }} >12/30<br/>Tickets Sold</Paper>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <Paper style={{ padding: 20, minHeight: 100 }} >$22.50<br/>Sales</Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={10}>
                <div style={{ padding: 10, backgroundColor: 'rgba(102,187,106,.08)', borderLeft: '2px solid #66bb6a', marginBottom: 15 }}>
                    <Text variant='h6'>Notification Title</Text>
                    <Text>notification description here</Text>
                </div>
                <div style={{ padding: 10, backgroundColor: 'rgba(102,187,106,.08)', borderLeft: '2px solid #66bb6a', marginBottom: 15 }}>
                    <Text variant='h6'>Notification Title</Text>
                    <Text>notification description here</Text>
                </div>
            </Grid>

        </Grid>
        </Container>
    )
}
export default GroupDashboardSummary
