import React from 'react'
import { connect } from 'react-redux'
import { startMonitoringNotifications, stopMonitoringNotifications, saveNotificationToggle, openNotificationToggle, deleteNotification } from '../../../redux/memberCommunicate/actions'
import { selectorFilterCategories, selectorLoadNotifications } from '../../../redux/memberCommunicate/selectors'

import SubNav from './components/Subnav'
import List from './List'
import Notification from './Notification'

class MemberNotificationsContainer extends React.Component {
  state = {
    page: 1,
    count: 10,
    sort: 'New',
    filter: 'All',
    notificationId: null,
    notifications: []
  }
  componentDidMount = () => {
    this.props.startMonitoringNotifications()
    this.setSortFilter()
  }
  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setSortFilter()
    }
  }
  componentWillUnmount = () => {
    this.props.stopMonitoringNotifications()
  }

  setSortFilter = () => {
    let page = (this.props.match.params.page) ? this.props.match.params.page : 1
    let count = (this.props.match.params.count) ? this.props.match.params.count : 10
    let sort = (this.props.match.params.sort) ? this.props.match.params.sort : 'New'
    let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
    let notificationId = (this.props.match.params.notificationId) ? this.props.match.params.notificationId : null
    
    let notifications = []
    if (notificationId) {
        let allNotifications = this.props.allNotifications
        notifications = allNotifications.filter((note) => { return note.docId === notificationId })
    } else {
        switch(sort) {
          case 'New':
              notifications = this.props.newNotifications
            break
          case 'Viewed':
              notifications = this.props.viewedNotifications
            break
          case 'Saved':
              notifications = this.props.savedNotifications
            break
          default:
              notifications = []
        }

        if (notifications.length && filter !== 'All'){
          let filtered = []
          filtered = notifications.filter((note) => { return note.category === filter.replace(/_/g, ' ') })             
          notifications = filtered
        }
    }      
    this.setState({ page: page, count: count, sort: sort, filter: filter, notificationId: notificationId, notifications: notifications })
  }

  render() {
    const {page, count, sort, filter, notificationId, notifications} = this.state
    const {filterCategories, saveNotificationToggle, openNotificationToggle, deleteNotification} = this.props
    return (
      <>
        <SubNav page={page} count={count} sort={sort} filter={filter} options={filterCategories} />
        { (notificationId) ? (
          <Notification notificationId={notificationId} notifications={notifications} saveNotificationToggle={saveNotificationToggle} />
        ) : (
          <List page={page} count={count} sort={sort} filter={filter} notifications={notifications} 
            openNotificationToggle={openNotificationToggle} deleteNotification={deleteNotification} 
          />
        )      
      }  
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allNotifications: state.memberCommunicate.notifications,
  newNotifications: selectorLoadNotifications(state.memberCommunicate.notifications, 'New'),
  viewedNotifications: selectorLoadNotifications(state.memberCommunicate.notifications, 'Viewed'),
  savedNotifications: selectorLoadNotifications(state.memberCommunicate.notifications, 'Saved'),
  filterCategories: selectorFilterCategories(state.memberCommunicate.notifications)
})

const mapDispatchToProps = {
  startMonitoringNotifications, 
  stopMonitoringNotifications,
  saveNotificationToggle,
  openNotificationToggle,
  deleteNotification
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberNotificationsContainer)
