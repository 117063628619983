import React from 'react'
import ImageSelector from '../../../components/ImageSelector'
import countryList from '../../../../includes/countryList'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

class Edit extends React.Component {
    state = {
        changed: false,
        message: '',
        actionButtonDisabled: true,
        firstName: (this.props.user.firstName) ? this.props.user.firstName : '',
      	lastName: (this.props.user.lastName) ? this.props.user.lastName : '',
      	dob: (this.props.user.dob) ? this.props.user.dob : '',
        gender: (this.props.user.gender) ? this.props.user.gender : '',
        nationality: (this.props.user.nationality) ? this.props.user.nationality : '',
        location: (this.props.user.location) ? this.props.user.location : '',
        currentImage: this.props.user.thumb, // ImageSelector variable        
        selectedImage: null, // ImageSelector variable
        images: [ // ImageSelector variable,
            '/images/default-profilePic.jpg',
            '/images/profile/red.jpg',
            '/images/profile/orange.jpg',
            '/images/profile/skyblue.jpg'
        ]
    }

    // [START]: ImageSelector Function :::::::::
    handleSelectImage = (src) => {
    	let {changed, actionButtonDisabled, currentImage} = this.state
    	let changeStatus = changed
    	let disabledStatus = actionButtonDisabled
    	if (src !== currentImage) {
    		changeStatus = true
    		disabledStatus = false
    	}
        this.setState({changed:changeStatus, actionButtonDisabled:disabledStatus, selectedImage:src})
    }
    // [END]: ImageSelector Function :::::::::::

    handleChange = (e) => {
        this.setState({ [e.target.name]:e.target.value, changed:true, actionButtonDisabled:false, message:'' })
    }

	updateUser = () => {
		// if nothing changed just skip
		if (!this.state.changed) {
			return this.props.skip()
		}
		// firstName and lastName cannot be blank
		if (this.state.firstName.length < 1 || this.state.lastName.length < 1) {
            return this.setState({ message: 'Sorry, both First and Last names are required.' })
        }
        // set user variables
		let user = {
			thumb: this.state.selectedImage,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			dob: this.state.dob,
	        gender: this.state.gender,
	        nationality: this.state.nationality,
	        location: this.state.location,
		}
		this.props.update(user)
	}
    render() {
    	const {images, firstName, lastName, message, currentImage, actionButtonText, actionButtonDisabled} = this.state
        return (
            <div> 
                {/** ImageSelector Starts Here */}
                <div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
                    <ImageSelector 
                        imgBorder='1px solid #ccc'
                        imgBorderRadius={10}
                        imgPadding={5}
                        imgRatio='1:1' 
                        images={images}
                        currentImage={currentImage}
                        selectImage={this.handleSelectImage}
                    />
                </div>
                {/** ImageSelector Ends Here */}
                
                <Grid container justify='center'>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField fullWidth
                            label='First Name (required)'
                            name='firstName'
                            value={firstName}
                            onChange={this.handleChange}
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField fullWidth
                            label='Last Name (required)'
                            name='lastName'
                            value={lastName}
                            onChange={this.handleChange}
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <FormControl fullWidth>
                        <InputLabel htmlFor='gender'>Gender</InputLabel>
                        <Select
                            value={this.state.gender}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'gender',
                                id: 'gender'
                            }}
                        >
                            <MenuItem value=''><em>Gender</em></MenuItem>
                            <MenuItem value='Male'>Male</MenuItem>
                            <MenuItem value='Female'>Female</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <TextField fullWidth
                            name='dob'
                            onChange={ this.handleChange }
                            label='Date of Birth'
                            type='date'
                            value={this.state.dob}
                            InputLabelProps={{
                                shrink: true,
                                placeholder: 'Select Date of Birth'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <FormControl fullWidth>
                        <InputLabel htmlFor='nationality'>Nationality</InputLabel>
                        <Select
                            value={this.state.nationality}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'nationality',
                                id: 'nationality'
                            }}
                        >
                            <MenuItem value=''><em>Nationality</em></MenuItem>
                            {countryList.map((c, i) => {
                                return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                            })}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                        <FormControl fullWidth>
                        <InputLabel htmlFor='location'>Current Location</InputLabel>
                        <Select
                            value={this.state.location}
                            onChange={this.handleChange}
                            inputProps={{
                            name: 'location',
                            id: 'location'
                            }}
                        >
                            <MenuItem value=''><em>Location</em></MenuItem>
                            {countryList.map((c, i) => {
                                return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                            })}
                        </Select>
                        </FormControl>
                    </Grid>                    
                </Grid>

            	<div align='center' style={{ height: 35, padding:5 }}>
                    <Text variant='caption' style={{ color: 'red' }}>
                        {message}
                    </Text>
                </div>
				<div><Button fullWidth variant='contained' color='secondary' onClick={this.updateUser} disabled={actionButtonDisabled}>UPDATE PROFILE</Button></div>
                <div><Button fullWidth onClick={this.props.skip} style={{marginTop:10}}>skip</Button></div>
            </div>
        )
    }
}
export default Edit
